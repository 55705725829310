import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as routes from 'router/Routes';
import { Customer, CustomerRelation } from 'models';
import { isOrganizationCustomer } from 'utils/customer';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Loader from 'components/UI/Loader';
import Tag from 'components/UI/Tag';

type CustomerRelationRow = {
  name: string;
  email: string;
  link: string;
  relation?: ReactNode;
};

type Props = {
  customers: Customer[];
  relations?: CustomerRelation[];
  isLoading?: boolean;
};

const CustomerRelationCard: FC<Props> = ({
  customers,
  relations,
  isLoading
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Link to customer
  const onClick = useCallback(
    (customerId: string) => {
      navigate(routes.customerDetailsLink(customerId), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Render relation
  const renderRelation = useCallback(
    (customerId: string) => {
      if (!relations) {
        return null;
      }
      const found = relations.find(
        (item) => item.attributes.customerId === customerId
      );
      return found ? <Tag>{found.attributes.relationshipType}</Tag> : '—';
    },
    [relations]
  );

  // Render table
  const table = useMemo(() => {
    // Header
    const headerCells: HeaderCell[] = [
      {
        sortable: true,
        intl: 'customers.relations_table_name'
      },
      {
        sortable: true,
        intl: 'customers.relations_table_email'
      },
      ...(relations
        ? [
            {
              sortable: true,
              intl: 'customers.relations_table_relation'
            }
          ]
        : [])
    ];

    // Rows
    const bodyRows = customers.map(({ id, attributes }) => {
      const {
        companyName,
        eContact: { emailContacts },
        firstName,
        lastName
      } = attributes;

      const name = isOrganizationCustomer(attributes)
        ? companyName
        : `${firstName} ${lastName}`;

      const rowData: CustomerRelationRow = {
        name: name || '–',
        email: emailContacts?.length ? emailContacts[0].emailAddress : '–',
        link: id
      };

      if (relations) {
        rowData.relation = renderRelation(id);
      }

      return rowData;
    });

    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="customers.relations_table_empty"
        onClick={onClick}
      />
    );
  }, [customers, relations, isLoading, onClick, renderRelation]);

  return (
    <Card>
      <CardHeader intl="customers.title" />
      {table}
    </Card>
  );
};

export default CustomerRelationCard;
