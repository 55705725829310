import { apiFetch, createFilterUrl } from 'utils/fetch';
import {
  UserResponse,
  User,
  UsersResponse,
  Pagination,
  UserFilterData,
  UserBody,
  UserStatisticsExportResponse,
  UserStatistics,
  UserStatisticsResponse
} from 'models';
import * as config from 'config';

export async function findUsersByFilters(
  filters: UserFilterData,
  pagination?: Pagination
): Promise<User[]> {
  let url = createFilterUrl(`${config.backendApi}/diam/users`, filters);

  if (pagination) {
    const { limit, offset } = pagination;
    url += `&limit=${limit}&offset=${offset}`;
  }

  const json = await apiFetch<UsersResponse>(url);

  return json.data;
}

export async function findUserById(userId: string): Promise<User> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(userId)}`;

  const json = await apiFetch<UserResponse>(url);

  return json.data;
}

export async function addUserGroup(
  userId: string,
  group: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/groups/${encodeURIComponent(group)}`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}

export async function removeUserGroup(
  userId: string,
  group: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/groups/${encodeURIComponent(group)}`;

  await apiFetch<void>(url, {
    method: 'DELETE'
  });
}

export async function addUserRole(userId: string, role: string): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/roles/${encodeURIComponent(role)}`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}

export async function removeUserRole(
  userId: string,
  role: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/roles/${encodeURIComponent(role)}`;

  await apiFetch<void>(url, {
    method: 'DELETE'
  });
}

export async function updateUser(
  userId: string,
  body: UserBody
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(userId)}`;

  await apiFetch<void>(url, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'users',
        attributes: {
          ...body
        }
      }
    })
  });
}

export async function updateUserPasswordPolicy(
  userId: string,
  policy: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/password-policy/${policy}`;

  await apiFetch<void>(url, {
    method: 'PATCH'
  });
}

export async function activateUser(userId: string): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/activate`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}

export async function disableUser(userId: string): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/disable`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}

export async function requestActivateUser(
  userId: string,
  username: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/request-activate`;

  await apiFetch<void>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'users',
        attributes: {
          username
        }
      }
    })
  });
}

export async function requestChangeUsername(
  userId: string,
  username: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/request-change-username`;

  await apiFetch<void>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'users',
        attributes: {
          new_username: username
        }
      }
    })
  });
}

export async function confirmChangeUsername(
  userId: string,
  username: string
): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/confirm-change-username`;

  await apiFetch<void>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'users',
        attributes: {
          new_username: username
        }
      }
    })
  });
}

export async function requestDeleteUser(userId: string): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/request-delete`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}

export async function invalidateUserTokens(userId: string): Promise<void> {
  const url = `${config.backendApi}/diam/users/${encodeURIComponent(
    userId
  )}/invalidate-tokens`;

  await apiFetch<void>(url, {
    method: 'DELETE'
  });
}

export async function getStatistics(): Promise<UserStatistics[]> {
  const url = `${config.backendApi}/diam/statistics`;

  const json = await apiFetch<UserStatisticsResponse>(url);

  return json.data;
}

export async function getStatisticsCsvExport(): Promise<string> {
  const url = `${config.backendApi}/diam/statistics/csv`;

  const json = await apiFetch<UserStatisticsExportResponse>(url);

  return json.data;
}
