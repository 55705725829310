import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { isDev, isLocal } from 'config';
import { acceptanceReducer } from './acceptanceState/acceptanceState';
import { authReducer } from './authState/authState';
import { auditReducer } from './auditState/auditState';
import { connectivityReducer } from './connectivityState/connectivityState';
import { consentReducer } from './consentState/consentState';
import { contractReducer } from './contractState/contractState';
import { customerReducer } from './customerState/customerState';
import { localizationReducer } from './localizationState/localizationState';
import { metaReducer } from './metaState/metaState';
import { layoutReducer } from './layoutState/layoutState';
import { productReducer } from './productState/productState';
import { productRegistrationReducer } from './productRegistrationState/productRegistrationState';
import { servicePlanReducer } from './servicePlanState/servicePlanState';
import { serviceRecordReducer } from './serviceRecordState/serviceRecordState';
import { userReducer } from './userState/userState';
import { userGroupReducer } from './userGroupState/userGroupState';
import { userRoleReducer } from './userRoleState/userRoleState';
import { userUpdateReducer } from './userUpdateState/userUpdateState';
import { userPasswordPolicyReducer } from './userPasswordPolicyState/userPasswordPolicyState';
import { userRequestReducer } from './userRequestState/userRequestState';
import { userStatisticsReducer } from './userStatisticsState/userStatisticsState';
import { userAuthReducer } from './userAuthState/userAuthState';
import { warrantyConditionReducer } from './productWarrantyConditionState/productWarrantyConditionState';
import { companyReducer } from './companyState/companyState';

const rootReducer = combineReducers({
  auth: authReducer,
  audit: auditReducer,
  customer: customerReducer,
  company: companyReducer,
  user: userReducer,
  userAuth: userAuthReducer,
  userUpdate: userUpdateReducer,
  userRequest: userRequestReducer,
  userPasswordPolicy: userPasswordPolicyReducer,
  userGroup: userGroupReducer,
  userRole: userRoleReducer,
  userStatistics: userStatisticsReducer,
  productRegistration: productRegistrationReducer,
  product: productReducer,
  servicePlan: servicePlanReducer,
  serviceRecord: serviceRecordReducer,
  consent: consentReducer,
  acceptance: acceptanceReducer,
  contract: contractReducer,
  connectivity: connectivityReducer,
  localization: localizationReducer,
  meta: metaReducer,
  layout: layoutReducer,
  warrantyCondition: warrantyConditionReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: isLocal || isDev
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
