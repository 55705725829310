import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { User } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  updateUserStatusById,
  updateUserStatusReset,
  userUpdateStatusSelector
} from 'store';
import { showSuccessToast } from 'utils/toast';

import Loader from 'components/UI/Loader';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { ButtonGrid } from './styled';

type Props = {
  fetchData: () => void;
  fetchAuditLogs: () => void;
  onClose: () => void;
  open: boolean;
  user: User;
};

const EditUserStatusModal: FC<Props> = ({
  fetchData,
  fetchAuditLogs,
  onClose,
  open,
  user
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(userUpdateStatusSelector);
  const { disabled, firstName, lastName, username } = user.attributes;

  // Fetch if add was successful
  useEffect(() => {
    if (isSuccess) {
      fetchData();
      fetchAuditLogs();
      showSuccessToast(intl.formatMessage({ id: 'modal.user_status_success' }));
      onClose();
    }

    return () => {
      dispatch(updateUserStatusReset());
    };
  }, [dispatch, fetchAuditLogs, fetchData, intl, isSuccess, onClose]);

  // Confirm
  const onConfirm = useCallback(
    () => dispatch(updateUserStatusById(user.id, user.attributes.disabled)),
    [dispatch, user]
  );

  return (
    <EditModal
      title={`modal.user_status_${disabled ? 'activate' : 'disable'}_title`}
      open={open}
      close={onClose}
    >
      <p>
        <FormattedMessage
          id={`modal.user_status_${disabled ? 'activate' : 'disable'}_text`}
          values={{
            name: `${firstName} ${lastName}`
          }}
        />
      </p>
      <p>({username})</p>
      {isLoading ? <Loader center padding /> : null}
      <ButtonGrid>
        <Button onClick={onClose} backgroundColor="transparent" color="primary">
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button onClick={onConfirm} disabled={isLoading}>
          <FormattedMessage
            id={`modal.btn_${disabled ? 'activate' : 'disable'}`}
          />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};

export default EditUserStatusModal;
