export enum Language {
  EN = 'en',
  SV = 'sv',
  DA = 'da',
  DE = 'de'
}

export type LocalizationState = {
  locale: Language;
  languages: Language[];
  messages: {
    [key: string]: Record<string, string>;
  };
};
