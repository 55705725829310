import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CustomerAttributes, CustomerUpdate, UpdateCustomer } from 'models';
import { getCustomerEmail, getCustomerName } from 'utils/customer';

import Heading, { Type as HeadingType } from 'components/UI/Heading';
import InputField from 'components/UI/InputField';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';

import { Description, ButtonGrid } from './styled';

type FormValues = {
  locationStreet: string;
  locationPostalCode: string;
  locationCity: string;
  billingStreet: string;
  billingPostalCode: string;
  billingCity: string;
  shippingStreet: string;
  shippingPostalCode: string;
  shippingCity: string;
};

type Props = {
  customer: CustomerAttributes;
  open: boolean;
  update: UpdateCustomer;
  onCustomerUpdate: (values: CustomerUpdate) => void;
  onClose: () => void;
};

const EditCustomerAddressModal: FC<Props> = ({
  customer,
  open,
  update,
  onClose,
  onCustomerUpdate
}) => {
  // Refs
  const shouldClose = useRef(false);

  // Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useForm<FormValues>({
    defaultValues: {
      locationStreet: customer.locationAddress.street,
      locationPostalCode: customer.locationAddress.zipCode,
      locationCity: customer.locationAddress.city,
      billingStreet: customer.billingAddress.street,
      billingPostalCode: customer.billingAddress.zipCode,
      billingCity: customer.billingAddress.city,
      shippingStreet: customer.shippingAddress.street,
      shippingPostalCode: customer.shippingAddress.zipCode,
      shippingCity: customer.shippingAddress.city
    }
  });

  // Watch billing
  const [billStreet, billCode, billCity, shipStreet, shipCode, shipCity] =
    watch([
      'billingStreet',
      'billingPostalCode',
      'billingCity',
      'shippingStreet',
      'shippingPostalCode',
      'shippingCity'
    ]);
  const billingRequired = Boolean(billStreet || billCode || billCity);
  const shippingRequired = Boolean(shipStreet || shipCode || shipCity);

  // Submit
  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (values) => {
      const { type, country } = customer;
      onCustomerUpdate({
        type,
        locationAddress: {
          street: values.locationStreet,
          zipCode: values.locationPostalCode,
          city: values.locationCity,
          country
        },
        billingAddress: {
          street: values.billingStreet,
          zipCode: values.billingPostalCode,
          city: values.billingCity,
          country
        },
        shippingAddress: {
          street: values.shippingStreet,
          zipCode: values.shippingPostalCode,
          city: values.shippingCity,
          country
        }
      });
    },
    [onCustomerUpdate, customer]
  );

  // Close modal on success
  useEffect(() => {
    if (update.isSuccess) {
      if (shouldClose.current) {
        onClose();
        shouldClose.current = false;
      }
    } else {
      shouldClose.current = true;
    }
  }, [onClose, update.isSuccess]);

  // Input options
  const getInputOptions = useCallback(
    (required: boolean) => ({
      required: {
        value: required,
        message: 'input.required'
      }
    }),
    []
  );

  // Render data
  const formData = useMemo(() => {
    if (update.isLoading) {
      return <Loader padding center />;
    }
    return (
      <Fragment>
        <Heading type={HeadingType.h3}>
          <FormattedMessage id="customer_details.address_location_title" />
        </Heading>
        <InputField
          description="modal.update_customer_address_street"
          register={register('locationStreet', getInputOptions(true))}
          error={errors.locationStreet}
        />
        <InputField
          description="modal.update_customer_address_postcode"
          register={register('locationPostalCode', getInputOptions(true))}
          error={errors.locationPostalCode}
        />
        <InputField
          description="modal.update_customer_address_city"
          register={register('locationCity', getInputOptions(true))}
          error={errors.locationCity}
        />
        <Heading type={HeadingType.h3}>
          <FormattedMessage id="customer_details.address_billing_title" />
        </Heading>
        <InputField
          description="modal.update_customer_address_street"
          register={register('billingStreet', getInputOptions(billingRequired))}
          error={errors.billingStreet}
        />
        <InputField
          description="modal.update_customer_address_postcode"
          register={register(
            'billingPostalCode',
            getInputOptions(billingRequired)
          )}
          error={errors.billingPostalCode}
        />
        <InputField
          description="modal.update_customer_address_city"
          register={register('billingCity', getInputOptions(billingRequired))}
          error={errors.billingCity}
        />
        <Heading type={HeadingType.h3}>
          <FormattedMessage id="customer_details.address_shipping_title" />
        </Heading>
        <InputField
          description="modal.update_customer_address_street"
          register={register(
            'shippingStreet',
            getInputOptions(shippingRequired)
          )}
          error={errors.billingStreet}
        />
        <InputField
          description="modal.update_customer_address_postcode"
          register={register(
            'shippingPostalCode',
            getInputOptions(shippingRequired)
          )}
          error={errors.billingPostalCode}
        />
        <InputField
          description="modal.update_customer_address_city"
          register={register('shippingCity', getInputOptions(shippingRequired))}
          error={errors.billingCity}
        />
      </Fragment>
    );
  }, [
    register,
    getInputOptions,
    update,
    errors,
    billingRequired,
    shippingRequired
  ]);

  return (
    <EditModal
      title="modal.update_customer_address_title"
      open={open}
      close={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Description>
          <FormattedMessage
            id="modal.update_customer_address_text"
            values={{
              name: getCustomerName(customer),
              email: getCustomerEmail(customer)
            }}
          />
        </Description>
        {formData}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || update.isLoading} submit>
            <FormattedMessage id="modal.btn_update" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditCustomerAddressModal;
