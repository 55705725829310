import { FC, Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AuditLogAttributes } from 'models';
import { useDiamAccess } from 'hooks/useDiamAccess';
import { formatDate } from 'utils/date';

import EditModal from 'components/UI/EditModal';
import DiffView from 'components/UI/DiffView';
import Heading, { Type } from 'components/UI/Heading';
import InfoItem from 'components/UI/InfoItem';

import { Container } from './styled';

type Props = {
  data: AuditLogAttributes | null;
  onClose: () => void;
};

/**
 * Modal showing audit log changes
 */
const UserAuditDiffModal: FC<Props> = ({ data, onClose }) => {
  const intl = useIntl();
  const { isAnyReadonlyAdmin } = useDiamAccess();

  const details = useMemo(() => {
    if (!data) {
      return null;
    }

    const details = [
      {
        id: 1,
        title: 'user_details.audit_log_time',
        value: formatDate(data.createdDate, 'yyyy-MM-dd HH:mm')
      },
      {
        id: 2,
        title: 'user_details.audit_log_type',
        value: data.operationType
      },
      {
        id: 3,
        title: 'user_details.audit_log_done_by',
        value: data.userId
      },
      {
        id: 4,
        title: 'user_details.audit_log_subtype',
        value: data.operationSubtype
      },
      {
        id: 5,
        title: 'user_details.audit_log_description',
        value: data.description
      }
    ];

    return details.map(({ id, title, value }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={value ?? '—'}
      />
    ));
  }, [data, intl]);

  const diff = useMemo(() => {
    if (!data || isAnyReadonlyAdmin) {
      return null;
    }

    return (
      <Fragment>
        <Heading type={Type.h3}>
          <FormattedMessage id="user_details.audit_log_changes" />
        </Heading>
        <DiffView before={data.dataBefore} after={data.dataAfter} />
      </Fragment>
    );
  }, [data, isAnyReadonlyAdmin]);

  return (
    <EditModal title="modal.audit_title" open={Boolean(data)} close={onClose}>
      <Container>{details}</Container>
      {diff}
    </EditModal>
  );
};

export default UserAuditDiffModal;
