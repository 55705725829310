import { parse } from 'date-fns';
import { FC, Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EmailBounceAttributes } from 'models';
import { formatDate } from 'utils/date';

import EditModal from 'components/UI/EditModal';
import DiffView from 'components/UI/DiffView';
import Heading, { Type } from 'components/UI/Heading';
import InfoItem from 'components/UI/InfoItem';

import { Container } from './styled';

type Props = {
  data: EmailBounceAttributes | null;
  onClose: () => void;
};

/**
 * Modal showing email bounce details
 */
export const UserEmailBounceModal: FC<Props> = ({ data, onClose }) => {
  const intl = useIntl();

  const details = useMemo(() => {
    if (!data) {
      return null;
    }

    const details = [
      {
        id: 1,
        title: 'user_details.email_bounces_time',
        value: formatDate(
          parse(
            data.timestamp,
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            new Date()
          ).getTime(),
          'yyyy-MM-dd HH:mm'
        )
      },
      {
        id: 2,
        title: 'user_details.email_bounces_bounce_type',
        value: data.bounceType
      },
      {
        id: 3,
        title: 'user_details.email_bounces_bounce_subtype',
        value: data.bounceSubtype
      },
      {
        id: 4,
        title: 'user_details.email_bounces_diagnostic_info',
        value: data.diagnosticCode
      }
    ];

    return details.map(({ id, title, value }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={value ?? '—'}
      />
    ));
  }, [data, intl]);

  const bounceData = useMemo(() => {
    if (!data) {
      return null;
    }

    return (
      <Fragment>
        <Heading type={Type.h3}>
          <FormattedMessage id="user_details.email_bounces_bounce_data" />
        </Heading>
        <DiffView before={data.data} after={data.data} />
      </Fragment>
    );
  }, [data]);

  return (
    <EditModal title="modal.email_bounce_title" open={!!data} close={onClose}>
      <Container>{details}</Container>
      {bounceData}
    </EditModal>
  );
};
