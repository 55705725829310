import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { User } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  invalidateUserTokens,
  userInvalidateTokensReset,
  userInvalidateTokensSelector
} from 'store';
import { showSuccessToast } from 'utils/toast';

import Loader from 'components/UI/Loader';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { ButtonGrid } from './styled';

type Props = {
  onClose: () => void;
  open: boolean;
  user: User;
};

const InvalidateTokensModal: FC<Props> = ({ onClose, open, user }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(userInvalidateTokensSelector);
  const { firstName, lastName, username } = user.attributes;

  // Fetch if add was successful
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        intl.formatMessage({ id: 'modal.invalidate_tokens_success' })
      );
      onClose();
    }

    return () => {
      dispatch(userInvalidateTokensReset());
    };
  }, [onClose, intl, isSuccess, dispatch]);

  // Confirm
  const onConfirm = useCallback(
    () => dispatch(invalidateUserTokens(user.id)),
    [dispatch, user]
  );

  return (
    <EditModal
      title="modal.invalidate_tokens_title"
      open={open}
      close={onClose}
    >
      <p>
        <FormattedMessage
          id="modal.invalidate_tokens_text"
          values={{
            name: `${firstName} ${lastName}`
          }}
        />
      </p>
      <p>({username})</p>
      {isLoading ? <Loader center padding /> : null}
      <ButtonGrid>
        <Button onClick={onClose} backgroundColor="transparent" color="primary">
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button onClick={onConfirm} disabled={isLoading}>
          <FormattedMessage id="modal.btn_confirm" />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};

export default InvalidateTokensModal;
