import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 64px 20px;
  max-width: 450px;
  margin: 0 auto;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.onBackground};
  font-weight: bold;
  text-transform: uppercase;
  margin: 8px;
`;
