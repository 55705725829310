export function truncateText(text: string, characterLimit: number) {
  if (text.length <= characterLimit) {
    return text;
  }

  return `${text.substring(0, characterLimit)}…`;
}

export function capitalizeFirstChar(text?: string | null): string | null {
  if (!text?.length) {
    return null;
  }
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function getInitials(name: string): string {
  const names = name.split(' ');
  const initials = names.map((item) => item.charAt(0).toUpperCase());
  return initials.join('');
}
