import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';

import { AuditLogAttribute } from 'models';
import { Column, Grid } from 'styles';
import {
  auditLogsSelector,
  serviceRecordSelector,
  productDetailsSelector,
  fetchProductByIprId,
  fetchServiceRecordsByIprId,
  fetchAuditLogs,
  fetchFactoryPin,
  resetAuditLogsState,
  resetServiceRecordState,
  removeWarrantyConditionByIprId,
  warrantyConditionSelector,
  fetchWarrantyConditionByIprId,
  connectivityDeviceSelector,
  fetchDevice,
  connectivitySoftwareUpdatesSelector,
  fetchSofwareUpdates
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useValidateAzureEditProduct } from 'hooks/useValidateAzureEditProduct';
import * as routes from 'router/Routes';

import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import { IconType } from 'components/UI/Icon';
import ProductNameCard from 'components/products/ProductNameCard';
import { ConfirmRemoveWarrantyModal } from 'components/products/ConfirmDeleteWarrantyModal';
import ProductContractCard from 'components/products/ProductContractCard';
import ProductWarrantyCard from 'components/products/ProductWarrantyCard';
import CustomerRelationCard from 'components/customers/CustomerRelationCard';
import ServiceRecordCard from 'components/servicerecords/ServiceRecordCard';
import AuditLogCard from 'components/auditlog/AuditLogCard';
import ViewFactoryPinModal from 'components/products/ViewFactoryPinModal';
import SoftwareUpdatesTable from 'components/products/SoftwareUpdatesCard';
import ProductDealerCard from 'components/products/ProductDealerCard';

const ProductDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { iprId } = useParams();

  // Redux state
  const details = useAppSelector(productDetailsSelector);
  const connectivityDevice = useAppSelector(connectivityDeviceSelector);
  const softwareUpdates = useAppSelector(connectivitySoftwareUpdatesSelector);
  const warranty = useAppSelector(warrantyConditionSelector);
  const serviceRecord = useAppSelector(serviceRecordSelector);
  const auditLogs = useAppSelector(auditLogsSelector);

  // Access
  const canEdit = useValidateAzureEditProduct({
    allowAnySalesCompanyAdmin: true
  });

  // State
  const [viewFactoryPinModalOpen, setViewFactoryPinModalOpen] =
    useState<boolean>(false);
  const [removeWarrantyModalOpen, setRemoveWarrantyModalOpen] =
    useState<boolean>(false);

  // Modal actions
  const openRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModalOpen(true),
    []
  );
  const closeViewFactoryPinModal = useCallback(
    () => setViewFactoryPinModalOpen(false),
    []
  );
  const closeRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModalOpen(false),
    []
  );

  const onNavigateServicePlan = useCallback(() => {
    navigate(routes.productServicePlanLink(iprId));
  }, [navigate, iprId]);

  const openViewFactoryPinModal = useCallback(() => {
    setViewFactoryPinModalOpen(true);
    if (iprId) {
      dispatch(fetchFactoryPin(iprId));
    }
  }, [dispatch, iprId]);

  const onRemoveWarranty = useCallback(() => {
    if (iprId) {
      dispatch(removeWarrantyConditionByIprId(iprId));
    }
  }, [dispatch, iprId]);

  // Fetch audit logs
  const fetchAuditLogsByIprId = useCallback(() => {
    if (iprId) {
      dispatch(
        fetchAuditLogs([
          {
            attribute: AuditLogAttribute.IprId,
            value: iprId
          }
        ])
      );
    }
  }, [dispatch, iprId]);

  // Fetch warranty
  const fetchWarrantyByIprId = useCallback(() => {
    if (iprId) {
      dispatch(fetchWarrantyConditionByIprId(iprId));
    }
  }, [dispatch, iprId]);

  const refetchWarranty = useCallback(() => {
    fetchWarrantyByIprId();
    fetchAuditLogsByIprId();
  }, [fetchWarrantyByIprId, fetchAuditLogsByIprId]);

  // Fetch product
  useEffect(() => {
    if (iprId) {
      dispatch(fetchProductByIprId(iprId));
      dispatch(fetchServiceRecordsByIprId(iprId));
      dispatch(fetchDevice(iprId));
      dispatch(fetchSofwareUpdates(iprId));
      fetchAuditLogsByIprId();
      fetchWarrantyByIprId();
    }
    return () => {
      dispatch(resetAuditLogsState());
      dispatch(resetServiceRecordState());
    };
  }, [dispatch, fetchAuditLogsByIprId, fetchWarrantyByIprId, iprId]);

  // Loading
  if (details.isLoading) {
    return <Loader center padding />;
  }

  // No access
  if (!details.data && details.error?.status === 403) {
    return <NoAccessState />;
  }

  // Not found
  if (!iprId || !details.data) {
    return (
      <EmptyState icon={IconType.Product} padding includeParams>
        <FormattedMessage id="product_details.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <ProductNameCard
        product={details.data}
        device={connectivityDevice.data}
        canEdit={canEdit}
        openViewFactoryPinModal={openViewFactoryPinModal}
        onNavigateServicePlan={onNavigateServicePlan}
      />
      <Grid>
        <Column $col={1} $marginRight>
          <ProductWarrantyCard
            iprId={iprId}
            warranty={warranty.data}
            contracts={details.contracts}
            isLoading={warranty.isLoading}
            onCreate={fetchAuditLogsByIprId}
            onRemove={openRemoveWarrantyModal}
          />
          <ProductDealerCard dealer={details.dealer} warranty={warranty.data} />
        </Column>
        <Column $col={2}>
          <CustomerRelationCard
            customers={details.customers}
            relations={details.relations}
          />
          <ProductContractCard contracts={details.contracts} />
          <SoftwareUpdatesTable softwareUpdates={softwareUpdates} />
        </Column>
      </Grid>
      <ServiceRecordCard
        isLoading={serviceRecord.isLoading}
        data={serviceRecord.data}
      />
      <AuditLogCard isLoading={auditLogs.isLoading} data={auditLogs.data} />
      <ViewFactoryPinModal
        open={viewFactoryPinModalOpen}
        close={closeViewFactoryPinModal}
        onFetch={fetchAuditLogsByIprId}
      />
      <ConfirmRemoveWarrantyModal
        open={removeWarrantyModalOpen}
        close={closeRemoveWarrantyModal}
        onFetch={refetchWarranty}
        onRemove={onRemoveWarranty}
      />
    </Fragment>
  );
};

export default ProductDetailsPage;
