import { useMemo } from 'react';

import { useAppSelector } from 'hooks/redux';
import { DIAMUserAccess } from 'models';
import { authSelector } from 'store';
import {
  getDiamSystemGroups,
  isAnyDiamGlobalAdmin,
  isAnyReadonlyAdmin,
  isDiamGlobalAdmin
} from 'utils/roles';

export const useDiamAccess = (): DIAMUserAccess => {
  const { session } = useAppSelector(authSelector);

  return useMemo(() => {
    const { scpUser } = session;
    if (!scpUser) {
      return {
        isGlobalAdmin: false,
        isAnyGlobalAdmin: false,
        isAnyReadonlyAdmin: false,
        groups: []
      };
    }
    return {
      isGlobalAdmin: isDiamGlobalAdmin(scpUser),
      isAnyGlobalAdmin: isAnyDiamGlobalAdmin(scpUser),
      isAnyReadonlyAdmin: isAnyReadonlyAdmin(scpUser),
      groups: getDiamSystemGroups(scpUser)
    };
  }, [session]);
};
