import { FC, useCallback, useMemo, useState } from 'react';

import compareIcon from 'assets/vectors/compare-icon.svg';
import { AuditLog, AuditLogAttributes } from 'models';
import { SortOrder } from 'utils/array';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import IconButton from 'components/UI/IconButton';
import Loader from 'components/UI/Loader';

import AuditDiffModal from '../AuditDiffModal';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'customers.table_date'
  },
  {
    sortable: true,
    intl: 'customers.auditlog_table_operation'
  },
  {
    sortable: false,
    intl: 'customers.auditlog_table_changes'
  }
];

type Props = {
  data: AuditLog[];
  isLoading: boolean;
};

const AuditLogCard: FC<Props> = ({ data, isLoading }) => {
  // State
  const [modalData, setModalData] = useState<AuditLogAttributes | null>(null);

  // Actions
  const onCloseModal = useCallback(() => setModalData(null), []);

  // Table
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    // Body rows
    const bodyRows = data.map(({ attributes }) => ({
      date: formatDate(attributes.createdDate, 'yyyy-MM-dd HH:mm'),
      operation: attributes.description ?? attributes.operationType,
      button: (
        <IconButton onClick={() => setModalData(attributes)}>
          <img src={compareIcon} alt="remove" />
        </IconButton>
      )
    }));

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="customer_details.auditlog_empty"
        sortOrder={SortOrder.Desc}
      />
    );
  }, [isLoading, data]);

  return (
    <Card>
      <CardHeader intl="customer_details.auditlog_title" />
      {content}
      <AuditDiffModal data={modalData} onClose={onCloseModal} />
    </Card>
  );
};

export default AuditLogCard;
