import { FC, useMemo } from 'react';

import { UserStatistics } from 'models/user';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import { SortOrder } from 'utils/array';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'user_statistics.table_service'
  },
  {
    sortable: true,
    intl: 'user_statistics.users_total'
  },
  {
    sortable: true,
    intl: 'user_statistics.users_enabled'
  }
];

type Props = {
  statistics: UserStatistics[];
};

const UserStatisticsTable: FC<Props> = ({ statistics }) => {
  // Body
  const bodyRows = useMemo(
    () =>
      statistics
        .filter((data) => data.id !== 'iam')
        .map(({ id, attributes }) => {
          const { total, enabled } = attributes;

          return {
            service: id,
            total: total,
            enabled: enabled
          };
        }),
    [statistics]
  );

  return (
    <SortableTable
      headerCells={headerCells}
      bodyRows={bodyRows}
      sortIndex={1}
      sortOrder={SortOrder.Desc}
    />
  );
};

export default UserStatisticsTable;
