import { useLocation } from 'react-router-dom';

export const useQuery = (...getParams: string[]) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  if (!getParams.length) {
    return ['No query param'];
  }
  return getParams.map((param) => query.get(param));
};
