import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserGroup, UserGroupData, UserGroupState } from 'models';
import * as services from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: UserGroupState = {
  groups: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  addGroup: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  removeGroup: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userGroupSlice = createSlice({
  name: 'user-group',
  initialState: defaultState,
  reducers: {
    getUserGroupsInit: (state) => {
      state.groups.isSuccess = false;
      state.groups.isLoading = true;
      state.groups.isError = false;
      state.groups.error = null;
    },
    getUserGroupsSuccess: (state, action: PayloadAction<UserGroup[]>) => {
      state.groups.data = action.payload;
      state.groups.isSuccess = true;
      state.groups.isLoading = false;
    },
    getUserGroupsFailure: (state, action: PayloadAction<APIError>) => {
      state.groups.isLoading = false;
      state.groups.isError = true;
      state.groups.error = action.payload;
    },
    getUserGroupsReset: (state) => {
      state.groups = defaultState.groups;
    },
    addUserGroupInit: (state) => {
      state.addGroup.isSuccess = false;
      state.addGroup.isLoading = true;
      state.addGroup.isError = false;
      state.addGroup.error = null;
    },
    addUserGroupSuccess: (state, action: PayloadAction<UserGroupData>) => {
      state.addGroup.data = action.payload;
      state.addGroup.isSuccess = true;
      state.addGroup.isLoading = false;
    },
    addUserGroupFailure: (state, action: PayloadAction<APIError>) => {
      state.addGroup.isLoading = false;
      state.addGroup.isError = true;
      state.addGroup.error = action.payload;
    },
    addUserGroupReset: (state) => {
      state.addGroup = defaultState.addGroup;
    },
    removeUserGroupInit: (state) => {
      state.removeGroup.isSuccess = false;
      state.removeGroup.isLoading = true;
      state.removeGroup.isError = false;
      state.removeGroup.error = null;
    },
    removeUserGroupSuccess: (state, action: PayloadAction<UserGroupData>) => {
      state.removeGroup.data = action.payload;
      state.removeGroup.isSuccess = true;
      state.removeGroup.isLoading = false;
    },
    removeUserGroupFailure: (state, action: PayloadAction<APIError>) => {
      state.removeGroup.isLoading = false;
      state.removeGroup.isError = true;
      state.removeGroup.error = action.payload;
    },
    removeUserGroupReset: (state) => {
      state.removeGroup = defaultState.removeGroup;
    }
  }
});

// Export selectors
export const userGroupSelector = (state: RootState) => state.userGroup;

// Export actions
export const {
  getUserGroupsInit,
  getUserGroupsSuccess,
  getUserGroupsFailure,
  getUserGroupsReset,
  addUserGroupInit,
  addUserGroupSuccess,
  addUserGroupFailure,
  addUserGroupReset,
  removeUserGroupInit,
  removeUserGroupSuccess,
  removeUserGroupFailure,
  removeUserGroupReset
} = userGroupSlice.actions;

// Export reducer
export const userGroupReducer = userGroupSlice.reducer;

// Export thunk
export function fetchUserGroups() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { isSuccess, isLoading } = getState().userGroup.groups;

    // Fetch user groups only once per session, and prevent multiple concurrent requests
    if (isSuccess || isLoading) {
      return;
    }

    dispatch(getUserGroupsInit());
    try {
      const result = await services.getUserGroups();
      dispatch(getUserGroupsSuccess(result));
    } catch (e: any) {
      dispatch(getUserGroupsFailure(e));
    }
  };
}

export function addUserGroup(userId: string, group: UserGroupData) {
  return async (dispatch: Dispatch) => {
    dispatch(addUserGroupInit());
    try {
      await services.addUserGroup(userId, group.value);
      dispatch(addUserGroupSuccess(group));
    } catch (e: any) {
      dispatch(addUserGroupFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function removeUserGroup(userId: string, group: UserGroupData) {
  return async (dispatch: Dispatch) => {
    dispatch(removeUserGroupInit());
    try {
      await services.removeUserGroup(userId, group.value);
      dispatch(removeUserGroupSuccess(group));
    } catch (e: any) {
      dispatch(removeUserGroupFailure(e));
      showErrorToast(e.title);
    }
  };
}
