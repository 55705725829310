import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Hamburger = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 48px;
  height: 100%;
  padding: 0;
  opacity: 1;
  z-index: 3;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 1;
  color: ${({ theme }) => theme.colors.onSurface};

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;
