import * as config from 'config';
import { AuditLogFilter, AuditLogResponse, EmailBounceResponse } from 'models';
import { apiFetch, createFilterParams } from 'utils/fetch';

export async function fetchAuditLogs(
  filters: AuditLogFilter[]
): Promise<AuditLogResponse> {
  const url = createFilterParams(`${config.backendApi}/audits`, filters);

  return apiFetch<AuditLogResponse>(url);
}

export async function fetchUserAuditLogsById(
  userId: string
): Promise<AuditLogResponse> {
  const url = `${config.backendApi}/audits/users/${encodeURIComponent(userId)}`;

  return apiFetch<AuditLogResponse>(url);
}

export async function fetchEmailBouncesByUserId(
  userId: string
): Promise<EmailBounceResponse> {
  const url = `${config.backendApi}/audits/users/${encodeURIComponent(
    userId
  )}/emailbounces`;

  return apiFetch<EmailBounceResponse>(url);
}
