import { FC, Fragment } from 'react';
import { ThemeColor } from 'styles';
import { StyledGroup, StyledPath } from './styled';

export enum IconType {
  Home,
  Customer,
  Copy,
  Product,
  Contract,
  ServiceContract,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  Search,
  Phone,
  Email,
  More,
  Edit,
  Add,
  Remove,
  Tune,
  Cross,
  Check,
  Info,
  Lock,
  Map,
  World,
  Close,
  Active,
  Inactive,
  Compare,
  ExternalLink,
  EmailBounce,
  Retail
}

type Props = {
  type: IconType;
  themeType?: ThemeColor;
  color?: string;
};

const IconPath: FC<Props> = ({ type, themeType, color = 'black' }) => {
  switch (type) {
    case IconType.Home: {
      return (
        <g data-testid="svg-home">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -127) translate(116.657 127)"
            />
            <StyledPath
              $themeType={themeType}
              $color={color}
              d="M2.166 8.85l9.296-6.65c.169-.12.393-.12.561 0l9.297 6.65c.13.094.209.247.209.41V21.5c0 .276-.22.5-.49.5H13.7v-7H9.786v7h-7.34c-.27 0-.489-.224-.489-.5V9.26c0-.163.078-.316.209-.41z"
              transform="translate(-116 -127) translate(116.657 127)"
            />
          </g>
        </g>
      );
    }
    case IconType.Copy: {
      return (
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <StyledPath
            d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1Zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6ZM8 21V7h6v5h5v9H8Z"
            fillRule="nonzero"
            $themeType={themeType}
            $color={color}
          />
        </g>
      );
    }
    case IconType.Customer: {
      return (
        <g data-testid="svg-customer">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -223) translate(116.657 223)"
            />
            <StyledPath
              $themeType={themeType}
              $color={color}
              d="M11.743 14c3.914 0 6.85 1.5 8.807 3 .596 1.217.92 2.712.971 4.485.008.276-.209.507-.485.515H2.464c-.276 0-.5-.224-.5-.5v-.015c.052-1.773.376-3.268.972-4.485 1.875-1.533 4.893-3 8.807-3zm0-12c3.243 0 5.871 2.686 5.871 6s-2.628 6-5.871 6C8.5 14 5.87 11.314 5.87 8s2.63-6 5.872-6z"
              transform="translate(-116 -223) translate(116.657 223)"
            />
          </g>
        </g>
      );
    }
    case IconType.Product: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-product">
          <StyledGroup $themeType={themeType} $color={color}>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M16.164 2.188c.964-.287 2.004-.246 2.946.123l-2.815 2.814 2.557 2.559 2.815-2.814c.369.942.41 1.983.123 2.947l-3.039 3.04c-1.198.357-2.514.207-3.612-.45L4.689 21.532l-.012.012-.005.005c-.282.279-.67.451-1.097.451-.863 0-1.562-.7-1.562-1.563 0-.42.167-.802.438-1.083l.012-.013c.01-.011.018-.017.025-.024L13.579 8.85c-.66-1.1-.812-2.42-.454-3.62zm-1.336 10.83l6.701 6.296c.29.284.471.68.471 1.118 0 .864-.699 1.563-1.561 1.563-.46 0-.874-.199-1.16-.516l-6.211-6.587 1.76-1.875zM7.814 2.187l3.04 3.04c.357 1.202.205 2.524-.457 3.624l.453.425-1.629 1.537-.383-.406c-1.097.656-2.413.806-3.61.45l-3.04-3.04c-.287-.965-.245-2.006.123-2.948l2.817 2.814 2.557-2.559-2.816-2.814c.942-.369 1.982-.41 2.945-.123z"
                      transform="translate(-16 -276) translate(0 83) translate(0 25) translate(0 144) translate(16 24)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </StyledGroup>
        </g>
      );
    }
    case IconType.Contract: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-contract">
          <StyledGroup $themeType={themeType} $color={color}>
            <g>
              <path
                d="M12.258 1.058c1.45.863 2.865 1.548 4.242 2.055 1.324.488 2.683.836 4.074 1.045.245.037.426.247.426.495v11.436c0 1.525-.868 2.918-2.237 3.59L12 23l-6.763-3.32C3.867 19.006 3 17.613 3 16.088V4.658c0-.25.183-.46.43-.496 1.323-.187 2.68-.537 4.07-1.049 1.27-.468 2.511-1.05 3.725-1.747l.519-.306c.158-.095.355-.096.514-.002zm5.616 6.831c-.195-.195-.511-.195-.707 0l-6.717 6.718-3.182-3.182c-.195-.196-.512-.196-.707 0l-.707.707c-.196.195-.196.512 0 .707l4.242 4.242.07.058c.194.135.464.116.637-.058l7.778-7.778c.196-.195.196-.512 0-.707z"
                transform="translate(-16 -24) translate(16 24)"
              />
            </g>
          </StyledGroup>
        </g>
      );
    }
    case IconType.ServiceContract: {
      return (
        <g
          id="servicecontract"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          data-testid="svg-servicecontract"
        >
          <StyledGroup
            transform="translate(2.000000, 0.000000)"
            $themeType={themeType}
            $color={color}
            fillRule="nonzero"
          >
            <path d="M2.35294118,4.84210526 L2.35294118,20.5789474 L17.6470588,20.5789474 L17.6470588,4.84210526 L2.35294118,4.84210526 Z M10,0 C11.7929412,0 13.3185882,1.01103158 13.8832941,2.42105263 L15.2941176,2.42105263 L15.2941176,4.84210526 L16.4705882,4.84210526 L16.4705882,2.42105263 L19.4117647,2.42105263 C19.7364706,2.42105263 20,2.69221053 20,3.02631579 L20,22.3947368 C20,22.7288421 19.7364706,23 19.4117647,23 L0.588235294,23 C0.263529412,23 0,22.7288421 0,22.3947368 L0,3.02631579 C0,2.69221053 0.263529412,2.42105263 0.588235294,2.42105263 L3.52941176,2.42105263 L3.52941176,4.84210526 L4.70588235,4.84210526 L4.70588235,2.42105263 L6.11670588,2.42105263 C6.65976471,1.06526316 8.09129412,0.0774736842 9.79482353,0.00484210526 L10,0 Z M10,1.21052632 C9.67529412,1.21052632 9.41176471,1.48168421 9.41176471,1.81578947 C9.41176471,2.14989474 9.67529412,2.42105263 10,2.42105263 C10.3247059,2.42105263 10.5882353,2.14989474 10.5882353,1.81578947 C10.5882353,1.48168421 10.3247059,1.21052632 10,1.21052632 Z M4.70588235,8.47368421 L15.2941176,8.47368421 L15.2941176,10.8947368 L4.70588235,10.8947368 L4.70588235,8.47368421 Z M4.70588235,13.3157895 L11.7647059,13.3157895 L11.7647059,15.7368421 L4.70588235,15.7368421 L4.70588235,13.3157895 Z" />
          </StyledGroup>
        </g>
      );
    }
    case IconType.ArrowDown: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9991 15.3248L4.20316 7.52885C4.0079 7.33358 3.69132 7.33358 3.49606 7.52885L2.45316 8.57174C2.2579 8.767 2.2579 9.08358 2.45316 9.27885L11.6456 18.4712C11.8408 18.6665 12.1574 18.6665 12.3527 18.4712L21.545 9.27885C21.7403 9.08358 21.7403 8.767 21.545 8.57174L20.5022 7.52885C20.3069 7.33358 19.9903 7.33358 19.795 7.52885L11.9991 15.3248Z"
          data-testid="svg-arrowdown"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.ArrowRight: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-arrowright">
          <g>
            <g>
              <path
                d="M0 0H20V19H0z"
                transform="translate(-1393 -27) translate(1393 27)"
              />
              <StyledPath
                $themeType={themeType}
                $color={color}
                d="M14.942 11.692 7.146 3.896a.5.5 0 0 1 0-.707L8.19 2.146a.5.5 0 0 1 .707 0l9.193 9.193a.5.5 0 0 1 0 .707l-9.193 9.192a.5.5 0 0 1-.707 0l-1.043-1.043a.5.5 0 0 1 0-.707l7.796-7.796Z"
              />
            </g>
          </g>
        </g>
      );
    }
    case IconType.ArrowLeft: {
      return (
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <StyledPath
            d="m9.5 11.9 7.797-7.797a.5.5 0 0 0 0-.708l-1.042-1.042a.5.5 0 0 0-.708 0l-9.192 9.192a.5.5 0 0 0 0 .707l9.192 9.192a.5.5 0 0 0 .708 0l1.042-1.042a.5.5 0 0 0 0-.708l-7.795-7.796-.002.002Z"
            $themeType={themeType}
            $color={color}
            fillRule="nonzero"
          />
        </g>
      );
    }
    case IconType.Search: {
      return (
        <Fragment>
          <path d="M0 0h24v24H0z" fill="none" data-testid="svg-search" />
          <StyledPath
            $themeType={themeType}
            $color={color}
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
        </Fragment>
      );
    }
    case IconType.Phone: {
      return (
        <Fragment>
          <path fill="none" d="M0 0h24v24H0z" data-testid="svg-phone" />
          <StyledPath
            $themeType={themeType}
            $color={color}
            fillRule="evenodd"
            d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 00-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
          />
        </Fragment>
      );
    }
    case IconType.Email: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-email">
          <path fill="none" d="M0 0h24v24H0V0z" />
          <StyledPath
            $themeType={themeType}
            $color={color}
            d="M22 4H2v16h20V4zm-2 4l-8 5-8-5V6l8 5 8-5v2z"
          ></StyledPath>
        </g>
      );
    }
    case IconType.Add: {
      return (
        <StyledPath
          $themeType={themeType}
          $color={color}
          fillRule="evenodd"
          d="M13 5v6h6v2h-6v6h-2v-6.001L5 13v-2l6-.001V5h2z"
          data-testid="svg-add"
        />
      );
    }
    case IconType.Remove: {
      return (
        <StyledGroup
          $themeType={themeType}
          $color={color}
          data-testid="svg-remove"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        </StyledGroup>
      );
    }
    case IconType.Edit: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-edit">
          <path d="M0 0h24v24H0z"></path>
          <StyledPath
            $themeType={themeType}
            $color={color}
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          />
          <path d="M0 0h24v24H0z"></path>
        </g>
      );
    }
    case IconType.More: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-more">
          <path d="M0 0h24v24H0z"></path>
          <StyledPath
            $themeType={themeType}
            $color={color}
            d="M14 6c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-4 6c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm0 6c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
          />
        </g>
      );
    }
    case IconType.Tune: {
      return (
        <Fragment>
          <path fill="none" d="M0 0h24v24H0z" data-testid="svg-tune"></path>
          <StyledPath
            $themeType={themeType}
            $color={color}
            d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"
          />
        </Fragment>
      );
    }
    case IconType.Check: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3535 3.35348C21.1582 3.15822 20.8416 3.15822 20.6464 3.35348L8.99992 14.9999L3.35348 9.35348C3.15822 9.15822 2.84163 9.15822 2.64637 9.35348L1.35348 10.6464C1.15822 10.8416 1.15822 11.1582 1.35348 11.3535L8.64637 18.6464C8.84163 18.8416 9.15822 18.8416 9.35348 18.6464L22.6464 5.35348C22.8416 5.15822 22.8416 4.84163 22.6464 4.64637L21.3535 3.35348Z"
          $themeType={themeType}
          $color={color}
          data-testid="svg-check"
        />
      );
    }
    case IconType.Cross: {
      return (
        <StyledPath
          $themeType={themeType}
          $color={color}
          data-testid="svg-cross"
          d="M12 1C5.917 1 1 5.917 1 12s4.917 11 11 11 11-4.917 11-11S18.083 1 12 1zm4.646 14.236a.5.5 0 010 .708l-.702.702a.5.5 0 01-.708 0L12 13.41l-3.236 3.236a.5.5 0 01-.708 0l-.702-.702a.5.5 0 010-.708L10.59 12 7.354 8.764a.5.5 0 010-.708l.702-.702a.5.5 0 01.708 0L12 10.59l3.236-3.236a.5.5 0 01.708 0l.702.702a.5.5 0 010 .708L13.41 12l3.236 3.236z"
        />
      );
    }
    case IconType.Info: {
      return (
        <StyledPath
          $themeType={themeType}
          $color={color}
          data-testid="svg-info"
          d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12zm20 0a9 9 0 11-18 0 9 9 0 0118 0zm-10 5.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v7zm.5-9.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1z"
        />
      );
    }
    case IconType.Lock: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          data-testid="svg-lock"
          d="M12 2C8.68629 2 6 4.68629 6 8H4.5C4.22386 8 4 8.22386 4 8.5V21.5C4 21.7761 4.22386 22 4.5 22H19.5C19.7761 22 20 21.7761 20 21.5V8.5C20 8.22386 19.7761 8 19.5 8H18C18 4.68629 15.3137 2 12 2ZM12 4C14.2091 4 16 5.79086 16 8H8C8 5.79086 9.79086 4 12 4ZM12 12C10.8954 12 10 12.8954 10 14C10 14.7532 10.4163 15.4091 11.0314 15.7502C11.0109 15.8301 11 15.9138 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.9138 12.9891 15.8301 12.9686 15.7502C13.5837 15.4091 14 14.7532 14 14C14 12.8954 13.1046 12 12 12Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.Map: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          data-testid="svg-map"
          d="M6.5 6.51398C6.5 3.47545 8.96147 1.01331 12 1.01331C15.0385 1.01331 17.5 3.47545 17.5 6.51398C17.5 7.50036 17.2406 8.426 16.7863 9.22639H16.7866L12 17.8587L7.21408 9.22774H7.21447C6.75968 8.42704 6.5 7.50091 6.5 6.51398ZM3 12H7L8.04921 14H4.80998L4.20998 20H19.79L19.19 14H15.9791L17 12H21L22 22H2L3 12ZM9.42177 6.51398C9.42177 7.93876 10.5759 9.09221 12 9.09221C13.4241 9.09221 14.5782 7.93876 14.5782 6.51398C14.5782 5.08988 13.4241 3.93575 12 3.93575C10.5759 3.93575 9.42177 5.08988 9.42177 6.51398Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.World: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          data-testid="svg-world"
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM19.7393 14.3809C19.9707 13.628 20.0952 12.8283 20.0952 11.9995C20.0952 11.5124 20.0522 11.0352 19.9697 10.5718H16.0508C16.101 11.0338 16.1301 11.4965 16.1382 11.9599C16.1523 12.7668 16.1025 13.5738 15.9891 14.3809H19.7393ZM18.8688 16.2856H15.6034C15.2964 17.4465 14.8582 18.607 14.2895 19.7665C16.2084 19.2017 17.831 17.9453 18.8688 16.2856ZM9.69314 19.7613C9.10211 18.6059 8.64284 17.4471 8.31619 16.2856H5.13124C6.16591 17.9403 7.78186 19.1942 9.69314 19.7613ZM19.3797 8.66699C18.3734 6.44219 16.3906 4.75352 13.978 4.14767C14.7859 5.64486 15.3686 7.15176 15.7242 8.66699H19.3797ZM9.69314 4.23773C8.94048 5.70913 8.4015 7.18598 8.07796 8.66699H4.62035C5.58051 6.54413 7.42975 4.90941 9.69314 4.23773ZM4.03027 10.5718H7.78098C7.73654 11.0473 7.71429 11.5233 7.71429 11.9995C7.71429 12.7943 7.77624 13.5881 7.89989 14.3809H4.26068C4.0293 13.628 3.90476 12.8283 3.90476 11.9995C3.90476 11.5124 3.9478 11.0352 4.03027 10.5718ZM10.0339 8.66699C10.3979 7.20295 11.0021 5.73363 11.8489 4.25725C12.7316 5.72945 13.3679 7.19877 13.7605 8.66699H10.0339ZM9.69529 10.5718H14.1332C14.192 11.0456 14.2255 11.5194 14.2337 11.9932C14.2476 12.7866 14.1908 13.5824 14.0629 14.3809H9.831C9.68957 13.5857 9.61905 12.7921 9.61905 11.9995C9.61905 11.5241 9.64443 11.0481 9.69529 10.5718ZM10.3047 16.2856H13.6245C13.2542 17.5203 12.7152 18.7618 12.0062 20.0114C11.2666 18.7647 10.6999 17.5232 10.3047 16.2856Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.Close: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-close">
          <path d="M0 0h24v24H0z" />
          <StyledPath
            d="m5.99 4.575 6.01 6.01 6.01-6.01a.502.502 0 0 1 .708 0l.707.707a.502.502 0 0 1 0 .708L13.414 12l6.01 6.01a.5.5 0 0 1 0 .708l-.706.707a.502.502 0 0 1-.708 0L12 13.414l-6.01 6.01a.5.5 0 0 1-.708 0l-.707-.706a.502.502 0 0 1 0-.708l6.01-6.01-6.01-6.01a.502.502 0 0 1 0-.708l.707-.707a.502.502 0 0 1 .708 0z"
            $themeType={themeType}
            $color={color}
          />
        </g>
      );
    }
    case IconType.Active: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.928 5.928 1 12 1C18.072 1 23 5.928 23 12C23 18.072 18.072 23 12 23C5.928 23 1 18.072 1 12ZM17.1673 7.88909C17.3625 7.69383 17.6791 7.69382 17.8744 7.88909L18.5815 8.59619C18.7767 8.79146 18.7767 9.10804 18.5815 9.3033L11.5104 16.3744L10.8033 17.0815C10.608 17.2767 10.2915 17.2767 10.0962 17.0815L9.38909 16.3744L5.85355 12.8388C5.65829 12.6436 5.65829 12.327 5.85355 12.1317L6.56066 11.4246C6.75592 11.2294 7.0725 11.2294 7.26777 11.4246L10.4497 14.6066L17.1673 7.88909Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.Inactive: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.917 1 1 5.917 1 12C1 18.083 5.917 23 12 23C18.083 23 23 18.083 23 12C23 5.917 18.083 1 12 1ZM16.6464 15.2364C16.8417 15.4317 16.8417 15.7483 16.6464 15.9436L15.9436 16.6464C15.7483 16.8417 15.4317 16.8417 15.2364 16.6464L12 13.41L8.76355 16.6464C8.56829 16.8417 8.25171 16.8417 8.05645 16.6464L7.35355 15.9436C7.15829 15.7483 7.15829 15.4317 7.35355 15.2364L10.59 12L7.35355 8.76355C7.15829 8.56829 7.15829 8.25171 7.35355 8.05645L8.05645 7.35355C8.25171 7.15829 8.56829 7.15829 8.76355 7.35355L12 10.59L15.2364 7.35355C15.4317 7.15829 15.7483 7.15829 15.9436 7.35355L16.6464 8.05645C16.8417 8.25171 16.8417 8.56829 16.6464 8.76355L13.41 12L16.6464 15.2364Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.Compare: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.01,14H2v2h7.01v3L13,15l-3.99-4V14z M14.99,13v-3H22V8h-7.01V5L11,9L14.99,13z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.ExternalLink: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1756 11.0209L19.758 3.23706L11.9237 4.54689L14.2681 6.97466L10.3445 10.7637L11.9075 12.3822L15.8311 8.59317L18.1756 11.0209ZM6 6.00003H11V8.00003H7V16H15V12H17V17C17 17.5523 16.5523 18 16 18H6C5.44772 18 5 17.5523 5 17V7.00003C5 6.44775 5.44772 6.00003 6 6.00003Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.EmailBounce: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8494 3.82052L12.0031 7.88431L5.39844 1.11328C5.25203 0.966879 5.01467 0.966879 4.86827 1.11328L3.89889 2.08266C3.75248 2.22906 3.75248 2.46643 3.89889 2.61283L11.733 10.6133C11.8058 10.6862 11.901 10.7227 11.9962 10.7231H3.40909C3.18316 10.7231 3 10.8848 3 11.0842V22.6389C3 22.8384 3.18316 23 3.40909 23H20.5909C20.8168 23 21 22.8384 21 22.6389V11.0842C21 10.8848 20.8168 10.7231 20.5909 10.7231H11.9999C12.0975 10.7227 12.1949 10.6843 12.2681 10.6084L17.3652 5.27156L19.0436 6.87824L20 1L14.1691 2.21193L15.8494 3.82052ZM12 17.6623L19.3636 12.1674H4.63636L12 17.6623ZM4.63636 21.5556V13.8943L12 19.3891L19.3636 13.8943V21.5556H4.63636Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
    case IconType.Retail: {
      return (
        <StyledPath
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.5C6 2.22386 6.22386 2 6.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4V5H21.5C21.7761 5 22 5.22386 22 5.5V13H17V10.5C17 10.2239 16.7761 10 16.5 10H7.5C7.22386 10 7 10.2239 7 10.5V13H2V5.5C2 5.22386 2.22386 5 2.5 5H6V4V2.5ZM8 4H16V5H8V4ZM17 17.5V15H22V21.5C22 21.7761 21.7761 22 21.5 22H2.5C2.22386 22 2 21.7761 2 21.5V15H7V17.5C7 17.7761 7.22386 18 7.5 18H16.5C16.7761 18 17 17.7761 17 17.5ZM15 12H9V16H15V12Z"
          $themeType={themeType}
          $color={color}
        />
      );
    }
  }
};

export default IconPath;
