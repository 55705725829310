import { formatDate } from 'utils/date';

export function downloadFile(
  data: string,
  fileName: string,
  fileType: string = 'text/csv',
  fileExtension: string = 'csv'
) {
  const a = document.createElement('a');
  const currentUrl = window.URL || window.webkitURL;
  const blob = new Blob([data], { type: fileType });
  a.href = currentUrl.createObjectURL(blob);
  a.download = `${fileName}_${formatDate(
    new Date().getTime(),
    'yyyy-MM-dd_HH_mm_ss'
  )}.${fileExtension}`;
  a.click();
}
