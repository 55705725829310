import { FC, ReactElement } from 'react';
import { Button } from './styled';

type Props = {
  children: ReactElement;
  onClick?: (e: any) => void;
};

const IconButton: FC<Props> = ({ children, onClick }) => {
  return (
    <Button onClick={onClick} type="button" data-testid="icon-button">
      {children}
    </Button>
  );
};

export default IconButton;
