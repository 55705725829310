import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  APIError,
  WarrantyConditionState,
  Warranty,
  CreateWarranty
} from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';
import { showErrorToast } from 'utils/toast';

export const defaultState: WarrantyConditionState = {
  warranty: {
    data: null,
    isLoading: false,
    isError: false,
    error: null
  },
  createWarranty: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  removeWarranty: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const warrantyConditionSlice = createSlice({
  name: 'warrantyCondition',
  initialState: defaultState,
  reducers: {
    getWarrantyInit: (state) => {
      state.warranty.data = null;
      state.warranty.isLoading = true;
      state.warranty.isError = false;
      state.warranty.error = null;
    },
    getWarrantySuccess: (state, action: PayloadAction<Warranty>) => {
      state.warranty.data = action.payload;
      state.warranty.isLoading = false;
      state.warranty.isError = false;
      state.warranty.error = null;
    },
    getWarrantyFailure: (state, action: PayloadAction<APIError>) => {
      state.warranty.data = null;
      state.warranty.isLoading = false;
      state.warranty.isError = true;
      state.warranty.error = action.payload;
    },
    resetGetWarranty: (state) => {
      state.warranty = defaultState.warranty;
    },
    createWarrantyInit: (state) => {
      state.createWarranty.isSuccess = false;
      state.createWarranty.isLoading = true;
      state.createWarranty.isError = false;
      state.createWarranty.error = null;
    },
    createWarrantySuccess: (state, action: PayloadAction<Warranty>) => {
      state.warranty.data = action.payload;

      state.createWarranty.isSuccess = true;
      state.createWarranty.isLoading = false;
      state.createWarranty.isError = false;
      state.createWarranty.error = null;
    },
    createWarrantyFailure: (state, action: PayloadAction<APIError>) => {
      state.createWarranty.isSuccess = false;
      state.createWarranty.isLoading = false;
      state.createWarranty.isError = true;
      state.createWarranty.error = action.payload;
    },
    resetCreateWarranty: (state) => {
      state.createWarranty = defaultState.createWarranty;
    },
    removeWarrantyInit: (state) => {
      state.removeWarranty.isSuccess = false;
      state.removeWarranty.isLoading = true;
      state.removeWarranty.isError = false;
      state.removeWarranty.error = null;
    },
    removeWarrantySuccess: (state) => {
      state.removeWarranty.isSuccess = true;
      state.removeWarranty.isLoading = false;
    },
    removeWarrantyFailure: (state, action: PayloadAction<APIError>) => {
      state.removeWarranty.isLoading = false;
      state.removeWarranty.isError = true;
      state.removeWarranty.error = action.payload;
    },
    resetRemoveWarranty: (state) => {
      state.removeWarranty = defaultState.removeWarranty;
    }
  }
});

// Export selectors
export const warrantyConditionSelector = (state: RootState) =>
  state.warrantyCondition.warranty;
export const createWarrantyConditionSelector = (state: RootState) =>
  state.warrantyCondition.createWarranty;
export const removeWarrantyConditionSelector = (state: RootState) =>
  state.warrantyCondition.removeWarranty;

// Export actions
export const {
  getWarrantyInit,
  getWarrantySuccess,
  getWarrantyFailure,
  resetGetWarranty,
  createWarrantyInit,
  createWarrantySuccess,
  createWarrantyFailure,
  resetCreateWarranty,
  removeWarrantyInit,
  removeWarrantySuccess,
  removeWarrantyFailure,
  resetRemoveWarranty
} = warrantyConditionSlice.actions;

// Export reducer
export const warrantyConditionReducer = warrantyConditionSlice.reducer;

// Export thunk
export function fetchWarrantyConditionByIprId(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getWarrantyInit());
    try {
      const response = await services.getWarrantyConditionByIprId(iprId);
      dispatch(getWarrantySuccess(response));
    } catch (e: any) {
      dispatch(getWarrantyFailure(e));
    }
  };
}

export function createWarrantyConditionByIprId(
  iprId: string,
  createWarranty: CreateWarranty
) {
  return async (dispatch: Dispatch) => {
    dispatch(createWarrantyInit());
    try {
      const response = await services.createWarrantyConditionByIprId(
        iprId,
        createWarranty
      );
      dispatch(createWarrantySuccess(response));
    } catch (e: any) {
      dispatch(createWarrantyFailure(e));
      showErrorToast(e.detail);
    }
  };
}

export function removeWarrantyConditionByIprId(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(removeWarrantyInit());
    try {
      await services.removeWarrantyConditionByIprId(iprId);
      dispatch(removeWarrantySuccess());
    } catch (e: any) {
      dispatch(removeWarrantyFailure(e));
      showErrorToast(e.title);
    }
  };
}
