import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';

import {
  EmptySearchResults,
  SearchArea,
  SearchButton,
  SearchContainer,
  SearchField,
  SearchIcon,
  SearchInput,
  SearchResultItem,
  SearchResults
} from './styled';

export type ResultItem = {
  id: string;
  primary: string;
  secondary: string;
  disabled?: boolean;
};

type Props = {
  onSelect: (id: string) => void;
  onSearch: (value: string) => void;
  defaultValue?: string;
  isFetching: boolean;
  placeholder: string;
  results: ResultItem[];
  disabled?: boolean;
};

const TypeaheadSearchField: FC<Props> = ({
  onSearch,
  onSelect,
  results,
  defaultValue = '',
  placeholder,
  isFetching,
  disabled
}) => {
  // Refs
  const searchRef = useRef<HTMLDivElement>(null);

  // Hooks
  const [isActive, setIsActive] = useDetectOutsideClick([searchRef], false);

  // State
  const [value, setValue] = useState<string>(defaultValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Implement search delay
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isLoading) {
      timer = setTimeout(() => {
        onSearch(value);
        setIsLoading(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [onSearch, value, isLoading]);

  // Search
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsLoading(true);
  }, []);

  // Reset search
  const onReset = useCallback(() => {
    onSearch('');
    setValue('');
  }, [onSearch]);

  // Active
  const onFocus = useCallback(() => setIsActive(true), [setIsActive]);
  const onBlur = useCallback(() => setIsActive(false), [setIsActive]);

  // Select result
  const onSelectResult = useCallback(
    (item: ResultItem) => () => {
      onSelect(item.id);
      onBlur();
    },
    [onBlur, onSelect]
  );

  // Results
  const showResults = useMemo(() => {
    if (isLoading || isFetching) {
      return <Loader center padding size="small" />;
    }
    if (!results.length) {
      return (
        <EmptySearchResults>
          <FormattedMessage id="search.no_results" />
        </EmptySearchResults>
      );
    }
    return results.map((result) => (
      <SearchResultItem key={result.id} onClick={onSelectResult(result)}>
        <p>{result.primary}</p>
        <span>{result.secondary}</span>
      </SearchResultItem>
    ));
  }, [onSelectResult, isLoading, isFetching, results]);

  return (
    <SearchContainer ref={searchRef}>
      <SearchField>
        <SearchIcon>
          <Icon type={IconType.Search} themeType="grey6" />
        </SearchIcon>
        <SearchInput
          onChange={onChange}
          onFocus={onFocus}
          $isActive={isActive}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
        <SearchArea>
          {results.length > 0 && (
            <FormattedMessage
              id="search.hits"
              values={{ value: results.length }}
            />
          )}
          {value.length > 0 && (
            <SearchButton type="button" onClick={onReset}>
              <Icon type={IconType.Close} themeType="grey6" size="small" />
            </SearchButton>
          )}
        </SearchArea>
      </SearchField>
      <SearchResults $isActive={isActive}>{showResults}</SearchResults>
    </SearchContainer>
  );
};

export default TypeaheadSearchField;
