import { ProductAttributes } from 'models/product';
import { Customer, CustomerRelation } from 'models/customers';
import { APIError } from 'models/errors';

export enum ContractFilterAttribute {
  Id = 'id',
  Status = 'status',
  ServicePlanId = 'servicePlanId',
  Type = 'type',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ServiceContractProduct = 'serviceContractProduct',
  CustomerId = 'customerId',
  IprId = 'iprId',
  SellerId = 'sellerId',
  PaymentProvider = 'paymentProvider',
  Email = 'email',
  CustomerType = 'customerType'
}

export enum ContractStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  OnHold = 'ON_HOLD',
  NeverActivated = 'NEVER_ACTIVATED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

export enum ContractCancellationReason {
  NotPaid = 'NOT_PAID',
  CustomerRequested = 'CUSTOMER_REQUESTED',
  NotFollowingServicePlan = 'NOT_FOLLOWING_SERVICEPLAN'
}

export enum EditServiceContractStatusField {
  Status = 'status',
  CancelReason = 'cancellationReason'
}

export enum MoveServiceContractField {
  Pnc = 'pnc',
  SerialNumber = 'serialNumber'
}

export type ContractStatusUpdate = {
  status: ContractStatus;
  cancellationUser?: string;
  cancellationReason?: string;
  cancellationDate?: number;
};

export type ContractFilter = {
  attribute: ContractFilterAttribute;
  value: string;
};

export type ContractFilterObject = {
  type: string;
  startDate: string;
  endDate: string;
  paymentProvider: string;
  status: string;
  sellerId: string;
  email: string;
  customerType: string;
};

export type TermsAndConditions = {
  id: string;
  type: string;
  attributes: {
    publicationType: string;
    publicationTitle: Record<string, string>;
    region: Record<string, string>;
    publicationId: string;
    created: number;
    updated: number;
    binaryLastModified: number;
    languages: string[];
    publishedBy: Record<string, any>;
    format: string;
    link: string;
    sizeBytes: number;
    metaData: Record<string, any>;
    sections: any[];
    documentArticleNumber: string;
    originalFilename: string;
    filename: string;
    intendedCountries: string[];
    intendedCountriesType: string;
    checksum: string;
  };
};

export type SalesOrder = {
  id: string;
  type: string;
  attributes: {
    totalPriceInclVAT: number;
    totalPriceExclVAT: number;
    monthlyPriceInclVAT: number;
    monthlyPriceExclVAT: number;
    currency: string;
    paymentMethod: string;
    paymentProvider: string;
    paymentReference: string;
    sellerIdType: string;
    sellerId: string;
    date: number;
  };
};

export type ServiceContract = {
  id: string;
  type: string;
  attributes: {
    type: string;
    iprId: string;
    salesOrder: SalesOrder;
    serviceContractProduct: string;
    servicePlanId: string;
    status: string;
    startDate: number;
    endDate: number;
    customerId?: string;
    termsAndConditions: TermsAndConditions[];
  };
};

export type ServiceContractSuccess = {
  response: ServiceContract[];
  filters: ContractFilter[] | null;
};

export type ServiceContractProduct = {
  data: ProductAttributes;
  type: string;
};

export type ServiceContractResponse = {
  id: string;
  type: string;
  attributes: {
    customer: Customer | null;
    product: ServiceContractProduct;
    relation: CustomerRelation;
    serviceContract: ServiceContract;
    marketName: string;
  };
};

export type ContractList = {
  data: ServiceContract[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
  filters: {
    active: boolean;
    data: ContractFilter[] | null;
  };
  exports: {
    data: string | null;
    errors: APIError[];
    isLoading: boolean;
  };
};

export type ContractDetails = {
  data: ServiceContractResponse | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ContractUpdateStatus = {
  data: ContractStatus | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type SendMail = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type MoveContract = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ContractState = {
  list: ContractList;
  details: ContractDetails;
  status: ContractUpdateStatus;
  sendMail: SendMail;
  move: MoveContract;
};

export type ContractExportResponse = {
  data: string;
  errors: APIError[];
};
