import { FC, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';

import * as routes from 'router/Routes';
import { ServiceContract } from 'models';
import { formatDate } from 'utils/date';
import { getContractStatusColors } from 'utils/serviceContracts';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Tag from 'components/UI/Tag';

// Header cells
const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'product_details.contracts_table_id'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_type'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_status'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_start_date'
  },
  {
    sortable: true,
    intl: 'product_details.contracts_table_end_date'
  }
];

type Props = {
  contracts: ServiceContract[];
};

const ProductContractCard: FC<Props> = ({ contracts }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Click
  const onClick = useCallback(
    (id: string) => {
      navigate(routes.serviceContractDetailsLink(id), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Status
  const renderStatus = useCallback((status: string) => {
    const { background, color } = getContractStatusColors(status);
    return (
      <Tag background={background} color={color}>
        {status}
      </Tag>
    );
  }, []);

  return (
    <Card>
      <CardHeader intl="product_details.contracts_title" />
      <SortableTable
        headerCells={headerCells}
        bodyRows={contracts.map(({ id, attributes }) => ({
          id,
          type: attributes.type,
          status: renderStatus(attributes.status),
          startDate: formatDate(attributes.startDate, 'yyyy-MM-dd'),
          endDate: formatDate(attributes.endDate, 'yyyy-MM-dd'),
          link: id
        }))}
        emptyTextId="product_details.contracts_not_found"
        onClick={onClick}
      />
    </Card>
  );
};

export default ProductContractCard;
