import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ProductFilter, ProductFilterAttribute } from 'models';
import { createFilters, validateField } from 'utils/filtering';

import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';

import { ButtonGrid, ThreeColumnGrid } from 'styles';

type Props = {
  onFilter: (value: ProductFilter[]) => void;
};

const ProductFilters: FC<Props> = ({ onFilter }) => {
  // Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  // Watch fields
  const [pnc, localIdGardena] = watch([
    ProductFilterAttribute.PNC,
    ProductFilterAttribute.LocalIdGardena
  ]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const filters = createFilters<ProductFilter>(values);
      if (filters.length) {
        onFilter(filters);
      }
    },
    [onFilter]
  );

  // Reset
  const onReset = () =>
    reset({
      [ProductFilterAttribute.PNC]: '',
      [ProductFilterAttribute.SN]: '',
      [ProductFilterAttribute.LocalIdGardena]: ''
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ThreeColumnGrid>
        <InputField
          type="text"
          description="products.input_pnc"
          register={register(ProductFilterAttribute.PNC, {
            required: validateField(!localIdGardena, 'input.required'),
            minLength: {
              value: 9,
              message: 'input.wrong_length'
            },
            maxLength: {
              value: 9,
              message: 'input.wrong_length'
            }
          })}
          disabled={localIdGardena}
          error={errors.pnc}
        />
        <InputField
          type="text"
          description="products.filter_gardena_id"
          register={register(ProductFilterAttribute.LocalIdGardena, {
            required: validateField(!pnc, 'input.required')
          })}
          disabled={pnc}
          error={errors.pnc}
        />
        <InputField
          type="text"
          description="products.input_serial"
          register={register(ProductFilterAttribute.SN, {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.serialNumber}
        />
      </ThreeColumnGrid>
      <ButtonGrid>
        <Button
          onClick={onReset}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button submit>
          <FormattedMessage id="search.advanced_btn_submit" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default ProductFilters;
