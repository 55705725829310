import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import countries from 'assets/data/countries.json';
import languages from 'assets/data/languages.json';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  fetchPasswordPolicies,
  fetchUserAuditLogsById,
  findUserById,
  updateUserById,
  updateUserPasswordPolicyById,
  updateUserPasswordPolicyReset,
  updateUserReset,
  userPasswordPolicySelector,
  userUpdateSelector
} from 'store';
import { User, UserBody } from 'models';
import { showSuccessToast } from 'utils/toast';

import SelectField, { OptionType } from 'components/UI/SelectField';
import InputField from 'components/UI/InputField';
import EditModal from 'components/UI/EditModal';
import Heading, { Type } from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';

import { Description, ButtonGrid } from './styled';

type Props = {
  user: User;
  open: boolean;
  onClose: () => void;
};

const EditUserModal: FC<Props> = ({ user, open, onClose }) => {
  const intl = useIntl();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(userUpdateSelector);
  const { list, update } = useAppSelector(userPasswordPolicySelector);

  // Props
  const {
    firstName,
    lastName,
    country,
    language,
    city,
    postalAddress,
    postalCode,
    address2,
    telephoneNumber,
    passwordPolicy
  } = user.attributes;

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      country,
      language,
      telephoneNumber,
      postalAddress,
      postalCode,
      city,
      address2,
      passwordPolicy: passwordPolicy.id
    }
  });

  // Watch
  const [countryInput, languageInput, policyInput] = watch([
    'country',
    'language',
    'passwordPolicy'
  ]);

  // Fetch new user data
  const fetchUserData = useCallback(() => {
    if (userId) {
      dispatch(findUserById(userId));
      dispatch(fetchUserAuditLogsById(userId));
    }
  }, [dispatch, userId]);

  // Fetch all DIAM password policies if they have not already been fetched
  useEffect(() => {
    if (!list.data) {
      dispatch(fetchPasswordPolicies());
    }

    return () => {
      dispatch(updateUserReset());
      dispatch(updateUserPasswordPolicyReset());
    };
  }, [dispatch, list.data]);

  // Policy change success
  useEffect(() => {
    if (update.isSuccess) {
      showSuccessToast(intl.formatMessage({ id: 'modal.edit_user_success' }));
      fetchUserData();
    }
  }, [fetchUserData, update, intl]);

  // Close modal on success
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(intl.formatMessage({ id: 'modal.edit_user_success' }));
      fetchUserData();
      onClose();
    }
  }, [fetchUserData, onClose, isSuccess, intl]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const body: UserBody = {
        first_name: values.firstName,
        last_name: values.lastName,
        country: values.country,
        language: values.language,
        postal_address: values.postalAddress,
        postal_code: values.postalCode,
        city: values.city,
        address2: values.address2,
        telephone_number: values.telephoneNumber
      };
      dispatch(updateUserById(user.id, body));
      reset(values);
    },
    [dispatch, reset, user]
  );

  // Password policy
  const onSelectPasswordPolicy = useCallback(
    (option: OptionType) => {
      if (option) {
        dispatch(updateUserPasswordPolicyById(user.id, option.value));
        setValue('passwordPolicy', option.value, { shouldDirty: true });
      }
    },
    [dispatch, setValue, user]
  );

  // Select option
  const onSelect = useCallback(
    (name: 'country' | 'language') => (option: OptionType) =>
      setValue(name, option.value, { shouldDirty: true }),
    [setValue]
  );

  // Country options
  const countryOptions = useMemo(() => {
    return countries.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  // Language options
  const languageOptions = useMemo(() => {
    return languages.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  // Password policy options
  const policyOptions = useMemo(() => {
    if (!list.data) {
      return [];
    }
    return list.data.map((item) => ({
      key: item.id,
      label: item.id,
      value: item.id
    }));
  }, [list]);

  // Render data
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding center />;
    }
    return (
      <Fragment>
        <Heading type={Type.h4}>
          <FormattedMessage id="modal.edit_user_general_title" />
        </Heading>
        <InputField
          description="modal.edit_user_input_firstname"
          register={register('firstName', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.firstName}
        />
        <InputField
          description="modal.edit_user_input_lastname"
          register={register('lastName', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.lastName}
        />
        <SelectField
          name="country"
          register={register('country')}
          onSelect={onSelect('country')}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'modal.edit_user_input_country' })}
          options={countryOptions}
          value={countryInput}
          error={errors.country}
          enableSearch
        />
        <SelectField
          name="language"
          register={register('language')}
          onSelect={onSelect('language')}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'modal.edit_user_input_language' })}
          options={languageOptions}
          value={languageInput}
          error={errors.language}
          enableSearch
        />
        <InputField
          description="modal.edit_user_input_phonenumber"
          register={register('telephoneNumber')}
          error={errors.telephoneNumber}
        />
        <SelectField
          name="passwordPolicy"
          register={register('passwordPolicy')}
          onSelect={onSelectPasswordPolicy}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({
            id: 'modal.edit_user_input_password_policy'
          })}
          message={intl.formatMessage({ id: 'modal.immediate_change' })}
          options={policyOptions}
          value={policyInput}
          error={errors.passwordPolicy}
        />
        <Heading type={Type.h4}>
          <FormattedMessage id="modal.edit_user_address_title" />
        </Heading>
        <InputField
          description="modal.edit_user_input_address"
          register={register('postalAddress')}
          error={errors.postalAddress}
        />
        <InputField
          description="modal.edit_user_input_postalcode"
          register={register('postalCode')}
          error={errors.postalCode}
        />
        <InputField
          description="modal.edit_user_input_city"
          register={register('city')}
          error={errors.city}
        />
        <InputField
          description="modal.edit_user_input_additional"
          register={register('address2')}
          error={errors.address2}
        />
      </Fragment>
    );
  }, [
    register,
    onSelect,
    onSelectPasswordPolicy,
    intl,
    errors,
    isLoading,
    countryInput,
    languageInput,
    policyInput,
    countryOptions,
    languageOptions,
    policyOptions
  ]);

  return (
    <EditModal title="modal.edit_user_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Description>
          <p>
            <FormattedMessage
              id="modal.edit_user_text"
              values={{
                name: `${firstName} ${lastName}`
              }}
            />
          </p>
          <p>({user.attributes.username})</p>
        </Description>
        {content}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || isLoading} submit>
            <FormattedMessage id="modal.btn_update" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditUserModal;
