import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AuditLogAttributes } from 'models';
import { formatDate } from 'utils/date';

import Heading, { Type } from 'components/UI/Heading';
import EditModal from 'components/UI/EditModal';
import InfoItem from 'components/UI/InfoItem';
import DiffView from 'components/UI/DiffView';

import { DetailGroup } from './styled';

type Props = {
  data: AuditLogAttributes | null;
  onClose: () => void;
};

/**
 * Modal showing audit log changes
 */
const AuditDiffModal: FC<Props> = ({ data, onClose }) => {
  const intl = useIntl();

  // Render details
  const renderDetails = useCallback(() => {
    if (!data) {
      return null;
    }
    const details = [
      {
        id: 1,
        title: 'customers.auditlog_table_email',
        value: data.userEmail
      },
      {
        id: 2,
        title: 'customers.auditlog_table_user_name',
        value: data.userFullName
      },
      {
        id: 3,
        title: 'customers.auditlog_table_operation',
        value: data.operationType
      },
      {
        id: 4,
        title: 'customers.auditlog_table_customer_id',
        value: data.customerId
      },
      {
        id: 5,
        title: 'customers.auditlog_table_product_id',
        value: data.iprId
      },
      {
        id: 6,
        title: 'customers.table_date',
        value: formatDate(data.createdDate, 'yyyy-MM-dd HH:mm')
      }
    ];
    return details.map(({ id, title, value }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={value ?? '—'}
      />
    ));
  }, [data, intl]);

  const diffData = useMemo(() => {
    if (!data) {
      return null;
    }
    return (
      <Fragment>
        <Heading type={Type.h3}>
          <FormattedMessage id="customers.auditlog_table_changes" />
        </Heading>
        <DiffView before={data.dataBefore} after={data.dataAfter} />
      </Fragment>
    );
  }, [data]);

  return (
    <EditModal title="modal.audit_title" open={Boolean(data)} close={onClose}>
      <div>
        <DetailGroup>{renderDetails()}</DetailGroup>
        {diffData}
      </div>
    </EditModal>
  );
};

export default AuditDiffModal;
