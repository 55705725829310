import { FC, ReactNode } from 'react';
import { Root } from './styled';

type TableBodyProps = {
  children?: ReactNode;
};

const TableBody: FC<TableBodyProps> = ({ children }) => {
  return <Root>{children}</Root>;
};

export default TableBody;
