import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ServiceContract } from 'models';
import { formatDate } from 'utils/date';
import * as routes from 'router/Routes';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';

const headerCells: HeaderCell[] = [
  {
    intl: 'contracts.contract_table_id',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_type',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_contract_pnc',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_start_date',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_end_date',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_status',
    sortable: true
  },
  {
    intl: 'contracts.contract_table_payment_method',
    sortable: true
  }
];

type Props = {
  contracts: ServiceContract[];
};

const ServiceContractTable: FC<Props> = ({ contracts }) => {
  const navigate = useNavigate();

  // On click
  const onClick = useCallback(
    (id: string) => navigate(routes.serviceContractDetailsLink(id)),
    [navigate]
  );

  // Body
  const bodyRows = contracts.map(({ id, attributes }: ServiceContract) => {
    const {
      type,
      serviceContractProduct,
      startDate,
      endDate,
      status,
      salesOrder
    } = attributes;
    const { paymentMethod } = salesOrder.attributes;

    return {
      id,
      type: type || '–',
      product: serviceContractProduct || '–',
      startDate: startDate ? formatDate(startDate, 'yyyy-MM-dd') : '–',
      endDate: endDate ? formatDate(endDate, 'yyyy-MM-dd') : '–',
      status: status || '–',
      paymentMethod: paymentMethod || '–',
      link: id
    };
  });

  return (
    <SortableTable
      headerCells={headerCells}
      bodyRows={bodyRows}
      onClick={onClick}
      sortIndex={2}
    />
  );
};

export default ServiceContractTable;
