import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 77px);
  position: relative;
  overflow-y: scroll;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  max-width: ${breakpoints.xl + 'px'};

  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 16px;
`;

export const NavButtons = styled.div`
  display: flex;
  align-items: center;
`;
