import { FC, useMemo, useState } from 'react';
import ArrowIcon from 'components/UI/Icon/ArrowIcon';
import DropdownMenu, { MenuItem } from 'components/UI/DropdownMenu';

import { Button, Text, Subtext } from './styled';

type Props = {
  text?: string;
  subtext?: string;
  menu: MenuItem[];
  light?: boolean;
};

const NavDropdownButton: FC<Props> = ({ text, subtext, menu, light }) => {
  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Content
  const content = useMemo(() => {
    if (!text && !subtext) {
      return null;
    }
    return (
      <div>
        {text && <Text $light={light}>{text}</Text>}
        {subtext && <Subtext $light={light}>{subtext}</Subtext>}
      </div>
    );
  }, [text, subtext, light]);

  return (
    <DropdownMenu menu={menu} onClick={setIsOpen}>
      <Button>
        {content}
        <ArrowIcon
          direction={isOpen ? 'up' : 'down'}
          themeType={light ? 'onPrimary' : 'onSurface'}
        />
      </Button>
    </DropdownMenu>
  );
};

export default NavDropdownButton;
