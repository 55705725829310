import { useMemo } from 'react';
import { authSelector, productDetailsSelector } from 'store';
import {
  getSalesCompanyCodesFromCustomerRelations,
  getUserRoles,
  validateAzureEditPermission
} from 'utils/roles';
import { useAppSelector } from './redux';

type Props = { allowAnySalesCompanyAdmin: boolean };

export const useValidateAzureEditProduct = ({
  allowAnySalesCompanyAdmin = false
}: Props) => {
  const { session } = useAppSelector(authSelector);
  const product = useAppSelector(productDetailsSelector);

  // Can edit
  return useMemo(() => {
    const roles = getUserRoles(session);

    const salesCompanyCodes = getSalesCompanyCodesFromCustomerRelations(
      product.relations
    );

    return validateAzureEditPermission(
      roles.azure,
      salesCompanyCodes,
      allowAnySalesCompanyAdmin
    );
  }, [session, product, allowAnySalesCompanyAdmin]);
};
