import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/UI/Modal';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';

import { ModalBody, ModalClose, ModalHeader } from './styled';

type Props = {
  open: boolean;
  close: () => void;
  title: string;
  children: ReactNode;
};

const EditModal: FC<Props> = ({ open, close, title, children }) => {
  return (
    <Modal open={open} close={close}>
      <ModalHeader>
        <Heading type={HeadingType.h2}>
          <FormattedMessage id={title} />
        </Heading>
        <ModalClose onClick={close}>
          <Icon type={IconType.Close} />
        </ModalClose>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default EditModal;
