import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ConfirmDeleteCustomer, CustomerAttributes } from 'models';
import { getCustomerName } from 'utils/customer';
import { showSuccessToast } from 'utils/toast';
import * as routes from 'router/Routes';

import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';

import { ButtonGrid } from './styled';

type Props = {
  customer: CustomerAttributes;
  deleteCustomer: ConfirmDeleteCustomer;
  onClose: () => void;
  open: boolean;
  onConfirmDeleteCustomer: (reason: string) => void;
};

const ConfirmDeleteCustomerModal: FC<Props> = ({
  customer,
  deleteCustomer,
  open,
  onClose,
  onConfirmDeleteCustomer
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ reason }) => {
      onConfirmDeleteCustomer(reason);
    },
    [onConfirmDeleteCustomer]
  );

  // Success
  useEffect(() => {
    if (deleteCustomer.isSuccess) {
      showSuccessToast(
        intl.formatMessage({ id: 'modal.confirm_delete_customer_success' })
      );
      navigate(routes.CUSTOMERS);
      onClose();
    }
  }, [navigate, onClose, intl, deleteCustomer.isSuccess]);

  // Inputs
  const formData = useMemo(() => {
    if (deleteCustomer.isLoading) {
      return <Loader padding center />;
    }

    return (
      <InputField
        description="customer_details.confirm_delete_customer_reason"
        register={register('reason', {
          required: {
            value: true,
            message: 'input.confirm_delete_customer_reason'
          }
        })}
        error={errors.reason}
      />
    );
  }, [register, deleteCustomer.isLoading, errors.reason]);
  return (
    <EditModal
      title="modal.confirm_delete_customer_title"
      open={open}
      close={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          <FormattedMessage
            id="modal.confirm_delete_customer_text"
            values={{
              name: getCustomerName(customer)
            }}
          />
        </p>
        {formData}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button
            submit
            backgroundColor="danger"
            color="surface"
            disabled={deleteCustomer.isLoading}
          >
            <FormattedMessage id="modal.btn_confirm" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};
export default ConfirmDeleteCustomerModal;
