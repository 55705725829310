import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { useTheme } from 'styled-components';

import InfoItem from 'components/UI/InfoItem';
import { useNumberFormat } from 'hooks/useNumberFormat';
import { UserStatistics } from 'models';
import { sortObjectArray, SortOrder } from 'utils/array';
import {
  userStatisticsNumberOfBars,
  userStatisticsTickCharacterLimit
} from 'utils/constants';
import { truncateText } from 'utils/string';

import {
  LegendCol,
  LegendDataCol,
  LegendGrid,
  LegendItem,
  LegendItemIcon,
  LegendItemText
} from './styled';

function tickFormatter(tick: string) {
  return truncateText(tick, userStatisticsTickCharacterLimit);
}

type Props = {
  statistics: UserStatistics[];
};

const UserStatisticsChart: FC<Props> = ({ statistics }) => {
  const intl = useIntl();
  const theme = useTheme();
  const numberFormatter = useNumberFormat();

  const data = useMemo(() => {
    const stats = statistics
      .filter((data) => data.id !== 'iam')
      .map((data) => ({
        name: data.id,
        total: data.attributes.total,
        enabled: data.attributes.enabled
      }));

    return sortObjectArray(stats, 'total', SortOrder.Desc).slice(
      0,
      userStatisticsNumberOfBars
    );
  }, [statistics]);

  const totals = useMemo(() => {
    const iamService = statistics.find((data) => data.id === 'iam');

    return {
      total: iamService?.attributes?.total,
      enabled: iamService?.attributes?.enabled
    };
  }, [statistics]);

  return (
    <ResponsiveContainer minHeight={500} minWidth={1000}>
      <BarChart data={data} margin={{ top: 24 }}>
        <CartesianGrid stroke={theme.colors.grey2} vertical={false} />
        <XAxis
          dataKey="name"
          interval={0}
          tickFormatter={tickFormatter}
          axisLine={{ stroke: theme.colors.grey2 }}
          tickLine={{ display: 'none' }}
        />
        <YAxis
          fontWeight="bold"
          fontSize={14}
          tick={{ fill: theme.colors.grey9 }}
          tickFormatter={(tick) => numberFormatter.format(tick)}
          axisLine={{ stroke: theme.colors.grey2 }}
          tickLine={{ stroke: theme.colors.grey2 }}
          width={80}
        />
        <Tooltip
          cursor={{ fill: theme.colors.grey2 }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{ borderRadius: 8 }}
          formatter={(value, name) => [
            numberFormatter.format(value as number),
            intl.formatMessage({
              id: `user_statistics.legend_${name}`
            })
          ]}
        />
        <Legend
          layout="vertical"
          iconSize={16}
          iconType="square"
          verticalAlign="top"
          align="right"
          content={
            <LegendGrid>
              <LegendCol>
                <LegendItem>
                  <LegendItemIcon $color="grey4" />
                  <LegendItemText>
                    <FormattedMessage id="user_statistics.legend_total" />
                  </LegendItemText>
                </LegendItem>
                <LegendItem>
                  <LegendItemIcon $color="primary" />
                  <LegendItemText>
                    <FormattedMessage id="user_statistics.legend_enabled" />
                  </LegendItemText>
                </LegendItem>
              </LegendCol>
              <LegendDataCol>
                <InfoItem
                  title={intl.formatMessage({
                    id: 'user_statistics.users_total'
                  })}
                  value={
                    totals.total ? numberFormatter.format(totals.total) : '-'
                  }
                />
                <InfoItem
                  title={intl.formatMessage({
                    id: 'user_statistics.users_enabled'
                  })}
                  value={
                    totals.enabled
                      ? numberFormatter.format(totals.enabled)
                      : '-'
                  }
                />
              </LegendDataCol>
            </LegendGrid>
          }
        />
        <Bar dataKey="total" fill={theme.colors.grey4} radius={[8, 8, 0, 0]} />
        <Bar
          dataKey="enabled"
          fill={theme.colors.primary}
          radius={[8, 8, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UserStatisticsChart;
