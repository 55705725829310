import { FC, useCallback } from 'react';
import ArrowIcon from '../ArrowIcon';
import { Root } from './styled';

type ArrowDirection = 'up' | 'down';

type Props = {
  active?: boolean;
  direction?: ArrowDirection;
};

const SortIcon: FC<Props> = ({ active = false, direction = 'up' }) => {
  const renderArrow = useCallback(
    (arrowDirection: ArrowDirection, visible: boolean = true) => {
      if (!visible) {
        return null;
      }
      return (
        <ArrowIcon
          transition={true}
          transitionDuration={0.2}
          direction={arrowDirection}
          themeType="primary"
        />
      );
    },
    []
  );

  return (
    <Root $active={active} data-testid="sort-icon">
      {renderArrow(active ? direction : 'up')}
      {renderArrow('down', !active)}
    </Root>
  );
};

export default SortIcon;
