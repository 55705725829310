import styled from 'styled-components';
import { boxShadows, breakpoints } from 'styles';

export const SmallScreenControls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.surface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  box-shadow: ${boxShadows.md};

  @media (min-width: ${breakpoints.l - 1}px) {
    display: none;
  }
`;

export const SmallScreenControlSpacer = styled.div`
  height: 72px;

  @media (min-width: ${breakpoints.l - 1}px) {
    display: none;
  }
`;

export const SmallScreenButtonCol = styled.div`
  min-width: 40px;
`;
