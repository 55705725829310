import styled from 'styled-components';

type MenuProps = {
  $isActive: boolean;
};

export const MenuContainer = styled.div`
  position: relative;
`;

export const MenuButton = styled.div`
  background: none;
  padding: 0;
  border: none;
  margin: 0 auto 0 0;
`;

export const Menu = styled.nav<MenuProps>`
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 54px;
  right: 16px;
  width: 100%;
  max-width: 200px;
  min-width: 72px;
  text-align: left;
  z-index: 99;
  overflow: hidden;
  box-shadow:
    0px 12px 24px rgba(0, 0, 0, 0.05),
    0px 2px 16px rgba(0, 0, 0, 0.12);
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  transform: ${({ $isActive }) =>
    $isActive ? 'translateY(0)' : 'translateY(-20px)'};
  transition:
    opacity 0.4s ease,
    transform 0.4s ease,
    visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid #dddddd;
  }
`;

export const MenuItemInner = styled.div`
  padding: 16px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.grey9};
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
