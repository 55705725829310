import * as config from 'config';
import {
  SCPUserResponse,
  UserGroup,
  UserGroupResponse,
  UserPasswordPolicy,
  UserPasswordPolicyResponse,
  UserRole,
  UserRoleResponse
} from 'models';
import { apiFetch, defaultHeaders } from 'utils/fetch';

export async function refreshToken(): Promise<boolean> {
  const url = `${config.backendApi}/diam/refresh`;

  const res = await fetch(url, {
    credentials: 'include',
    headers: defaultHeaders
  });

  return res.ok;
}

export async function healthCheck(): Promise<void> {
  const url = `${config.backendApi}/alive`;

  await apiFetch<any>(url);
}

export function loginRedirect(languageCode: string, email?: string): void {
  let url = `${config.authUrl}?client_id=${config.authClientId}&redirect_uri=${config.backendApi}/diam/authorize&language=${languageCode}`;

  if (email) {
    url += `&username=${encodeURIComponent(email)}`;
  }

  window.location.assign(url);
}

export async function getUserInfo(): Promise<SCPUserResponse> {
  const url = `${config.backendApi}/diam/user-info`;

  const json = await apiFetch<any>(url);

  return json.data;
}

export async function getUserGroups(): Promise<UserGroup[]> {
  const url = `${config.backendApi}/diam/groups`;

  const json = await apiFetch<UserGroupResponse>(url);

  return json.data;
}

export async function getUserRoles(): Promise<UserRole[]> {
  const url = `${config.backendApi}/diam/roles`;

  const json = await apiFetch<UserRoleResponse>(url);

  return json.data;
}

export async function getPasswordPolicies(): Promise<UserPasswordPolicy[]> {
  const url = `${config.backendApi}/diam/password-policies`;

  const json = await apiFetch<UserPasswordPolicyResponse>(url);

  return json.data;
}

export async function logout(): Promise<void> {
  const url = `${config.backendApi}/diam/logout`;

  return apiFetch<any>(url, {
    method: 'POST'
  });
}

export async function sendPasswordRecovery(username: string): Promise<void> {
  const url = `${config.backendApi}/diam/password-recovery/${encodeURIComponent(
    username
  )}`;

  await apiFetch<void>(url, {
    method: 'POST'
  });
}
