import { useMemo } from 'react';
import { localizationSelector } from 'store';
import { useAppSelector } from './redux';

export const useDisplayNames = (
  type: Intl.DisplayNamesType
): Intl.DisplayNames => {
  const { locale } = useAppSelector(localizationSelector);

  return useMemo(() => {
    return new Intl.DisplayNames(locale, {
      type,
      fallback: 'code'
    });
  }, [locale, type]);
};
