import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;

  > svg {
    width: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 8px;
    gap: 8px;
  }
`;

type TextProps = {
  $light?: boolean;
};

export const Text = styled.p<TextProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: normal;
  color: ${({ theme, $light }) =>
    $light ? theme.colors.onPrimary : theme.colors.grey9};
  font-size: 16px;
  margin: 0;
`;

export const Subtext = styled.p<TextProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme, $light }) =>
    $light ? theme.colors.onPrimary : theme.colors.primary};
  font-size: 14px;
  margin: 0;
`;
