import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  AuditLog,
  AuditLogFilter,
  AuditState,
  APIError,
  EmailBounce
} from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: AuditState = {
  auditLogs: {
    data: [],
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  emailBounces: {
    data: [],
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const auditSlice = createSlice({
  name: 'audit',
  initialState: defaultState,
  reducers: {
    getAuditLogs: (state) => {
      state.auditLogs.isSuccess = false;
      state.auditLogs.isLoading = true;
      state.auditLogs.isError = false;
      state.auditLogs.error = null;
    },
    getAuditLogsSuccess: (state, action: PayloadAction<AuditLog[]>) => {
      state.auditLogs.isSuccess = true;
      state.auditLogs.isLoading = false;
      state.auditLogs.data = action.payload;
    },
    getAuditLogsFailure: (state, action: PayloadAction<APIError>) => {
      state.auditLogs.data = [];
      state.auditLogs.isLoading = false;
      state.auditLogs.isError = true;
      state.auditLogs.error = action.payload;
    },
    resetAuditLogsState: (state) => {
      state.auditLogs = defaultState.auditLogs;
    },
    getEmailBounces: (state) => {
      state.emailBounces.isSuccess = false;
      state.emailBounces.isLoading = true;
      state.emailBounces.isError = false;
      state.emailBounces.error = null;
    },
    getEmailBouncesSuccess: (state, action: PayloadAction<EmailBounce[]>) => {
      state.emailBounces.isSuccess = true;
      state.emailBounces.isLoading = false;
      state.emailBounces.data = action.payload;
    },
    getEmailBouncesFailure: (state, action: PayloadAction<APIError>) => {
      state.emailBounces.data = [];
      state.emailBounces.isLoading = false;
      state.emailBounces.isError = true;
      state.emailBounces.error = action.payload;
    },
    resetEmailBouncesState: (state) => {
      state.emailBounces = defaultState.emailBounces;
    }
  }
});

// Export selectors
export const auditLogsSelector = (state: RootState) => state.audit.auditLogs;
export const emailBouncesSelector = (state: RootState) =>
  state.audit.emailBounces;

// Export actions
export const {
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  resetAuditLogsState,
  getEmailBounces,
  getEmailBouncesSuccess,
  getEmailBouncesFailure,
  resetEmailBouncesState
} = auditSlice.actions;

// Export reducer
export const auditReducer = auditSlice.reducer;

// Export thunk
export function fetchAuditLogs(filters: AuditLogFilter[]) {
  return async (dispatch: Dispatch) => {
    dispatch(getAuditLogs());
    try {
      const response = await services.fetchAuditLogs(filters);
      dispatch(getAuditLogsSuccess(response.data));
    } catch (e: any) {
      dispatch(getAuditLogsFailure(e));
    }
  };
}

export function fetchUserAuditLogsById(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getAuditLogs());
    try {
      const response = await services.fetchUserAuditLogsById(userId);
      dispatch(getAuditLogsSuccess(response.data));
    } catch (e: any) {
      dispatch(getAuditLogsFailure(e));
    }
  };
}

export function fetchEmailBouncesByUserId(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getEmailBounces());
    try {
      const response = await services.fetchEmailBouncesByUserId(userId);
      dispatch(getEmailBouncesSuccess(response.data));
    } catch (e: any) {
      dispatch(getEmailBouncesFailure(e));
    }
  };
}
