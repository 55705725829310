import styled from 'styled-components';

export const Container = styled.span`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  > div {
    &:last-child {
      grid-column: span 3;
    }
  }
`;
