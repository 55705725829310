import { FC, useMemo } from 'react';

import { ServicePlanFlatInterval, ServicePlanPart } from 'models';
import { getServicePlanTitle } from 'utils/product';
import { TableRowData } from 'models/table';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import CardHeader from 'components/UI/Card/CardHeader';
import Card from 'components/UI/Card';

import ServicePlanStatus from '../ServicePlanStatus';
import { StatusGrid } from './styled';

type Props = {
  title: string;
  allParts: ServicePlanPart[];
  intervals: ServicePlanFlatInterval[];
  humanResource?: string;
  smallScreen: boolean;
  activeIndex: number;
};

const ServicePlanCard: FC<Props> = ({
  title,
  allParts,
  intervals,
  humanResource,
  smallScreen,
  activeIndex
}) => {
  // Header cells
  const headerCells = useMemo<HeaderCell[]>(() => {
    const cells = intervals.map((interval) => ({
      title: getServicePlanTitle(interval),
      sortable: true
    }));

    // Only show active cell on small screen
    const intervalCells = smallScreen
      ? cells.filter((item) => item.title === cells[activeIndex].title)
      : cells;

    return [
      {
        title: 'Part',
        sortable: true
      },
      ...intervalCells
    ];
  }, [intervals, smallScreen, activeIndex]);

  // Body rows
  const bodyRows = useMemo<TableRowData[]>(() => {
    const intervalCells = smallScreen ? [intervals[activeIndex]] : intervals;

    return allParts.map(({ name, genericName }) => ({
      name,
      ...Object.fromEntries(
        intervalCells.map(({ id, parts }) => {
          const filtered = parts.filter(
            (part) => part.genericName === genericName
          );

          // Only show if parts exist
          if (!filtered.length) {
            return [id, null];
          }

          // If human resource is operator show contact technician
          if (
            humanResource === 'OPERATOR' &&
            filtered.some((item) =>
              item.actions.some(
                (action) => action.humanResourceType !== 'OPERATOR'
              )
            )
          ) {
            return [id, 'Performed by certified technician'];
          }

          // Filter by human resource if applicable
          if (
            humanResource &&
            humanResource !== 'OPERATOR' &&
            filtered.some((item) =>
              item.actions.some(
                (action) => action.humanResourceType !== humanResource
              )
            )
          ) {
            return [id, null];
          }

          return [
            id,
            <StatusGrid key={id}>
              {filtered.flatMap((item) =>
                item.actions.map((action) => (
                  <ServicePlanStatus
                    key={action.id}
                    action={action}
                    smallScreen={smallScreen}
                  />
                ))
              )}
            </StatusGrid>
          ];
        })
      )
    }));
  }, [allParts, intervals, humanResource, smallScreen, activeIndex]);

  return (
    <Card>
      <CardHeader title={title} />
      <SortableTable
        emptyTextId="service_plan.not_found"
        headerCells={headerCells}
        bodyRows={bodyRows}
      />
    </Card>
  );
};

export default ServicePlanCard;
