import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { User } from 'models';
import * as routes from 'router/Routes';
import { formatDate } from 'utils/date';
import { useDiamUserAdminAccess } from 'hooks/useDiamUserAdminAccess';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import Tooltip from 'components/UI/Tooltip';
import InfoItem from 'components/UI/InfoItem';
import CopyField from 'components/UI/CopyField';
import Tag from 'components/UI/Tag';

import EditUserStatusModal from 'components/users/modals/EditUserStatusModal';
import ChangeUsernameModal from 'components/users/modals/ChangeUsernameModal';
import RequestChangeUsernameModal from 'components/users/modals/RequestChangeUsernameModal';
import RequestUserActivationModal from 'components/users/modals/RequestUserActivationModal';
import RequestUserDeletionModal from 'components/users/modals/RequestUserDeletionModal';
import SendPasswordRecoveryModal from 'components/users/modals/SendPasswordRecoveryModal';
import InvalidateTokensModal from 'components/users/modals/InvalidateTokensModal';

import {
  ContentContainer,
  AvatarBackground,
  NameContainer,
  Title,
  IconText,
  IconContainer,
  DetailGroup,
  EmailContainer,
  ContentColumn
} from './styled';

type Props = {
  user: User;
  fetchData: () => void;
  fetchAuditLogs: () => void;
  hasBounces: boolean;
};

const UserNameCard: FC<Props> = ({
  user,
  fetchData,
  fetchAuditLogs,
  hasBounces
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const diamUserAdminAccess = useDiamUserAdminAccess(user);

  const { id, attributes } = user;
  const { username, firstName, lastName, disabled, lastLogin, customerId } =
    attributes;

  // State
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [changeUsernameModalOpen, setChangeUsernameModalOpen] =
    useState<boolean>(false);
  const [requestActivationModalOpen, setRequestActivationModalOpen] =
    useState<boolean>(false);
  const [requestChangeUsernameModalOpen, setRequestChangeUsernameModalOpen] =
    useState<boolean>(false);
  const [requestDeletionModalOpen, setRequestDeletionModalOpen] =
    useState<boolean>(false);
  const [invalidateTokensModalOpen, setInvalidateTokensModalOpen] =
    useState<boolean>(false);
  const [passwordRecoveryModalOpen, setPasswordRecoveryModalOpen] =
    useState<boolean>(false);

  // Link to customer
  const onClickCustomerAccount = useCallback(() => {
    if (customerId) {
      navigate(routes.customerDetailsLink(customerId));
    }
  }, [navigate, customerId]);

  // Actions
  const onCloseStatusModal = useCallback(() => setStatusModalOpen(false), []);
  const onCloseChangeUsernameModal = useCallback(
    () => setChangeUsernameModalOpen(false),
    []
  );
  const onCloseRequestActivationModal = useCallback(
    () => setRequestActivationModalOpen(false),
    []
  );
  const onCloseRequestChangeUsernameModal = useCallback(
    () => setRequestChangeUsernameModalOpen(false),
    []
  );
  const onCloseRequestDeletionModal = useCallback(
    () => setRequestDeletionModalOpen(false),
    []
  );
  const onCloseInvalidateTokensModal = useCallback(
    () => setInvalidateTokensModalOpen(false),
    []
  );
  const onClosePasswordRecoveryModal = useCallback(
    () => setPasswordRecoveryModalOpen(false),
    []
  );

  // Details
  const details = useMemo(() => {
    // Detail list
    const detailList = [
      {
        id: 1,
        title: 'user_details.user_id',
        value: (
          <CopyField title={intl.formatMessage({ id: 'user_details.user_id' })}>
            {id}
          </CopyField>
        )
      },
      {
        id: 2,
        title: 'user_details.email_address',
        value: (
          <EmailContainer>
            <CopyField
              title={intl.formatMessage({ id: 'user_details.email_address' })}
            >
              {username}
            </CopyField>
            {hasBounces && (
              <Tooltip
                position="bottom"
                paragraph={intl.formatMessage({
                  id: 'user_details.email_bounces_info'
                })}
              >
                <Tag background="error" color="onPrimary">
                  <Icon
                    type={IconType.EmailBounce}
                    themeType="onPrimary"
                    size="small"
                  />
                </Tag>
              </Tooltip>
            )}
          </EmailContainer>
        )
      },
      {
        id: 3,
        title: 'user_details.account_status',
        value: (
          <Tag background={disabled ? 'error' : 'success'} color="onPrimary">
            {intl.formatMessage({
              id: disabled ? 'user_details.inactive' : 'user_details.active'
            })}
          </Tag>
        )
      },
      {
        id: 4,
        title: 'user_details.last_login',
        value: formatDate(lastLogin)
      }
    ];
    return detailList.map(({ id, title, value }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={value}
      />
    ));
  }, [intl, id, username, disabled, lastLogin, hasBounces]);

  // Menu
  const menu = useMemo(
    () => [
      ...(disabled
        ? [
            {
              id: 1,
              text: intl.formatMessage({
                id: 'user_details.btn_request_activation'
              }),
              onClick: () => setRequestActivationModalOpen(true)
            }
          ]
        : []),
      {
        id: 2,
        text: intl.formatMessage({
          id: `modal.btn_${disabled ? 'activate' : 'disable'}`
        }),
        onClick: () => setStatusModalOpen(true)
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'user_details.btn_request_change_username'
        }),
        onClick: () => setRequestChangeUsernameModalOpen(true)
      },
      {
        id: 4,
        text: intl.formatMessage({
          id: 'user_details.btn_change_username'
        }),
        onClick: () => setChangeUsernameModalOpen(true)
      },
      {
        id: 5,
        text: intl.formatMessage({
          id: 'user_details.btn_send_password_recovery'
        }),
        onClick: () => setPasswordRecoveryModalOpen(true)
      },
      {
        id: 6,
        text: intl.formatMessage({
          id: 'user_details.btn_invalidate_tokens'
        }),
        onClick: () => setInvalidateTokensModalOpen(true)
      },
      {
        id: 7,
        text: intl.formatMessage({
          id: 'user_details.btn_request_deletion'
        }),
        onClick: () => setRequestDeletionModalOpen(true)
      }
    ],
    [intl, disabled]
  );

  return (
    <Card>
      <CardHeader intl="users.title" navUrl={routes.USERS}>
        {diamUserAdminAccess && (
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        <ContentColumn>
          <AvatarBackground>
            <Icon type={IconType.Customer} themeType="grey5" />
          </AvatarBackground>
          <NameContainer>
            <Title>{`${firstName} ${lastName}`}</Title>
            <Tooltip
              position="bottom"
              paragraph={intl.formatMessage({
                id: `misc.customer_account_${
                  customerId ? 'active' : 'inactive'
                }`
              })}
            >
              <IconText onClick={onClickCustomerAccount}>
                <Icon
                  type={customerId ? IconType.Active : IconType.Inactive}
                  themeType={customerId ? 'success' : 'error'}
                />
                {intl.formatMessage({
                  id: 'misc.customer_account'
                })}
                <IconContainer>
                  <Icon type={IconType.Info} themeType="grey4" />
                </IconContainer>
              </IconText>
            </Tooltip>
          </NameContainer>
        </ContentColumn>
        <DetailGroup>{details}</DetailGroup>
      </ContentContainer>
      <EditUserStatusModal
        user={user}
        fetchData={fetchData}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onCloseStatusModal}
        open={statusModalOpen}
      />
      <ChangeUsernameModal
        user={user}
        fetchData={fetchData}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onCloseChangeUsernameModal}
        open={changeUsernameModalOpen}
      />
      <RequestChangeUsernameModal
        user={user}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onCloseRequestChangeUsernameModal}
        open={requestChangeUsernameModalOpen}
      />
      <RequestUserActivationModal
        user={user}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onCloseRequestActivationModal}
        open={requestActivationModalOpen}
      />
      <RequestUserDeletionModal
        user={user}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onCloseRequestDeletionModal}
        open={requestDeletionModalOpen}
      />
      <SendPasswordRecoveryModal
        user={user}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onClosePasswordRecoveryModal}
        open={passwordRecoveryModalOpen}
      />
      <InvalidateTokensModal
        user={user}
        onClose={onCloseInvalidateTokensModal}
        open={invalidateTokensModalOpen}
      />
    </Card>
  );
};

export default UserNameCard;
