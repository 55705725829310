import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserRequestState } from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserRequestState = {
  activation: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  changeUsername: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  deletion: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userRequestSlice = createSlice({
  name: 'user-request',
  initialState: defaultState,
  reducers: {
    requestActivateUserInit: (state) => {
      state.activation.isSuccess = false;
      state.activation.isLoading = true;
      state.activation.isError = false;
      state.activation.error = null;
    },
    requestActivateUserSuccess: (state) => {
      state.activation.isSuccess = true;
      state.activation.isLoading = false;
    },
    requestActivateUserFailure: (state, action: PayloadAction<APIError>) => {
      state.activation.isLoading = false;
      state.activation.isError = true;
      state.activation.error = action.payload;
    },
    requestActivateUserReset: (state) => {
      state.activation = defaultState.activation;
    },
    requestChangeUsernameInit: (state) => {
      state.changeUsername.isSuccess = false;
      state.changeUsername.isLoading = true;
      state.changeUsername.isError = false;
      state.changeUsername.error = null;
    },
    requestChangeUsernameSuccess: (state) => {
      state.changeUsername.isSuccess = true;
      state.changeUsername.isLoading = false;
    },
    requestChangeUsernameFailure: (state, action: PayloadAction<APIError>) => {
      state.changeUsername.isLoading = false;
      state.changeUsername.isError = true;
      state.changeUsername.error = action.payload;
    },
    requestChangeUsernameReset: (state) => {
      state.changeUsername = defaultState.changeUsername;
    },
    requestDeleteUserInit: (state) => {
      state.deletion.isSuccess = false;
      state.deletion.isLoading = true;
      state.deletion.isError = false;
      state.deletion.error = null;
    },
    requestDeleteUserSuccess: (state) => {
      state.deletion.isSuccess = true;
      state.deletion.isLoading = false;
    },
    requestDeleteUserFailure: (state, action: PayloadAction<APIError>) => {
      state.deletion.isLoading = false;
      state.deletion.isError = true;
      state.deletion.error = action.payload;
    },
    requestDeleteUserReset: (state) => {
      state.deletion = defaultState.deletion;
    }
  }
});

// Export selectors
export const userRequestActivateSelector = (state: RootState) =>
  state.userRequest.activation;
export const userRequestChangeUsernameSelector = (state: RootState) =>
  state.userRequest.changeUsername;
export const userRequestDeleteSelector = (state: RootState) =>
  state.userRequest.deletion;

// Export actions
export const {
  requestActivateUserInit,
  requestActivateUserSuccess,
  requestActivateUserFailure,
  requestActivateUserReset,
  requestChangeUsernameInit,
  requestChangeUsernameSuccess,
  requestChangeUsernameFailure,
  requestChangeUsernameReset,
  requestDeleteUserInit,
  requestDeleteUserSuccess,
  requestDeleteUserFailure,
  requestDeleteUserReset
} = userRequestSlice.actions;

// Export reducer
export const userRequestReducer = userRequestSlice.reducer;

// Export thunk
export function requestActivateUser(userId: string, username: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestActivateUserInit());
    try {
      await services.requestActivateUser(userId, username);
      dispatch(requestActivateUserSuccess());
    } catch (e: any) {
      dispatch(requestActivateUserFailure(e));
    }
  };
}

export function requestChangeUsername(userId: string, username: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestChangeUsernameInit());
    try {
      await services.requestChangeUsername(userId, username);
      dispatch(requestChangeUsernameSuccess());
    } catch (e: any) {
      dispatch(requestChangeUsernameFailure(e));
    }
  };
}

export function requestDeleteUser(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestDeleteUserInit());
    try {
      await services.requestDeleteUser(userId);
      dispatch(requestDeleteUserSuccess());
    } catch (e: any) {
      dispatch(requestDeleteUserFailure(e));
    }
  };
}
