import styled from 'styled-components';

type ColumnProps = {
  $left?: boolean;
  $bottom?: boolean;
};

type GridProps = {
  $column?: boolean;
};

export const Grid = styled.div<GridProps>`
  display: flex;
  flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};
  grid-gap: 24px;
`;

export const Column = styled.div<ColumnProps>`
  width: ${({ $left }) => ($left ? '60%' : '100%')};
  margin-right: ${({ $left }) => ($left ? '24px' : '0')};
`;
