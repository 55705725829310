import { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { foldableVariants } from 'utils/constants';

type Props = {
  children: ReactNode;
  open: boolean;
};

const FoldableContainer: FC<Props> = ({ children, open }) => {
  return (
    <motion.div
      initial={false}
      animate={open ? 'open' : 'closed'}
      variants={foldableVariants}
    >
      {children}
    </motion.div>
  );
};

export default FoldableContainer;
