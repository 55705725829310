import {
  ServiceContract,
  ServiceContractResponse,
  ContractFilter,
  ContractStatus,
  ContractStatusUpdate,
  ContractExportResponse
} from 'models';
import { createFilterParams, apiFetch } from 'utils/fetch';
import * as config from 'config';

export async function findServiceContractsByFilters(
  filters: ContractFilter[],
  limit: number,
  offset: number
): Promise<ServiceContract[]> {
  let url = createFilterParams(`${config.backendApi}/scm`, filters);
  url += `&limit=${limit}&offset=${offset}`;

  const json = await apiFetch<any>(url);

  return json.data;
}

export async function findServiceContractById(
  id: string
): Promise<ServiceContractResponse> {
  const url = `${config.backendApi}/scm/${id}`;

  const json = await apiFetch<any>(url);

  return json.data;
}

export async function getCSVExport(
  filters: ContractFilter[],
  limit: number,
  offset: number
): Promise<ContractExportResponse> {
  let url = createFilterParams(`${config.backendApi}/scm/csv`, filters);
  url += `&limit=${limit}&offset=${offset}`;

  return apiFetch<any>(url);
}

export async function updateServiceContractStatusById(
  id: string,
  update: ContractStatusUpdate
): Promise<ContractStatus> {
  const url = `${config.backendApi}/scm/${id}`;

  const json = await apiFetch<any>(url, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'servicecontract',
        id,
        attributes: {
          ...update
        }
      }
    })
  });

  return json.data.attributes.status;
}

export async function sendServiceContractEmail(id: string): Promise<void> {
  const url = `${config.backendApi}/scm/send-mail/${id}`;

  await apiFetch(url, {
    method: 'POST'
  });
}

export async function moveServiceContract(
  id: string,
  pnc: string,
  serialNumber: string
): Promise<void> {
  const url = `${config.backendApi}/scm/${id}/move?pnc=${pnc}&serialNumber=${serialNumber}`;

  await apiFetch<any>(url, {
    method: 'PATCH'
  });
}
