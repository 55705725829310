import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ContractState,
  ContractFilter,
  ServiceContractResponse,
  ContractStatus,
  ContractStatusUpdate,
  ServiceContractSuccess,
  ContractExportResponse,
  APIError
} from 'models';
import * as services from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: ContractState = {
  list: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null,
    filters: {
      active: false,
      data: null
    },
    exports: {
      data: null,
      errors: [],
      isLoading: false
    }
  },
  details: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  status: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  sendMail: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  move: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const contractSlice = createSlice({
  name: 'contract',
  initialState: defaultState,
  reducers: {
    getContractList: (state) => {
      state.list.isLoading = true;
      state.list.isError = false;
      state.list.error = null;
    },
    getContractListSuccess: (
      state,
      action: PayloadAction<ServiceContractSuccess>
    ) => {
      state.list.isSuccess = true;
      state.list.isLoading = false;
      state.list.data = action.payload.response;
      state.list.filters.data = action.payload.filters;
    },
    getContractListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.isError = true;
      state.list.error = action.payload;
      state.list.filters.data = null;
    },
    setContractFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    resetExportContracts: (state) => {
      state.list.exports.data = null;
    },
    exportContracts: (state) => {
      state.list.exports.isLoading = true;
      state.list.exports.data = null;
      state.list.exports.errors = [];
    },
    exportContractsSuccess: (
      state,
      action: PayloadAction<ContractExportResponse>
    ) => {
      state.list.exports.isLoading = false;
      state.list.exports.data = action.payload.data;
      state.list.exports.errors = action.payload.errors;
    },
    exportContractsFailure: (state, action: PayloadAction<APIError>) => {
      state.list.exports.data = null;
      state.list.exports.isLoading = false;
      state.list.exports.errors = [action.payload];
    },
    getContract: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getContractSuccess: (
      state,
      action: PayloadAction<ServiceContractResponse>
    ) => {
      state.details.isSuccess = true;
      state.details.isLoading = false;
      state.details.error = null;
      state.details.data = action.payload;
    },
    getContractFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.isError = true;
      state.details.error = action.payload;
    },
    updateContractStatus: (state) => {
      state.status.isSuccess = false;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = null;
    },
    updateContractStatusSuccess: (
      state,
      action: PayloadAction<ContractStatus>
    ) => {
      const { data } = state.details;
      if (data) {
        data.attributes.serviceContract.attributes.status = action.payload;
      }
      state.status.data = action.payload;
      state.status.isSuccess = true;
      state.status.isLoading = false;
    },
    updateContractStatusFailure: (state, action: PayloadAction<APIError>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    sendContractMail: (state) => {
      state.sendMail.isSuccess = false;
      state.sendMail.isLoading = true;
      state.sendMail.isError = false;
      state.sendMail.error = null;
    },
    sendContractMailSuccess: (state) => {
      state.sendMail.isSuccess = true;
      state.sendMail.isLoading = false;
    },
    sendContractMailFailure: (state, action: PayloadAction<APIError>) => {
      state.sendMail.isLoading = false;
      state.sendMail.isError = true;
      state.sendMail.error = action.payload;
    },
    moveContract: (state) => {
      state.move.isSuccess = false;
      state.move.isLoading = true;
      state.move.isError = false;
      state.move.error = null;
    },
    moveContractSuccess: (state) => {
      state.move.isSuccess = true;
      state.move.isLoading = false;
    },
    moveContractFailure: (state, action: PayloadAction<APIError>) => {
      state.move.isLoading = false;
      state.move.isError = true;
      state.move.error = action.payload;
    },
    resetContractDetails: (state) => {
      state.details = defaultState.details;
    },
    resetUpdateContractStatus: (state) => {
      state.status = defaultState.status;
    },
    resetSendContractMail: (state) => {
      state.sendMail = defaultState.sendMail;
    },
    resetMoveContract: (state) => {
      state.move = defaultState.move;
    }
  }
});

// Export selectors
export const contractListSelector = (state: RootState) => state.contract.list;
export const contractDetailsSelector = (state: RootState) =>
  state.contract.details;
export const contractStatusUpdateSelector = (state: RootState) =>
  state.contract.status;
export const contractSendMailSelector = (state: RootState) =>
  state.contract.sendMail;
export const contractMoveSelector = (state: RootState) => state.contract.move;

// Export actions
export const {
  getContractList,
  getContractListSuccess,
  getContractListFailure,
  setContractFilters,
  resetExportContracts,
  exportContracts,
  exportContractsSuccess,
  exportContractsFailure,
  getContract,
  getContractSuccess,
  getContractFailure,
  updateContractStatus,
  updateContractStatusSuccess,
  updateContractStatusFailure,
  sendContractMail,
  sendContractMailSuccess,
  sendContractMailFailure,
  moveContract,
  moveContractSuccess,
  moveContractFailure,
  resetUpdateContractStatus,
  resetContractDetails,
  resetSendContractMail,
  resetMoveContract
} = contractSlice.actions;

// Export reducer
export const contractReducer = contractSlice.reducer;

// Export thunk
export const fetchServiceContracts =
  (filters: ContractFilter[], limit: number, offset: number) =>
  async (dispatch: Dispatch) => {
    dispatch(getContractList());
    try {
      const response = await services.findServiceContractsByFilters(
        filters,
        limit,
        offset
      );
      dispatch(
        getContractListSuccess({
          response,
          filters: response.length ? filters : null
        })
      );
    } catch (e: any) {
      dispatch(getContractListFailure(e));
    }
  };

export const exportServiceContracts =
  (filters: ContractFilter[], limit: number, offset: number) =>
  async (dispatch: Dispatch) => {
    dispatch(exportContracts());
    try {
      const response = await services.getCSVExport(filters, limit, offset);
      dispatch(exportContractsSuccess(response));
    } catch (e: any) {
      dispatch(exportContractsFailure(e));
    }
  };

export const fetchServiceContract =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(getContract());
    try {
      const response = await services.findServiceContractById(id);
      dispatch(getContractSuccess(response));
    } catch (e: any) {
      dispatch(getContractFailure(e));
      showErrorToast(e.detail);
    }
  };

export const updateServiceContractStatus =
  (id: string, update: ContractStatusUpdate) => async (dispatch: Dispatch) => {
    dispatch(updateContractStatus());
    try {
      const result = await services.updateServiceContractStatusById(id, update);
      dispatch(updateContractStatusSuccess(result));
    } catch (e: any) {
      dispatch(updateContractStatusFailure(e));
      showErrorToast(e.detail);
    }
  };

export const sendServiceContractMail =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(sendContractMail());
    try {
      await services.sendServiceContractEmail(id);
      dispatch(sendContractMailSuccess());
    } catch (e: any) {
      dispatch(sendContractMailFailure(e));
      showErrorToast(e.detail);
    }
  };

export const moveServiceContract =
  (id: string, pnc: string, serialNumber: string) =>
  async (dispatch: Dispatch) => {
    dispatch(moveContract());
    try {
      await services.moveServiceContract(id, pnc, serialNumber);
      dispatch(moveContractSuccess());
    } catch (e: any) {
      dispatch(moveContractFailure(e));
      showErrorToast(e.detail);
    }
  };
