import { FC, ReactNode } from 'react';
import { GlobalStyles } from 'styles';
import { ThemeProvider, DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  fontFamily:
    "'Husqvarna Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;",
  colors: {
    primary: '#F35321',
    primaryDark: '#D1471C',
    primaryLight: '#F46639',
    primaryLighter: '#F3532152',
    primaryDisabled: 'rgba(243, 83, 33, 0.32)',
    onPrimary: '#FFFFFF',
    secondary: '#EEEEED',
    onSecondary: '#3D3D3C',
    background: '#EEEEED',
    onBackground: '#3D3D3C',
    surface: '#FFFFFF',
    onSurface: '#3D3D3C',
    grey0: '#FFFFFF',
    grey1: '#F4F4F3',
    grey2: '#EEEEED',
    grey3: '#D2D2D1',
    grey4: '#90908F',
    grey5: '#686867',
    grey6: '#3D3D3C',
    grey7: '#2C2C2B',
    grey8: '#1E1E1D',
    grey9: '#000000',
    info: '#07AFDC',
    danger: '#dc2832',
    success: '#31B900',
    warning: '#FFC800',
    onWarning: '#5C4800',
    error: '#DC2832'
  }
};

type Props = {
  children: ReactNode;
};

const ThemeWrapper: FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
