import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { sortObjectArray, SortOrder } from 'utils/array';
import { TableRowData } from 'models/table';

import Table from 'components/UI/Table';
import TableBody from 'components/UI/Table/TableBody';
import TableDataCell from 'components/UI/Table/TableDataCell';
import TableHeader from 'components/UI/Table/TableHeader';
import TableHeaderCell from 'components/UI/Table/TableHeaderCell';
import TableRow from 'components/UI/Table/TableRow';
import ArrowIcon from 'components/UI/Icon/ArrowIcon';

import { EmptyMessage } from './styled';
import TableBodyCell from '../TableBodyCell';

export type HeaderCell = {
  sortable?: boolean;
  title?: string;
  intl?: string;
};

type Props = {
  headerCells: HeaderCell[];
  bodyRows: TableRowData[];
  emptyTextId?: string;
  sortIndex?: number;
  sortOrder?: SortOrder;
  onClick?: (id: string) => void;
};

const SortableTable: FC<Props> = ({
  headerCells,
  bodyRows,
  emptyTextId,
  sortIndex = 0,
  sortOrder = SortOrder.Asc,
  onClick
}) => {
  // State
  const [activeIndex, setActiveIndex] = useState<number>(sortIndex);
  const [activeSortOrder, setActiveSortOrder] = useState<SortOrder>(sortOrder);

  // On sort
  const onSort = useCallback(
    (active: boolean, i: number) => () => {
      if (active) {
        setActiveSortOrder(activeSortOrder * -1);
      } else {
        setActiveIndex(i);
      }
    },
    [activeSortOrder]
  );

  // Render arrow button
  const renderArrowButton = useCallback(
    (link?: string, disabled?: string | boolean) => {
      if (!onClick || !link) {
        return null;
      }

      return (
        <TableDataCell
          onClick={() => onClick(link)}
          disabled={disabled}
          isPressable
        >
          <ArrowIcon
            size="small"
            direction="right"
            transition={false}
            themeType={disabled ? 'grey4' : 'grey6'}
          />
        </TableDataCell>
      );
    },
    [onClick]
  );

  if (!bodyRows.length && emptyTextId) {
    return (
      <EmptyMessage>
        <FormattedMessage id={emptyTextId} />
      </EmptyMessage>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headerCells.map((cell, index) => {
            const isActive = index === activeIndex;
            return (
              <TableHeaderCell
                key={cell.title ?? cell.intl}
                sortable={cell.sortable}
                active={isActive}
                onClick={onSort(isActive, index)}
                sortOrder={activeSortOrder}
              >
                {cell.intl && <FormattedMessage id={cell.intl} />}
                {cell.title}
              </TableHeaderCell>
            );
          })}
          {onClick && <TableHeaderCell />}
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortObjectArray(
          bodyRows,
          Object.keys(bodyRows[0])[activeIndex],
          activeSortOrder
        ).map((item, rowIndex) => {
          return (
            <TableRow key={`row-${rowIndex + 1}`}>
              {Object.entries(item).map(([name, value], colIndex) => (
                <TableBodyCell
                  key={`cell-${colIndex + 1}`}
                  name={name}
                  value={value}
                />
              ))}
              {renderArrowButton(item.link, item.disabled)}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SortableTable;
