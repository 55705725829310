import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DetailGroup = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;
