import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProductFilterObject } from 'models';
import Button from 'components/UI/Button';

import { Form, Row, Input, InputLarge, ButtonGrid, Divider } from './styled';

type Props = {
  onSearch: (values: ProductFilterObject) => void;
  onReset: () => void;
};

const SearchProductsField: FC<Props> = ({ onSearch, onReset }) => {
  const intl = useIntl();
  const { register, handleSubmit, watch, reset } = useForm();

  // Watch fields
  const [pnc, serialNumber, husqvarnaId] = watch([
    'pnc',
    'serialNumber',
    'husqvarnaId'
  ]);

  // Check field validity
  const hasHusqvarnaId = Boolean(husqvarnaId);
  const hasPncOrSerial = Boolean(pnc || serialNumber);
  const hasPncAndSerial = Boolean(pnc && serialNumber);
  const canSubmit = Boolean(hasPncAndSerial || hasHusqvarnaId);

  // Submit
  const onSubmit: SubmitHandler<any> = useCallback(onSearch, [onSearch]);

  // Clear
  const onClear = useCallback(() => {
    onReset();
    reset();
  }, [onReset, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Input
          {...register('pnc', {
            required: hasPncOrSerial
          })}
          className="input small"
          placeholder={`${intl.formatMessage({ id: 'product_details.pnc' })}`}
          disabled={hasHusqvarnaId}
        />
        <Divider>+</Divider>
        <Input
          {...register('serialNumber', {
            required: hasPncOrSerial
          })}
          className="input small"
          placeholder={`${intl.formatMessage({
            id: 'product_details.serial'
          })}`}
          disabled={hasHusqvarnaId}
        />
        <Divider>or</Divider>
        <InputLarge
          {...register('husqvarnaId', {
            required: hasHusqvarnaId
          })}
          className="input large"
          placeholder={`${intl.formatMessage({ id: 'product_details.hid' })}`}
          disabled={Boolean(pnc || serialNumber)}
        />
      </Row>
      <ButtonGrid>
        <Button
          onClick={onClear}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button submit disabled={!canSubmit}>
          <FormattedMessage id="search.btn" />
        </Button>
      </ButtonGrid>
    </Form>
  );
};

export default SearchProductsField;
