import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserRole, UserRoleState } from 'models';
import * as services from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: UserRoleState = {
  roles: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  addRole: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  removeRole: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userRoleSlice = createSlice({
  name: 'user-role',
  initialState: defaultState,
  reducers: {
    getUserRolesInit: (state) => {
      state.roles.isSuccess = false;
      state.roles.isLoading = true;
      state.roles.isError = false;
      state.roles.error = null;
    },
    getUserRolesSuccess: (state, action: PayloadAction<UserRole[]>) => {
      state.roles.data = action.payload;
      state.roles.isSuccess = true;
      state.roles.isLoading = false;
    },
    getUserRolesFailure: (state, action: PayloadAction<APIError>) => {
      state.roles.isLoading = false;
      state.roles.isError = true;
      state.roles.error = action.payload;
    },
    getUserRolesReset: (state) => {
      state.roles = defaultState.roles;
    },
    addUserRoleInit: (state) => {
      state.addRole.data = null;
      state.addRole.isSuccess = false;
      state.addRole.isLoading = true;
      state.addRole.isError = false;
      state.addRole.error = null;
    },
    addUserRoleSuccess: (state, action: PayloadAction<string>) => {
      state.addRole.data = action.payload;
      state.addRole.isSuccess = true;
      state.addRole.isLoading = false;
    },
    addUserRoleFailure: (state, action: PayloadAction<APIError>) => {
      state.addRole.isLoading = false;
      state.addRole.isError = true;
      state.addRole.error = action.payload;
    },
    addUserRoleReset: (state) => {
      state.addRole = defaultState.addRole;
    },
    removeUserRoleInit: (state) => {
      state.removeRole.isSuccess = false;
      state.removeRole.isLoading = true;
      state.removeRole.isError = false;
      state.removeRole.error = null;
    },
    removeUserRoleSuccess: (state, action: PayloadAction<string>) => {
      state.removeRole.data = action.payload;
      state.removeRole.isSuccess = true;
      state.removeRole.isLoading = false;
    },
    removeUserRoleFailure: (state, action: PayloadAction<APIError>) => {
      state.removeRole.isLoading = false;
      state.removeRole.isError = true;
      state.removeRole.error = action.payload;
    },
    removeUserRoleReset: (state) => {
      state.removeRole = defaultState.removeRole;
    }
  }
});

// Export selectors
export const userRoleSelector = (state: RootState) => state.userRole;

// Export actions
export const {
  getUserRolesInit,
  getUserRolesSuccess,
  getUserRolesFailure,
  getUserRolesReset,
  addUserRoleInit,
  addUserRoleSuccess,
  addUserRoleFailure,
  addUserRoleReset,
  removeUserRoleInit,
  removeUserRoleSuccess,
  removeUserRoleFailure,
  removeUserRoleReset
} = userRoleSlice.actions;

// Export reducer
export const userRoleReducer = userRoleSlice.reducer;

// Export thunk
export function fetchUserRoles() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { isSuccess, isLoading } = getState().userRole.roles;

    // Fetch user roles only once per session, and prevent multiple concurrent requests
    if (isSuccess || isLoading) {
      return;
    }

    dispatch(getUserRolesInit());
    try {
      const result = await services.getUserRoles();
      dispatch(getUserRolesSuccess(result));
    } catch (e: any) {
      dispatch(getUserRolesFailure(e));
    }
  };
}

export function addUserRole(userId: string, role: string) {
  return async (dispatch: Dispatch) => {
    dispatch(addUserRoleInit());
    try {
      await services.addUserRole(userId, role);
      dispatch(addUserRoleSuccess(role));
    } catch (e: any) {
      dispatch(addUserRoleFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function removeUserRole(userId: string, role: string) {
  return async (dispatch: Dispatch) => {
    dispatch(removeUserRoleInit());
    try {
      await services.removeUserRole(userId, role);
      dispatch(removeUserRoleSuccess(role));
    } catch (e: any) {
      dispatch(removeUserRoleFailure(e));
      showErrorToast(e.title);
    }
  };
}
