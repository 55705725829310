import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

import { AuthState, APIError, SCPUser } from 'models';
import * as authService from 'services/authService/authService';
import type { RootState } from 'store/store';

export const defaultState: AuthState = {
  session: {
    isAuthenticated: false,
    scpUser: null
  },
  isLoading: false,
  error: null,
  authCheck: false,
  authExpired: false,
  authRateLimited: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    authStart: (state) => {
      state.isLoading = true;
    },
    authCheck: (state) => {
      state.authCheck = true;
    },
    authExpired: (state) => {
      state.authExpired = true;
    },
    authRateLimited: (state) => {
      state.authRateLimited = true;
    },
    logoutStart: (state) => {
      state.isLoading = true;
    },
    logoutSuccess: () => {
      return defaultState;
    },
    logoutFail: (state, action: PayloadAction<APIError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    validateTokenStart: (state) => {
      state.isLoading = true;
    },
    validateTokenSuccess: (state, action: PayloadAction<SCPUser>) => {
      const user = action.payload;

      state.session.scpUser = user;
      state.session.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
    },
    validateTokenFail: (state, action: PayloadAction<APIError>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// Export selector
export const authSelector = (state: RootState) => state.auth;

// Export actions
export const {
  authStart,
  authCheck,
  authExpired,
  authRateLimited,
  logoutStart,
  logoutSuccess,
  logoutFail,
  validateTokenStart,
  validateTokenSuccess,
  validateTokenFail
} = authSlice.actions;

// Export reducer
export const authReducer = authSlice.reducer;

// Thunks
export function checkSession() {
  return async (dispatch: Dispatch) => {
    dispatch(validateTokenStart());
    try {
      const { attributes } = await authService.getUserInfo();
      dispatch(validateTokenSuccess(attributes));
    } catch (e: any) {
      dispatch(validateTokenFail(e));
    }
    dispatch(authCheck());
  };
}

export function logout(diamLogout: boolean = true) {
  return async (dispatch: Dispatch) => {
    dispatch(logoutStart());

    try {
      if (diamLogout) {
        await authService.logout();
      }
    } catch (e: any) {
      dispatch(logoutFail(e));
    } finally {
      dispatch(logoutSuccess());
      dispatch(authCheck());
    }
  };
}
