import styled from 'styled-components';

type TypeProps = {
  $add: boolean;
  $remove: boolean;
};

const contentProps = ({ $add, $remove }: TypeProps) => {
  if ($add) {
    return "'+'";
  }
  if ($remove) {
    return "'-'";
  }
  return 'none';
};

export const TextType = styled.div<TypeProps>`
  position: relative;
  &::before {
    position: absolute;
    content: ${contentProps};
  }
`;

type TextProps = {
  $indent: number;
};

export const Text = styled.div<TextProps>`
  padding-left: ${({ $indent }) => 16 + $indent}px;
`;

export const Container = styled.div`
  background: #eeeeed;
  border-radius: 8px;
  overflow: hidden;
`;
export const Normal = styled.div`
  padding: 8px;
`;

export const Removed = styled(Normal)`
  background: rgba(220, 40, 50, 0.3);
`;

export const Added = styled(Normal)`
  background: rgba(49, 185, 0, 0.3);
`;
