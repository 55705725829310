import styled from 'styled-components';

type RootProps = {
  $active: boolean;
};
export const Root = styled.div<RootProps>`
  display: flex;
  visibility: ${({ $active }) => (!$active ? 'hidden' : 'visible')};
  flex-direction: column;
  height: 16px;
  width: 16px;

  path {
    fill: ${({ theme, $active }) => ($active ? theme.colors.primary : null)};
  }
`;
