import styled from 'styled-components';

export const DealerSelectorContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button {
    max-width: 200px;
  }
`;
