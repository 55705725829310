import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface};
  width: 100%;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 24px;

  h3 {
    color: ${({ theme }) => theme.colors.grey5};
    font-size: 18px;
    margin-top: 0;
  }

  h4 {
    margin-top: 0;
  }
`;
