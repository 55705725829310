import { FC } from 'react';
import { useIntl } from 'react-intl';

import { ServiceContract } from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';

import { ContentContainer, NameContainer, DetailGroup } from './styled';

type Props = {
  contract: ServiceContract;
};

const ServiceContractPaymentCard: FC<Props> = ({ contract }) => {
  const intl = useIntl();
  const {
    attributes: {
      salesOrder: {
        attributes: {
          totalPriceInclVAT,
          monthlyPriceInclVAT,
          currency,
          paymentMethod,
          paymentProvider,
          paymentReference,
          sellerId
        }
      }
    }
  } = contract;

  // Detail list
  const detailList = [
    {
      id: 1,
      title: 'contract_details.payment_seller_id',
      text: sellerId
    },
    {
      id: 2,
      title: 'contract_details.payment_payment_provider',
      text: paymentProvider
    },
    {
      id: 3,
      title: 'contract_details.payment_payment_reference',
      text: paymentReference
    },
    {
      id: 4,
      title: 'contract_details.payment_payment_method',
      text: paymentMethod
    },
    {
      id: 5,
      title: 'contract_details.payment_price_monthly',
      text: `${monthlyPriceInclVAT || '–'} ${currency}`
    },
    {
      id: 6,
      title: 'contract_details.payment_price_total',
      text: `${totalPriceInclVAT || '–'} ${currency}`
    }
  ];

  return (
    <Card>
      <CardHeader intl="contract_details.payment_title" />
      <ContentContainer>
        <NameContainer>
          <DetailGroup>
            {detailList.map(({ id, title, text }) => (
              <InfoItem
                key={id}
                title={intl.formatMessage({ id: title })}
                value={text}
              />
            ))}
          </DetailGroup>
        </NameContainer>
      </ContentContainer>
    </Card>
  );
};

export default ServiceContractPaymentCard;
