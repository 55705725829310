import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as routes from 'router/Routes';
import { ServiceContract } from 'models';
import { formatDate } from 'utils/date';
import { getContractStatusColors } from 'utils/serviceContracts';

import Tag from 'components/UI/Tag';
import Card from 'components/UI/Card';
import CopyField from 'components/UI/CopyField';
import CardHeader from 'components/UI/Card/CardHeader';
import Icon, { IconType } from 'components/UI/Icon';
import InfoItem from 'components/UI/InfoItem';
import IconButton from 'components/UI/IconButton';
import DropdownMenu from 'components/UI/DropdownMenu';

import {
  ContentContainer,
  Title,
  NameContainer,
  DetailGroup,
  AvatarBackground
} from './styled';

type Props = {
  contract: ServiceContract;
  marketName: string;
  openStatusModal: () => void;
  openSendMailModal: () => void;
  openMoveModal: () => void;
  canEdit: boolean;
  isGlobalAdmin: boolean;
};

const ServiceContractNameCard: FC<Props> = ({
  contract,
  marketName,
  openStatusModal,
  openSendMailModal,
  openMoveModal,
  canEdit,
  isGlobalAdmin
}) => {
  const intl = useIntl();

  // Detail list
  const detailList = useMemo(() => {
    const {
      id,
      attributes: { status, startDate, endDate, serviceContractProduct, type }
    } = contract;

    // Contract status colors
    const { background, color } = getContractStatusColors(status);

    return [
      {
        id: 1,
        title: 'contract_details.id',
        text: (
          <CopyField title={intl.formatMessage({ id: 'contract_details.id' })}>
            {id}
          </CopyField>
        )
      },
      {
        id: 2,
        title: 'contract_details.pnc',
        text: serviceContractProduct
      },
      {
        id: 3,
        title: 'contract_details.type',
        text: type
      },
      {
        id: 4,
        title: 'contract_details.start_date',
        text: startDate && formatDate(startDate, 'yyyy-MM-dd')
      },
      {
        id: 5,
        title: 'contract_details.end_date',
        text: endDate && formatDate(endDate, 'yyyy-MM-dd')
      },
      {
        id: 6,
        title: 'contract_details.status',
        text: (
          <Tag background={background} color={color}>
            {status}
          </Tag>
        )
      }
    ];
  }, [intl, contract]);

  // Context menu
  const contextMenu = useMemo(
    () => [
      {
        id: 1,
        text: intl.formatMessage({ id: 'contract_details.btn_change_status' }),
        onClick: openStatusModal
      },
      {
        id: 2,
        text: intl.formatMessage({ id: 'contract_details.btn_send_email' }),
        onClick: openSendMailModal
      },
      ...(isGlobalAdmin
        ? [
            {
              id: 3,
              text: intl.formatMessage({ id: 'contract_details.btn_move' }),
              onClick: openMoveModal
            }
          ]
        : [])
    ],
    [intl, openStatusModal, openSendMailModal, isGlobalAdmin, openMoveModal]
  );

  return (
    <Card>
      <CardHeader
        navUrl={routes.SERVICE_CONTRACTS}
        intl="sidebar.service_contracts"
      >
        {canEdit && (
          <DropdownMenu menu={contextMenu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        <NameContainer>
          <AvatarBackground>
            <Icon type={IconType.ServiceContract} themeType="grey5" />
          </AvatarBackground>
          <Title>
            {!marketName
              ? intl.formatMessage({
                  id: 'contract_details.contract_fallback_title'
                })
              : marketName}
          </Title>
        </NameContainer>
        <DetailGroup>
          {detailList.map(({ id, title, text }) => (
            <InfoItem
              key={id}
              title={intl.formatMessage({ id: title })}
              value={text ?? '–'}
            />
          ))}
        </DetailGroup>
      </ContentContainer>
    </Card>
  );
};

export default ServiceContractNameCard;
