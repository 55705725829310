import { FC } from 'react';
import { useParams } from 'react-router-dom';

import Icon, { IconType } from '../Icon';

import { Container, List, ListItem, Params, Text } from './styled';

type Props = {
  children: any;
  icon: IconType;
  padding?: boolean;
  includeParams?: boolean;
};

const EmptyState: FC<Props> = ({
  children,
  icon,
  padding,
  includeParams = false
}) => {
  const params = useParams();

  return (
    <Container $padding={padding}>
      <Icon type={icon} size="extraLarge" themeType="grey4" />
      <Text>{children}</Text>
      {includeParams && (
        <Params>
          <List>
            {Object.entries(params).map(([key, value]) => (
              <ListItem key={key}>{`${key}: "${value}"`}</ListItem>
            ))}
          </List>
        </Params>
      )}
    </Container>
  );
};

export default EmptyState;
