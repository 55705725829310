import styled from 'styled-components';

export type StepProps = {
  $isActive: boolean;
};

export const Steps = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const StepItem = styled.div`
  flex: 1;

  h4 {
    font-size: 14px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 0 0 4px;
  }
`;

export const Indicator = styled.div<StepProps>`
  height: 4px;
  background: ${({ theme, $isActive }) =>
    theme.colors[$isActive ? 'primary' : 'grey3']};
`;
