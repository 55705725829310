import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  $color: ThemeColor;
  $background: ThemeColor;
};

export const StyledTag = styled.div<Props>`
  display: flex;
  gap: 4px;
  color: ${({ theme, $color }) => theme.colors[$color]};
  background-color: ${({ theme, $background }) => theme.colors[$background]};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 8px;
  padding: 4px 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
