import { FC, ReactNode, useCallback, useRef } from 'react';
import { Variants } from 'framer-motion';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import {
  Container,
  InnerContainer,
  Popup,
  Text,
  TooltipPosition
} from './styled';

const variants: Variants = {
  visible: {
    display: 'flex',
    opacity: 1,
    visibility: 'visible',
    transition: {
      display: {
        duration: 0.05
      },
      opacity: {
        duration: 0.05
      }
    }
  },
  hidden: {
    display: 'none',
    opacity: 0,
    visibility: 'hidden',
    transition: {
      display: {
        delay: 0.5,
        duration: 0.05
      },
      opacity: {
        duration: 0.05
      }
    }
  }
};

type Props = {
  children: ReactNode;
  paragraph: string;
  position?: TooltipPosition;
};

const Tooltip: FC<Props> = ({ children, paragraph, position = 'bottom' }) => {
  // Refs
  const containerRef = useRef(null);
  const hoverRef = useRef(false);

  // Hooks
  const [isVisible, setIsVisible] = useDetectOutsideClick(
    [containerRef],
    false
  );

  // Click
  const onClick = useCallback(() => {
    if (!hoverRef.current) {
      setIsVisible(!isVisible);
    }
  }, [isVisible, setIsVisible]);

  // Mouse effects
  const onMouseEnter = useCallback(() => {
    hoverRef.current = true;
    setIsVisible(true);
  }, [setIsVisible]);

  const onMouseLeave = useCallback(() => {
    hoverRef.current = false;
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <Container>
      <InnerContainer
        ref={containerRef}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid="tooltip-container"
      >
        {children}
      </InnerContainer>
      <Popup
        initial={false}
        variants={variants}
        animate={isVisible ? 'visible' : 'hidden'}
        transition={{ duration: 0.1, type: 'tween' }}
        $position={position}
      >
        <Text>{paragraph}</Text>
      </Popup>
    </Container>
  );
};

export default Tooltip;
