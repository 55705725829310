import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError } from 'models';
import {
  ConnectivityState,
  DeviceFilter,
  DevicesSuccess,
  SoftwareUpdatesSuccess,
  Device
} from 'models/connectivity';
import {
  findDevices,
  getDeviceByIprId,
  getSoftwareUpdateHistory
} from 'services/connectivityService/connectivityService';
import type { RootState } from 'store/store';

export const defaultState: ConnectivityState = {
  deviceList: {
    data: null,
    totalCount: 0,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null,
    filter: {
      active: false,
      data: null
    }
  },
  deviceDetails: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  softwareUpdates: {
    data: null,
    totalCount: 0,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const devicesSlice = createSlice({
  name: 'connectivity',
  initialState: defaultState,
  reducers: {
    getDevices: (state) => {
      state.deviceList.isSuccess = false;
      state.deviceList.isLoading = true;
      state.deviceList.isError = false;
      state.deviceList.error = null;
    },
    getDevicesSuccess: (state, action: PayloadAction<DevicesSuccess>) => {
      state.deviceList.data = action.payload.data;
      state.deviceList.isLoading = false;
      state.deviceList.totalCount = action.payload.meta.totalCount;
      state.deviceList.filter.data = action.payload.filter;
    },
    getDevicesFailure: (state, action: PayloadAction<APIError>) => {
      state.deviceList.data = [];
      state.deviceList.totalCount = 0;
      state.deviceList.isLoading = false;
      state.deviceList.isError = true;
      state.deviceList.error = action.payload;
    },
    setDeviceFilters: (state, action: PayloadAction<boolean>) => {
      state.deviceList.filter.active = action.payload;
    },
    getDeviceDetails: (state) => {
      state.deviceDetails.isSuccess = false;
      state.deviceDetails.isLoading = true;
      state.deviceDetails.error = null;
    },
    getDeviceDetailsSuccess: (state, action: PayloadAction<Device>) => {
      state.deviceDetails.data = action.payload;
      state.deviceDetails.isSuccess = true;
      state.deviceDetails.isLoading = false;
    },
    getDeviceDetailsFailure: (state, action: PayloadAction<APIError>) => {
      state.deviceDetails.data = null;
      state.deviceDetails.isLoading = false;
      state.deviceDetails.isError = true;
      state.deviceDetails.error = action.payload;
    },
    getSoftwareUpdates: (state) => {
      state.softwareUpdates.isSuccess = false;
      state.softwareUpdates.isLoading = true;
      state.softwareUpdates.isError = false;
      state.softwareUpdates.error = null;
    },
    getSoftwareUpdatesSuccess: (
      state,
      action: PayloadAction<SoftwareUpdatesSuccess>
    ) => {
      state.softwareUpdates.data = action.payload.data;
      state.softwareUpdates.totalCount = action.payload.meta.totalCount;
      state.softwareUpdates.isLoading = false;
    },
    getSoftwareUpdatesFailure: (state, action: PayloadAction<APIError>) => {
      state.softwareUpdates.data = null;
      state.softwareUpdates.isLoading = false;
      state.softwareUpdates.isError = true;
      state.softwareUpdates.error = action.payload;
    }
  }
});

// Export actions
export const {
  getDevices,
  getDevicesSuccess,
  getDevicesFailure,
  setDeviceFilters,
  getDeviceDetails,
  getDeviceDetailsSuccess,
  getDeviceDetailsFailure,
  getSoftwareUpdates,
  getSoftwareUpdatesSuccess,
  getSoftwareUpdatesFailure
} = devicesSlice.actions;

// Export reducer
export const connectivityReducer = devicesSlice.reducer;

// Export selectors
export const connectivityDeviceListSelector = (state: RootState) =>
  state.connectivity.deviceList;
export const connectivityDeviceSelector = (state: RootState) =>
  state.connectivity.deviceDetails;
export const connectivitySoftwareUpdatesSelector = (state: RootState) =>
  state.connectivity.softwareUpdates;

// Export thunks
export function fetchDeviceList(
  filter: DeviceFilter,
  offset: number,
  limit = 10
) {
  return async (dispatch: Dispatch) => {
    dispatch(getDevices());
    try {
      const { data, meta } = await findDevices(offset, limit, filter);
      dispatch(getDevicesSuccess({ data, meta, filter }));
    } catch (e: any) {
      dispatch(getDevicesFailure(e));
    }
  };
}

export function fetchDevice(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getDeviceDetails());
    try {
      const { data } = await getDeviceByIprId(iprId);
      dispatch(getDeviceDetailsSuccess(data));
    } catch (e: any) {
      dispatch(getDeviceDetailsFailure(e));
    }
  };
}

export function fetchSofwareUpdates(iprId: string, offset = 0, limit = 5) {
  return async (dispatch: Dispatch) => {
    dispatch(getSoftwareUpdates());
    try {
      const { data, meta } = await getSoftwareUpdateHistory(
        iprId,
        offset,
        limit
      );
      dispatch(getSoftwareUpdatesSuccess({ data, meta }));
    } catch (e: any) {
      dispatch(getSoftwareUpdatesFailure(e));
    }
  };
}
