import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserAuthState } from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserAuthState = {
  passwordRecovery: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  invalidateTokens: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userAuthSlice = createSlice({
  name: 'user-auth',
  initialState: defaultState,
  reducers: {
    userPasswordRecoveryInit: (state) => {
      state.passwordRecovery.isSuccess = false;
      state.passwordRecovery.isLoading = true;
      state.passwordRecovery.isError = false;
      state.passwordRecovery.error = null;
    },
    userPasswordRecoverySuccess: (state) => {
      state.passwordRecovery.isSuccess = true;
      state.passwordRecovery.isLoading = false;
    },
    userPasswordRecoveryFailure: (state, action: PayloadAction<APIError>) => {
      state.passwordRecovery.isLoading = false;
      state.passwordRecovery.isError = true;
      state.passwordRecovery.error = action.payload;
    },
    userPasswordRecoveryReset: (state) => {
      state.passwordRecovery = defaultState.passwordRecovery;
    },
    userInvalidateTokensInit: (state) => {
      state.invalidateTokens.isSuccess = false;
      state.invalidateTokens.isLoading = true;
      state.invalidateTokens.isError = false;
      state.invalidateTokens.error = null;
    },
    userInvalidateTokensSuccess: (state) => {
      state.invalidateTokens.isSuccess = true;
      state.invalidateTokens.isLoading = false;
    },
    userInvalidateTokensFailure: (state, action: PayloadAction<APIError>) => {
      state.invalidateTokens.isLoading = false;
      state.invalidateTokens.isError = true;
      state.invalidateTokens.error = action.payload;
    },
    userInvalidateTokensReset: (state) => {
      state.invalidateTokens = defaultState.invalidateTokens;
    }
  }
});

// Export selectors
export const userPasswordRecoverySelector = (state: RootState) =>
  state.userAuth.passwordRecovery;
export const userInvalidateTokensSelector = (state: RootState) =>
  state.userAuth.invalidateTokens;

// Export actions
export const {
  userPasswordRecoveryInit,
  userPasswordRecoverySuccess,
  userPasswordRecoveryFailure,
  userPasswordRecoveryReset,
  userInvalidateTokensInit,
  userInvalidateTokensSuccess,
  userInvalidateTokensFailure,
  userInvalidateTokensReset
} = userAuthSlice.actions;

// Export reducer
export const userAuthReducer = userAuthSlice.reducer;

// Export thunk
export function sendUserPasswordRecovery(username: string) {
  return async (dispatch: Dispatch) => {
    dispatch(userPasswordRecoveryInit());
    try {
      await services.sendPasswordRecovery(username);
      dispatch(userPasswordRecoverySuccess());
    } catch (e: any) {
      dispatch(userPasswordRecoveryFailure(e));
    }
  };
}

export function invalidateUserTokens(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(userInvalidateTokensInit());
    try {
      await services.invalidateUserTokens(userId);
      dispatch(userInvalidateTokensSuccess());
    } catch (e: any) {
      dispatch(userInvalidateTokensFailure(e));
    }
  };
}
