import { Variants } from 'framer-motion';
import * as routes from 'router/Routes';

export function getShieldVariants(index: number): Variants {
  return {
    open: {
      height: 22,
      transition: {
        height: {
          delay: 0.45 + 0.05 * index,
          duration: 0.15
        }
      }
    },
    closed: {
      height: 0,
      transition: {
        height: {
          delay: 0.3,
          duration: 0.15
        }
      }
    }
  };
}

export function getLinkVariants(index: number): Variants {
  return {
    open: {
      opacity: 1,
      translateX: 0,
      transition: {
        translateX: {
          delay: 0.25 + 0.05 * index
        },
        opacity: {
          delay: 0.25 + 0.05 * index
        }
      }
    },
    closed: {
      opacity: 0,
      translateX: 10,
      transition: {
        translateX: {
          delay: 0.25
        },
        opacity: {
          delay: 0.25,
          duration: 0.1
        }
      }
    }
  };
}

export function getBaseRoute(path: string): string {
  if (path.startsWith(routes.PRODUCTS)) {
    return routes.PRODUCTS;
  }
  if (path.startsWith(routes.SERVICE_CONTRACTS)) {
    return routes.SERVICE_CONTRACTS;
  }
  if (path.startsWith(routes.USERS)) {
    return routes.USERS;
  }
  return routes.CUSTOMERS;
}
