import styled from 'styled-components';
import { Link } from 'react-router-dom';

type MenuProps = {
  $isActive: boolean;
};

export const MenuContainer = styled.div`
  display: flex;
  position: relative;
`;

export const Menu = styled.nav<MenuProps>`
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 48px;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 200px;
  text-align: left;
  z-index: 99;
  overflow: hidden;
  box-shadow:
    0px 12px 24px rgba(0, 0, 0, 0.05),
    0px 2px 16px rgba(0, 0, 0, 0.12);
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  transform: ${({ $isActive }) =>
    $isActive ? 'translateY(0)' : 'translateY(-20px)'};
  transition:
    opacity 0.4s ease,
    transform 0.4s ease,
    visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

type MenuItemProps = {
  $isLast: boolean;
};

export const MenuListItem = styled.li<MenuItemProps>`
  border-bottom: ${({ $isLast }) => ($isLast ? 'none' : '1px solid #dddddd')};
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 16px 0;

  &:hover {
    color: ${({ theme }) => theme.colors.grey9};
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const LinkIcon = styled.div`
  width: 4px;
  display: flex;
  align-items: center;
`;
