import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const Container = styled(motion.div)`
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
  z-index: 99;

  @media (min-width: ${breakpoints.m + 1}px) {
    display: none;
  }
`;

export const MenuCard = styled.div`
  margin: 16px;
  padding: 22px 0;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const AnimatedMenuLinkContainer = styled(motion.div)``;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 0;
`;

export const LinkIcon = styled.div`
  width: 4px;
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

export const LinkText = styled.div`
  display: flex;
  align-items: center;
`;
