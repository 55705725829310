import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 14px;
  }
`;

export const Column = styled.div`
  display: flex;
`;
