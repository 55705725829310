import { FC, useCallback, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Stepper from 'components/UI/Stepper';
import EditModal from 'components/UI/EditModal';

import SearchProCustomer from './SearchProCustomer';
import ConfirmProCustomer from './ConfirmProCustomer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateProCustomerModal: FC<Props> = ({ isOpen, onClose }) => {
  // State
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  // Update index
  const onForward = useCallback(
    async () => setActiveStepIndex(activeStepIndex + 1),
    [activeStepIndex]
  );
  const onBack = useCallback(
    () => setActiveStepIndex(activeStepIndex - 1),
    [activeStepIndex]
  );

  // Steps
  const steps = useMemo(
    () => [
      {
        id: 1,
        intl: 'modal.create_customer_step1'
      },
      {
        id: 2,
        intl: 'modal.create_customer_step2'
      }
    ],
    []
  );

  return (
    <EditModal
      open={isOpen}
      close={onClose}
      title="modal.create_customer_title"
    >
      <Stepper activeIndex={activeStepIndex} steps={steps} />
      <AnimatePresence mode="wait">
        <motion.div
          key={`step-${activeStepIndex}`}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {activeStepIndex === 1 ? (
            <ConfirmProCustomer onBack={onBack} />
          ) : (
            <SearchProCustomer
              isOpen={isOpen}
              onForward={onForward}
              onClose={onClose}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </EditModal>
  );
};

export default CreateProCustomerModal;
