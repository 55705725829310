import { FC, RefObject, useCallback } from 'react';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';
import { localizationSelector } from 'store';
import 'react-calendar/dist/Calendar.css';

import { DatePickerRoot } from './styled';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

type Props = {
  isOpen: boolean;
  maxDate?: Date;
  defaultValue?: Date;
  refValue: RefObject<HTMLDivElement>;
  onChange?: (date: Date) => void;
  onClose: () => void;
};

const DatePicker: FC<Props> = ({
  isOpen,
  maxDate,
  defaultValue,
  refValue,
  onChange,
  onClose
}) => {
  const { locale } = useSelector(localizationSelector);
  // On change
  const onCalendarChange = useCallback(
    (value: Value) => {
      if (onChange) {
        onChange(value as Date);
      }
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <DatePickerRoot ref={refValue} $isOpen={isOpen}>
      <Calendar
        maxDate={maxDate}
        defaultValue={defaultValue ?? maxDate}
        onChange={onCalendarChange}
        locale={locale}
      />
    </DatePickerRoot>
  );
};

export default DatePicker;
