import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';
import SelectField, { OptionType } from 'components/UI/SelectField';
import { useAppSelector } from 'hooks/redux';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { useParseOptionLabel } from 'hooks/useParseOptionLabel';
import { CustomerFilter, CustomerFilterAttribute } from 'models';
import { customerListSelector, metaSelector } from 'store';
import { ButtonGrid, Grid, ThreeColumnGrid } from 'styles';
import { defaultOnboardingSource } from 'utils/constants';
import { createFilters, validateField } from 'utils/filtering';
import { getSalesCompanyCodeOptions } from 'utils/meta';

import { Form } from './styled';

type DisabledFilterState = {
  emailAndCustomerType: boolean;
  customerId: boolean;
  dunsNumber: boolean;
  companyName: boolean;
  customerNumberAndSalesCompanyCode: boolean;
  primaryContactEmail: boolean;
};

const defaultDisabledFilterState = {
  emailAndCustomerType: false,
  customerId: false,
  dunsNumber: false,
  companyName: false,
  customerNumberAndSalesCompanyCode: false,
  primaryContactEmail: false
};

const allDisabledFilterState = {
  emailAndCustomerType: true,
  customerId: true,
  dunsNumber: true,
  companyName: true,
  customerNumberAndSalesCompanyCode: true,
  primaryContactEmail: true
};

type Props = {
  onFilter: (value: CustomerFilter[]) => void;
};

const CustomerFilters: FC<Props> = ({ onFilter }) => {
  const intl = useIntl();
  const meta = useAppSelector(metaSelector);
  const { filters } = useAppSelector(customerListSelector);
  const countryDisplayNames = useDisplayNames('region');
  const parseOptionLabel = useParseOptionLabel();
  const [disabledFilters, setDisabledFilters] = useState<DisabledFilterState>(
    defaultDisabledFilterState
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  // Watch fields
  const [
    email,
    customerType,
    customerId,
    dunsNumber,
    companyName,
    customerNumber,
    salesCompanyCode,
    primaryContactEmail
  ] = watch([
    CustomerFilterAttribute.Email,
    CustomerFilterAttribute.CustomerType,
    CustomerFilterAttribute.CustomerId,
    CustomerFilterAttribute.DunsNumber,
    CustomerFilterAttribute.CompanyName,
    CustomerFilterAttribute.CustomerNumber,
    CustomerFilterAttribute.SalesCompanyCode,
    CustomerFilterAttribute.PrimaryContactEmail,
    CustomerFilterAttribute.OnboardingSource
  ]);

  // On filter value change
  useEffect(() => {
    if (email || customerType) {
      setDisabledFilters({
        ...allDisabledFilterState,
        emailAndCustomerType: false
      });
      return;
    }

    if (customerId) {
      setDisabledFilters({ ...allDisabledFilterState, customerId: false });
      return;
    }

    if (dunsNumber) {
      setDisabledFilters({ ...allDisabledFilterState, dunsNumber: false });
      return;
    }

    if (companyName) {
      setDisabledFilters({ ...allDisabledFilterState, companyName: false });
      return;
    }

    if (customerNumber || salesCompanyCode) {
      setDisabledFilters({
        ...allDisabledFilterState,
        customerNumberAndSalesCompanyCode: false
      });
      return;
    }

    if (primaryContactEmail) {
      setDisabledFilters({
        ...allDisabledFilterState,
        primaryContactEmail: false
      });
      return;
    }

    setDisabledFilters({ ...defaultDisabledFilterState });
    clearErrors();
  }, [
    clearErrors,
    companyName,
    customerId,
    customerNumber,
    customerType,
    dunsNumber,
    email,
    primaryContactEmail,
    salesCompanyCode
  ]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const filters = createFilters<CustomerFilter>(values);
      if (filters.length) {
        onFilter(filters);
      }
    },
    [onFilter]
  );

  // Reset
  const onReset = useCallback(
    () =>
      reset({
        [CustomerFilterAttribute.Email]: '',
        [CustomerFilterAttribute.CustomerType]: '',
        [CustomerFilterAttribute.CustomerId]: '',
        [CustomerFilterAttribute.DunsNumber]: '',
        [CustomerFilterAttribute.CompanyName]: '',
        [CustomerFilterAttribute.CustomerNumber]: '',
        [CustomerFilterAttribute.SalesCompanyCode]: '',
        [CustomerFilterAttribute.PrimaryContactEmail]: '',
        [CustomerFilterAttribute.OnboardingSource]: ''
      }),
    [reset]
  );

  // Select option
  const onSelect = useCallback(
    (name: CustomerFilterAttribute) => (option: OptionType) => {
      setValue(name, option.value, { shouldDirty: true });
    },
    [setValue]
  );

  // Customer types
  const customerTypeOptions: OptionType[] = useMemo(
    () =>
      meta.data.customerTypes.map(({ name }) => ({
        key: name,
        label: parseOptionLabel('customers.customer_type', name),
        value: name
      })),
    [meta, parseOptionLabel]
  );

  // Salescompany Countries
  const sccOptions: OptionType[] = useMemo(() => {
    return getSalesCompanyCodeOptions(
      meta.data.salesCompanyCodes.read,
      countryDisplayNames
    );
  }, [countryDisplayNames, meta]);

  // Onboarding sources
  const onboardingSourceOptions: OptionType[] = useMemo(
    () =>
      meta.data.onboardingSources.map(({ name }) => ({
        key: name,
        label: parseOptionLabel('customers.onboarding_source', name),
        value: name
      })),
    [meta, parseOptionLabel]
  );

  if (meta.isLoading) {
    return <Loader center padding />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid $column>
        <ThreeColumnGrid>
          <InputField
            type="text"
            description="customers.input_company_name"
            register={register(CustomerFilterAttribute.CompanyName)}
            disabled={disabledFilters.companyName}
            error={errors[CustomerFilterAttribute.CompanyName]}
          />
          <InputField
            type="text"
            description="customers.input_duns_number"
            register={register(CustomerFilterAttribute.DunsNumber)}
            disabled={disabledFilters.dunsNumber}
            error={errors[CustomerFilterAttribute.DunsNumber]}
          />
          <InputField
            type="text"
            description="customers.input_primary_contact_email"
            register={register(CustomerFilterAttribute.PrimaryContactEmail)}
            disabled={disabledFilters.primaryContactEmail}
            error={errors[CustomerFilterAttribute.PrimaryContactEmail]}
          />
        </ThreeColumnGrid>
        <ThreeColumnGrid>
          <InputField
            type="text"
            description="customers.input_customer_id"
            register={register(CustomerFilterAttribute.CustomerId)}
            disabled={disabledFilters.customerId}
            error={errors[CustomerFilterAttribute.CustomerId]}
          />
          <InputField
            type="text"
            description="customers.input_email"
            register={register(CustomerFilterAttribute.Email, {
              required: validateField(
                !!customerType,
                intl.formatMessage({
                  id: 'customers.validation_email_and_customer_type'
                })
              )
            })}
            disabled={disabledFilters.emailAndCustomerType}
            error={errors[CustomerFilterAttribute.Email]}
          />
          <SelectField
            name={CustomerFilterAttribute.CustomerType}
            register={register(CustomerFilterAttribute.CustomerType, {
              required: validateField(
                !!email,
                intl.formatMessage({
                  id: 'customers.validation_email_and_customer_type'
                })
              )
            })}
            disabled={disabledFilters.emailAndCustomerType}
            onSelect={onSelect(CustomerFilterAttribute.CustomerType)}
            placeholder={intl.formatMessage({ id: 'select.placeholder' })}
            label={intl.formatMessage({
              id: 'customers.input_customer_type'
            })}
            defaultValue={filters.customerType}
            options={customerTypeOptions}
            error={errors[CustomerFilterAttribute.CustomerType]}
          />
        </ThreeColumnGrid>
        <ThreeColumnGrid>
          <InputField
            type="text"
            description="customers.input_customer_number"
            register={register(CustomerFilterAttribute.CustomerNumber, {
              required: validateField(
                !!salesCompanyCode,
                intl.formatMessage({
                  id: 'customers.validation_customer_number_and_sales_company_code'
                })
              )
            })}
            disabled={disabledFilters.customerNumberAndSalesCompanyCode}
            error={errors[CustomerFilterAttribute.CustomerNumber]}
          />
          <SelectField
            name={CustomerFilterAttribute.SalesCompanyCode}
            register={register(CustomerFilterAttribute.SalesCompanyCode, {
              required: validateField(
                !!customerNumber,
                intl.formatMessage({
                  id: 'customers.validation_customer_number_and_sales_company_code'
                })
              )
            })}
            onSelect={onSelect(CustomerFilterAttribute.SalesCompanyCode)}
            disabled={disabledFilters.customerNumberAndSalesCompanyCode}
            defaultValue={salesCompanyCode}
            placeholder={intl.formatMessage({ id: 'select.placeholder' })}
            label={intl.formatMessage({
              id: 'contracts.input_salescompany'
            })}
            options={sccOptions}
            enableSearch
            error={errors[CustomerFilterAttribute.SalesCompanyCode]}
          />
          <SelectField
            name={CustomerFilterAttribute.OnboardingSource}
            register={register(CustomerFilterAttribute.OnboardingSource)}
            onSelect={onSelect(CustomerFilterAttribute.OnboardingSource)}
            placeholder={intl.formatMessage({ id: 'select.placeholder' })}
            label={intl.formatMessage({
              id: 'customers.input_onboarding_source'
            })}
            defaultValue={defaultOnboardingSource}
            options={onboardingSourceOptions}
          />
        </ThreeColumnGrid>
        <ButtonGrid>
          <Button
            color="primary"
            backgroundColor="transparent"
            onClick={onReset}
            marginRight
          >
            <FormattedMessage id="search.clear" />
          </Button>
          <Button submit>
            <FormattedMessage id="search.advanced_btn_submit" />
          </Button>
        </ButtonGrid>
      </Grid>
    </Form>
  );
};

export default CustomerFilters;
