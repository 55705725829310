import * as config from 'config';
import { CustomerAcceptanceResponse } from 'models';
import { apiFetch } from 'utils/fetch';

export async function findCustomerAcceptances(
  customerId: string
): Promise<CustomerAcceptanceResponse> {
  const url = `${config.backendApi}/customers/${customerId}/acceptances`;

  return apiFetch<CustomerAcceptanceResponse>(url);
}

export async function deleteCustomerAcceptance(
  customerId: string,
  acceptanceId: string
): Promise<CustomerAcceptanceResponse> {
  const url = `${config.backendApi}/customers/${customerId}/contracts/${acceptanceId}`;

  return apiFetch<CustomerAcceptanceResponse>(url, {
    method: 'DELETE'
  });
}
