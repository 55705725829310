import { FC, useMemo } from 'react';

import { ThemeColor } from 'styles';
import { ServicePlanAction } from 'models';
import { getLanguageValuePair } from 'utils/product';
import Tooltip from 'components/UI/Tooltip';
import Tag from 'components/UI/Tag';

import { Column, Container } from './styled';

type TagColors = {
  background: ThemeColor;
  color: ThemeColor;
};

type Props = {
  action: ServicePlanAction;
  smallScreen: boolean;
};

const ServicePlanStatus: FC<Props> = ({ action, smallScreen }) => {
  // Render colors
  const tagColors = useMemo<TagColors>(() => {
    if (action.actionType === 'REPLACE') {
      return {
        background: 'primaryDark',
        color: 'onPrimary'
      };
    }
    return {
      background: 'grey3',
      color: 'grey6'
    };
  }, [action]);

  // Content
  const content = useMemo(() => {
    const { color, background } = tagColors;
    return (
      <Tag key={action.id} background={background} color={color}>
        {action.actionType}
      </Tag>
    );
  }, [tagColors, action]);

  // Addition text
  const additionalInfo = useMemo(
    () => getLanguageValuePair(action.additionalInformation),
    [action]
  );

  // No tooltip if no addition information
  if (!additionalInfo) {
    return content;
  }

  // Tooltip if not small screen
  if (!smallScreen) {
    return <Tooltip paragraph={additionalInfo}>{content}</Tooltip>;
  }

  // Show all information if small screen
  return (
    <Container>
      <Column>{content}</Column>
      {additionalInfo}
    </Container>
  );
};

export default ServicePlanStatus;
