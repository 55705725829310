import { FC, useRef, useEffect, useCallback, ReactNode } from 'react';
import { useKeyEffect } from 'hooks/useKeyEffect';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { MenuContainer, Menu, MenuItemInner, MenuButton } from './styled';

export type MenuItem = {
  id: number;
  text: string;
  onClick: () => void;
};

type Props = {
  children: ReactNode;
  menu: MenuItem[];
  onClick?: (active: boolean) => void;
};

const DropdownMenu: FC<Props> = ({ children, menu, onClick }) => {
  // Ref
  const itemRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLElement>(null);

  //Hooks
  const [isActive, setIsActive] = useDetectOutsideClick(
    [itemRef, menuRef],
    false
  );

  // Active status
  useEffect(() => {
    if (onClick) {
      onClick(isActive);
    }
  }, [isActive, onClick]);

  // Effect for closing menu with key press
  useKeyEffect({
    action: () => setIsActive(false),
    addEventListener: isActive,
    eventKeys: ['Escape']
  });

  const onClickMenu = useCallback(() => {
    setIsActive(!isActive);
  }, [setIsActive, isActive]);

  const onClickMenuItem = useCallback(
    (onClick: () => void) => () => {
      onClick();
      onClickMenu();
    },
    [onClickMenu]
  );

  return (
    <MenuContainer>
      <MenuButton ref={itemRef} onClick={onClickMenu}>
        {children}
      </MenuButton>
      <Menu ref={menuRef} $isActive={isActive}>
        <ul>
          {menu.map((item) => (
            <li key={item.id}>
              <MenuItemInner onClick={onClickMenuItem(item.onClick)}>
                {item.text}
              </MenuItemInner>
            </li>
          ))}
        </ul>
      </Menu>
    </MenuContainer>
  );
};

export default DropdownMenu;
