import { FC, useCallback, ReactNode, MouseEvent } from 'react';
import { Variants } from 'framer-motion';
import { useKeyEffect } from 'hooks/useKeyEffect';
import { ModalBackground, ModalContainer, ModalBox } from './styled';

// Background
const backgroundVariants: Variants = {
  open: {
    opacity: 1,
    display: 'flex'
  },
  closed: {
    opacity: 0,
    display: 'none',
    transition: {
      opacity: {
        duration: 0
      },
      display: {
        duration: 0
      }
    }
  }
};

// Modal container
const modalVariants: Variants = {
  open: {
    opacity: 1,
    display: 'block'
  },
  closed: {
    opacity: 0,
    display: 'none',
    transition: {
      opacity: {
        duration: 0
      },
      display: {
        duration: 0
      }
    }
  }
};

type Props = {
  children?: ReactNode;
  open: boolean;
  close: () => void;
};

const Modal: FC<Props> = ({ children, open, close }) => {
  // Memoize close action
  const onClose = useCallback(() => close(), [close]);

  // Avoid close on modal click
  const onModalClick = useCallback((e: MouseEvent) => e.stopPropagation(), []);

  // Effect for adding key listener to close modal
  useKeyEffect({
    action: onClose,
    addEventListener: open,
    eventKeys: ['Escape']
  });

  return (
    <ModalBackground
      initial={false}
      data-testid="modal-background"
      transition={{ type: 'tween', duration: 0.1 }}
      variants={backgroundVariants}
      animate={open ? 'open' : 'closed'}
      onClick={onClose}
    >
      <ModalContainer
        transition={{ type: 'tween', duration: 0.1 }}
        initial={false}
        variants={modalVariants}
        animate={open ? 'open' : 'closed'}
      >
        <ModalBox onClick={onModalClick}>{children}</ModalBox>
      </ModalContainer>
    </ModalBackground>
  );
};

export default Modal;
