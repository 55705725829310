import styled from 'styled-components';

type Props = {
  $padding?: boolean;
};

export const Container = styled.div<Props>`
  text-align: center;
  padding: ${({ $padding }) => ($padding ? '64px 0' : '0')};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey4};
  font-weight: bold;
  text-transform: uppercase;
  margin: 8px;
`;

export const Params = styled.div`
  display: flex;
  justify-content: center;
`;

export const List = styled.ul`
  max-width: 500px;
  color: ${({ theme }) => theme.colors.grey4};
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  padding: 8px;
`;
