import { FC, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import Router from 'router';
import { localizationSelector, getLocale } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ThemeWrapper from 'styles/ThemeWrapper';
import Toast from 'components/UI/Toast';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { locale, messages } = useAppSelector(localizationSelector);

  // Get locale
  useEffect(() => {
    dispatch(getLocale());
  }, [dispatch]);

  // Check locale
  if (!locale) {
    return null;
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={messages[locale]}
    >
      <ThemeWrapper>
        <Router />
        <Toast />
      </ThemeWrapper>
    </IntlProvider>
  );
};

export default App;
