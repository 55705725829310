import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { User } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  requestChangeUsername,
  requestChangeUsernameReset,
  userRequestChangeUsernameSelector
} from 'store';
import { regExp } from 'utils/constants';
import { showSuccessToast } from 'utils/toast';

import Loader from 'components/UI/Loader';
import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Button';

import { ButtonGrid } from './styled';

type Props = {
  fetchAuditLogs: () => void;
  onClose: () => void;
  open: boolean;
  user: User;
};

const RequestChangeUsernameModal: FC<Props> = ({
  fetchAuditLogs,
  onClose,
  open,
  user
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(
    userRequestChangeUsernameSelector
  );
  const { firstName, lastName, username } = user.attributes;

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      username: ''
    }
  });

  // Fetch if add was successful
  useEffect(() => {
    if (isSuccess) {
      fetchAuditLogs();
      showSuccessToast(
        intl.formatMessage({ id: 'modal.request_change_username_success' })
      );
      onClose();
    }

    return () => {
      dispatch(requestChangeUsernameReset());
    };
  }, [dispatch, fetchAuditLogs, intl, isSuccess, onClose]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => dispatch(requestChangeUsername(user.id, values.username)),
    [dispatch, user]
  );

  return (
    <EditModal
      title="modal.request_change_username_title"
      open={open}
      close={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          <FormattedMessage
            id="modal.request_change_username_text"
            values={{
              name: `${firstName} ${lastName}`
            }}
          />
        </p>
        <p>({username})</p>
        <InputField
          description="modal.new_username_input"
          register={register('username', {
            required: {
              value: true,
              message: 'input.required'
            },
            pattern: {
              value: regExp.email,
              message: 'input.email_invalid'
            },
            validate: (value) =>
              value !== username ||
              intl.formatMessage({ id: 'modal.new_username_input_same' })
          })}
          error={errors.username}
        />
        {isLoading && <Loader padding center />}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || isLoading} submit>
            <FormattedMessage id="modal.btn_send_request" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default RequestChangeUsernameModal;
