import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserBody, UserUpdateState } from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserUpdateState = {
  update: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  status: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  username: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userUpdateSlice = createSlice({
  name: 'user-update',
  initialState: defaultState,
  reducers: {
    updateUserInit: (state) => {
      state.update.isSuccess = false;
      state.update.isLoading = true;
      state.update.isError = false;
      state.update.error = null;
    },
    updateUserSuccess: (state) => {
      state.update.isSuccess = true;
      state.update.isLoading = false;
    },
    updateUserFailure: (state, action: PayloadAction<APIError>) => {
      state.update.isLoading = false;
      state.update.isError = true;
      state.update.error = action.payload;
    },
    updateUserReset: (state) => {
      state.update = defaultState.update;
    },
    updateUserStatusInit: (state) => {
      state.status.isSuccess = false;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = null;
    },
    updateUserStatusSuccess: (state) => {
      state.status.isSuccess = true;
      state.status.isLoading = false;
    },
    updateUserStatusFailure: (state, action: PayloadAction<APIError>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    updateUserStatusReset: (state) => {
      state.status = defaultState.status;
    },
    updateUsernameInit: (state) => {
      state.username.isSuccess = false;
      state.username.isLoading = true;
      state.username.isError = false;
      state.username.error = null;
    },
    updateUsernameSuccess: (state) => {
      state.username.isSuccess = true;
      state.username.isLoading = false;
    },
    updateUsernameFailure: (state, action: PayloadAction<APIError>) => {
      state.username.isLoading = false;
      state.username.isError = true;
      state.username.error = action.payload;
    },
    updateUsernameReset: (state) => {
      state.username = defaultState.username;
    }
  }
});

// Export selectors
export const userUpdateSelector = (state: RootState) => state.userUpdate.update;
export const userUpdateStatusSelector = (state: RootState) =>
  state.userUpdate.status;
export const userUpdateUsernameSelector = (state: RootState) =>
  state.userUpdate.username;

// Export actions
export const {
  updateUserInit,
  updateUserSuccess,
  updateUserFailure,
  updateUserReset,
  updateUserStatusInit,
  updateUserStatusSuccess,
  updateUserStatusFailure,
  updateUserStatusReset,
  updateUsernameInit,
  updateUsernameSuccess,
  updateUsernameFailure,
  updateUsernameReset
} = userUpdateSlice.actions;

// Export reducer
export const userUpdateReducer = userUpdateSlice.reducer;

// Export thunk
export function updateUserById(userId: string, body: UserBody) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserInit());
    try {
      await services.updateUser(userId, body);
      dispatch(updateUserSuccess());
    } catch (e: any) {
      dispatch(updateUserFailure(e));
    }
  };
}

export function updateUserStatusById(userId: string, activate: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserStatusInit());
    try {
      if (activate) {
        await services.activateUser(userId);
      } else {
        await services.disableUser(userId);
      }
      dispatch(updateUserStatusSuccess());
    } catch (e: any) {
      dispatch(updateUserStatusFailure(e));
    }
  };
}

export function updateUsername(userId: string, username: string) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUsernameInit());
    try {
      await services.confirmChangeUsername(userId, username);
      dispatch(updateUsernameSuccess());
    } catch (e: any) {
      dispatch(updateUsernameFailure(e));
    }
  };
}
