import { FC, useEffect, useCallback, useMemo, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  auditLogsSelector,
  emailBouncesSelector,
  userDetailsSelector,
  fetchEmailBouncesByUserId,
  fetchUserAuditLogsById,
  findUserById,
  resetAuditLogsState,
  resetEmailBouncesState,
  resetUserDetailsState
} from 'store';

import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import UserInfoCard from 'components/users/UserInfoCard';
import UserNameCard from 'components/users/UserNameCard';
import UserPermissionsCard from 'components/users/UserPermissionsCard';
import UserAuditLogCard from 'components/users/UserAuditLogCard';
import { UserEmailBounceCard } from 'components/users/UserEmailBounceCard';

import { Column, Grid } from './styled';

const UserDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const { data, error, isLoading } = useAppSelector(userDetailsSelector);
  const auditLogs = useAppSelector(auditLogsSelector);
  const emailBounces = useAppSelector(emailBouncesSelector);

  // Fetch user data
  const fetchData = useCallback(
    (refetch = false) => {
      if (userId) {
        dispatch(findUserById(userId, refetch));
        dispatch(fetchEmailBouncesByUserId(userId));
      }
    },
    [dispatch, userId]
  );

  // Audit logs
  const fetchAuditLogs = useCallback(() => {
    if (userId) {
      dispatch(fetchUserAuditLogsById(userId));
    }
  }, [dispatch, userId]);

  // Mount fetch user
  useEffect(() => {
    fetchData();
    fetchAuditLogs();

    return () => {
      dispatch(resetUserDetailsState());
      dispatch(resetAuditLogsState());
      dispatch(resetEmailBouncesState());
    };
  }, [dispatch, fetchAuditLogs, fetchData]);

  const hasBounces = useMemo(
    () => !!emailBounces.data.length,
    [emailBounces.data.length]
  );

  // Render content
  if (isLoading) {
    return <Loader center padding />;
  }

  if (!data && error?.status === 403) {
    return <NoAccessState />;
  }

  if (!data) {
    return (
      <EmptyState icon={IconType.Customer} padding includeParams>
        <FormattedMessage id="user_details.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <UserNameCard
        user={data}
        fetchData={fetchData}
        fetchAuditLogs={fetchAuditLogs}
        hasBounces={hasBounces}
      />
      <Grid>
        <Column>
          <UserInfoCard user={data} />
        </Column>
        <Column>
          <UserPermissionsCard
            user={data}
            fetchData={fetchData}
            fetchAuditLogs={fetchAuditLogs}
          />
        </Column>
      </Grid>
      <Grid>
        <Column>
          <UserAuditLogCard
            isLoading={auditLogs.isLoading}
            data={auditLogs.data}
          />
        </Column>
      </Grid>
      {hasBounces && (
        <Grid>
          <Column>
            <UserEmailBounceCard
              isLoading={emailBounces.isLoading}
              data={emailBounces.data}
            />
          </Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default UserDetailsPage;
