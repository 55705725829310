import { useState, useEffect } from 'react';
import { breakpoints } from 'styles';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= breakpoints.s,
    isTablet: window.innerWidth <= breakpoints.l,
    isDesktop: window.innerWidth >= breakpoints.l
  });

  // Update on screen resize
  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= breakpoints.s,
        isTablet: window.innerWidth <= breakpoints.l,
        isDesktop: window.innerWidth >= breakpoints.l
      });
    }

    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};
