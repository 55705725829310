import styled, { DefaultTheme } from 'styled-components';
import { boxShadows } from 'styles';

type Props = {
  theme: DefaultTheme;
  $isError?: boolean;
  $isTight?: boolean;
  $isOpen?: boolean;
  $noBorder?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

interface LabelProps extends Props {
  $minimize: boolean;
}

interface MessageProps extends Props {
  $isHidden: boolean;
}

type OptionProps = {
  $isHighlighted?: boolean;
};

// Handle background color
function handleBackgroundColor({ theme, disabled }: Props) {
  if (disabled) {
    return theme.colors.background;
  }

  return theme.colors.surface;
}

// Handle border color
function handleBorderColor({ theme, $isError, $noBorder }: Props) {
  if ($isError) {
    return theme.colors.error;
  }

  if ($noBorder) {
    return 'none';
  }

  return theme.colors.grey3;
}

// Handle text color
function handleTextColor({ theme, $isError, disabled }: Props) {
  if ($isError) {
    return theme.colors.error;
  }

  if (disabled) {
    return theme.colors.grey4;
  }

  return theme.colors.onBackground;
}

// Handle cursor
function handleCursor({ disabled, readOnly }: Props) {
  if (disabled) {
    return 'default';
  }

  if (readOnly) {
    return 'pointer';
  }

  return 'auto';
}

export const Container = styled.div`
  width: 100%;
`;

export const SelectContainer = styled.div<Props>`
  width: 100%;
  height: ${({ $isTight }) => ($isTight ? '50px' : '56px')};
`;

export const Select = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: ${handleBackgroundColor};
  border: 1px solid ${handleBorderColor};
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: ${({ $isOpen }) => ($isOpen ? boxShadows.md : 'none')};
  opacity: 1;
  z-index: ${({ $isOpen }) => ($isOpen ? '2' : '1')};
`;

export const SelectGroup = styled.div<Props>`
  width: 100%;
  display: flex;
  border-bottom: ${({ $isOpen, theme }) =>
    $isOpen ? `1px solid ${theme.colors.grey3}` : 'none'};
`;

export const SelectInput = styled.input<Props>`
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  line-height: ${({ $isTight }) => ($isTight ? '22px' : '24px')};
  color: ${handleTextColor};
  caret-color: ${({ theme }) => theme.colors.primaryLight};
  padding: ${({ $isTight }) => ($isTight ? '20px 16px 6px' : '24px 16px 8px')};
  margin: 0;
  border: none;
  outline: none;
  cursor: ${handleCursor};
  transition: all 0.2s ease-out;
`;

export const SelectLabel = styled.label<LabelProps>`
  position: absolute;
  top: ${({ $minimize, $isTight }) => {
    if ($minimize) {
      return $isTight ? '6px' : '8px';
    }
    return $isTight ? '14px' : '16px';
  }};
  left: 16px;
  font-size: ${({ $minimize }) => ($minimize ? '12px' : '16px')};
  line-height: ${({ $minimize }) => ($minimize ? '16px' : '24px')};
  color: ${handleTextColor};
  pointer-events: none;
  transition: all 0.2s ease-out;
`;

export const SelectButton = styled.button<Props>`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 0 16px;
  border: none;
`;

export const Options = styled.ul<Props>`
  width: 100%;
  max-height: 300px;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.surface};
  padding: 0;
  margin: 0;
  border-radius: 0 0 16px 16px;
  overflow-y: scroll;
  z-index: 99;
`;

export const Option = styled.li<OptionProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.onSurface};
  background-color: ${({ theme, $isHighlighted }) =>
    $isHighlighted ? theme.colors.grey2 : 'transparent'};
  padding: 14px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey2};
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
`;

export const Mark = styled.mark`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

export const Message = styled.p<MessageProps>`
  height: 16px;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
  font-size: 12px;
  font-weight: bold;
  color: ${handleTextColor};
  padding: 4px 16px;
  margin: 0 0 8px;
`;
