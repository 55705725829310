import { FC, ReactNode } from 'react';
import { DetailContainer, DetailValue, DetailTitle } from './styled';

type Props = {
  title: string;
  value: ReactNode;
};

const InfoItem: FC<Props> = ({ title, value = '—' }) => {
  return (
    <DetailContainer>
      <DetailTitle>{title}</DetailTitle>
      <DetailValue>{value}</DetailValue>
    </DetailContainer>
  );
};

export default InfoItem;
