import styled, { DefaultTheme } from 'styled-components';

type Props = {
  theme: DefaultTheme;
  disabled?: boolean;
  $isError?: boolean;
  $isFocus?: boolean;
  $isTight?: boolean;
};

// Handle background color
function handleBackgroundColor({ theme, disabled }: Props) {
  if (disabled) {
    return theme.colors.background;
  }

  return theme.colors.surface;
}

// Handle border color
function handleBorderColor({ theme, $isError }: Props) {
  if ($isError) {
    return theme.colors.error;
  }

  return theme.colors.grey3;
}

// Handle text color
function handleTextColor(
  theme: DefaultTheme,
  error?: boolean,
  disabled?: boolean
) {
  if (error) {
    return theme.colors.error;
  }

  if (disabled) {
    return theme.colors.grey4;
  }

  return theme.colors.onBackground;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const InputContainer = styled.div<Props>`
  display: flex;
  background-color: ${handleBackgroundColor};
  border-radius: 16px;
  background-clip: padding-box;
  border: 1px solid ${handleBorderColor};
  height: ${({ $isTight }) => ($isTight ? '50px' : '56px')};
  box-sizing: border-box;
`;

export const Input = styled.input<Props>`
  width: 100%;
  min-width: 180px;
  background-color: ${handleBackgroundColor};
  outline: none;
  border: none;
  padding: ${({ $isTight }) => ($isTight ? '22px 14px 6px' : '24px 14px 8px')};
  font-size: 16px;
  line-height: ${({ $isTight }) => ($isTight ? '22px' : '24px')};
  transition: all 0.3s ease-out;
  border-radius: 16px;
  caret-color: ${({ theme }) => theme.colors.primary};

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label,
  &:-webkit-autofill + label {
    font-size: 12px;
    margin-top: ${({ $isTight }) => ($isTight ? '6px' : '8px')};
    line-height: ${({ $isTight }) => ($isTight ? '14px' : '16px')};
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const InputLabel = styled.label<Props>`
  position: absolute;
  margin-top: ${({ $isTight }) => ($isTight ? '15px' : '16px')};
  line-height: ${({ $isTight }) => ($isTight ? '22px' : '24px')};
  font-size: 16px;
  color: ${({ theme, $isError, disabled }) =>
    handleTextColor(theme, $isError, disabled)};
  pointer-events: none;
  transition: all 0.3s ease-out;
  top: 0;
  left: 16px;
`;

export const InputButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0 16px;
  border: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ErrorIcon = styled.img`
  margin-right: 16px;
  width: 24px;
`;

export const ErrorMessage = styled.p<Props>`
  display: ${({ $isTight }) => ($isTight ? 'none' : 'block')};
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  height: 16px;
  font-size: 12px;
  padding: 4px 16px;
  margin: 0 0 8px;
`;
