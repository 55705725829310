import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 16px 16px 16px 24px;
  }
`;

export const ModalClose = styled.div`
  cursor: pointer;
  line-height: 8px;
  padding: 24px;
`;

export const ModalBody = styled.div`
  padding: 0 24px 24px;
`;
