import { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ServiceContract,
  ContractStatus,
  ContractCancellationReason,
  EditServiceContractStatusField
} from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  authSelector,
  contractStatusUpdateSelector,
  resetUpdateContractStatus,
  updateServiceContractStatus
} from 'store';
import { showSuccessToast } from 'utils/toast';

import FoldableContainer from 'components/UI/FoldableContainer';
import SelectField, { OptionType } from 'components/UI/SelectField';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { ButtonGrid } from 'styles';
import { Grid } from './styled';

const statusOptions: OptionType[] = [
  {
    key: 'active',
    label: 'Active',
    value: ContractStatus.Active
  },
  {
    key: 'onHold',
    label: 'On hold',
    value: ContractStatus.OnHold
  },
  {
    key: 'cancelled',
    label: 'Cancelled',
    value: ContractStatus.Cancelled
  }
];

const reasonOptions: OptionType[] = [
  {
    key: 'notPaid',
    label: 'Not paid',
    value: ContractCancellationReason.NotPaid
  },
  {
    key: 'customerRequest',
    label: 'Customer request',
    value: ContractCancellationReason.CustomerRequested
  },
  {
    key: 'notFollowingServicePlan',
    label: 'Not following service plan',
    value: ContractCancellationReason.NotFollowingServicePlan
  }
];

type Props = {
  open: boolean;
  contract: ServiceContract;
  fetchAuditLogs: () => void;
  close: () => void;
};

const EditServiceContractStatusModal: FC<Props> = ({
  open,
  contract,
  fetchAuditLogs,
  close
}) => {
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const { session } = useAppSelector(authSelector);
  const { data, isSuccess, isLoading } = useAppSelector(
    contractStatusUpdateSelector
  );

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors: formErrors }
  } = useForm();

  // Watch
  const [status, cancelReason] = watch([
    EditServiceContractStatusField.Status,
    EditServiceContractStatusField.CancelReason
  ]);

  // State
  const [disabled, setDisabled] = useState<boolean>(true);
  const [inputIsExtended, setInputIsExtended] = useState<boolean>(false);

  // Success
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        `${intl.formatMessage({
          id: 'contract_details.status_update_success'
        })} ${data}`
      );
      dispatch(resetUpdateContractStatus());
      fetchAuditLogs();
      close();
    }
  }, [dispatch, fetchAuditLogs, close, isSuccess, data, intl]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const { scpUser } = session;
      dispatch(
        updateServiceContractStatus(contract.id, {
          cancellationDate: Date.now(),
          cancellationUser: scpUser?.email,
          cancellationReason: values.cancellationReason,
          status: values.status
        })
      );
    },
    [dispatch, contract, session]
  );

  // Update input fields based on status
  const updateInputFields = useCallback(
    (status: ContractStatus) => {
      const extendInput =
        status === ContractStatus.Cancelled &&
        !inputIsExtended &&
        contract.attributes.status !== ContractStatus.Cancelled;

      setInputIsExtended(extendInput);
    },
    [contract, inputIsExtended]
  );

  // On new status selection
  const onSelectStatus = useCallback(
    (option: OptionType) => {
      updateInputFields(option.value as ContractStatus);
      setValue(EditServiceContractStatusField.Status, option.value, {
        shouldDirty: true
      });
      setDisabled(false);
    },
    [updateInputFields, setValue]
  );

  // Select option
  const onSelect = useCallback(
    (name: EditServiceContractStatusField) => (option: OptionType) =>
      setValue(name, option.value, { shouldDirty: true }),
    [setValue]
  );

  return (
    <EditModal
      title="contract_details.status_modal_title"
      open={open}
      close={close}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <SelectField
            value={status}
            defaultValue={contract.attributes.status}
            name={EditServiceContractStatusField.Status}
            register={register(EditServiceContractStatusField.Status, {
              required: intl.formatMessage({
                id: 'contract_details.status_modal_status_input_required_error'
              })
            })}
            onSelect={onSelectStatus}
            placeholder={intl.formatMessage({
              id: 'contract_details.status_modal_status_input_placeholder'
            })}
            label={intl.formatMessage({
              id: 'contract_details.status_modal_status_input_type'
            })}
            options={statusOptions.filter(
              (option) => option.value !== contract.attributes.status
            )}
            error={formErrors.status}
          />
          <FoldableContainer open={inputIsExtended}>
            <SelectField
              value={cancelReason}
              register={register(EditServiceContractStatusField.CancelReason, {
                required: {
                  value: inputIsExtended,
                  message: intl.formatMessage({
                    id: 'contract_details.status_modal_reason_input_required_error'
                  })
                }
              })}
              name={EditServiceContractStatusField.CancelReason}
              onSelect={onSelect(EditServiceContractStatusField.CancelReason)}
              placeholder={intl.formatMessage({
                id: 'contract_details.status_modal_reason_input_placeholder'
              })}
              label={intl.formatMessage({
                id: 'contract_details.status_modal_reason_input_type'
              })}
              options={reasonOptions}
              error={formErrors.cancellationReason}
            />
          </FoldableContainer>
        </Grid>
        <ButtonGrid>
          <Button
            marginRight
            onClick={close}
            backgroundColor="transparent"
            color="primary"
          >
            <FormattedMessage id="contract_details.status_modal_button_cancel" />
          </Button>
          <Button submit disabled={disabled || isLoading}>
            <FormattedMessage id="contract_details.status_modal_button_confirm" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditServiceContractStatusModal;
