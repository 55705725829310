import { FC, useMemo } from 'react';

import { SoftwareUpdateList } from 'models/connectivity';
import { getConnectivityStatusColors } from 'utils/connectivity';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import Tooltip from 'components/UI/Tooltip';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import CardHeader from 'components/UI/Card/CardHeader';
import Loader from 'components/UI/Loader';
import Tag from 'components/UI/Tag';

const headerCells: HeaderCell[] = [
  {
    intl: 'product_details.software_updates_version',
    sortable: false
  },
  {
    intl: 'product_details.software_updates_date',
    sortable: false
  },
  {
    intl: 'product_details.software_updates_status',
    sortable: false
  }
];

type Props = {
  softwareUpdates: SoftwareUpdateList;
};

const SoftwareUpdatesTable: FC<Props> = ({ softwareUpdates }) => {
  // Content
  const content = useMemo(() => {
    if (softwareUpdates.isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        emptyTextId="product_details.software_updates_not_found"
        headerCells={headerCells}
        bodyRows={
          softwareUpdates.data?.map(({ attributes }) => {
            const {
              software,
              status,
              lastModifiedAt,
              lastStatusType,
              statusMessage
            } = attributes;

            const currentStatus =
              status === 'finished' && lastStatusType === 'error'
                ? lastStatusType
                : status;

            // Connectivity status colors
            const { color, background } =
              getConnectivityStatusColors(currentStatus);

            return {
              software,
              status: (
                <Tooltip paragraph={statusMessage}>
                  <Tag background={background} color={color}>
                    {currentStatus.toUpperCase()}
                  </Tag>
                </Tooltip>
              ),
              lastModified:
                lastModifiedAt > 0
                  ? formatDate(lastModifiedAt, 'yyyy-MM-dd')
                  : '-'
            };
          }) ?? []
        }
      />
    );
  }, [softwareUpdates]);

  return (
    <Card>
      <CardHeader intl="product_details.software_updates_title" />
      {content}
    </Card>
  );
};

export default SoftwareUpdatesTable;
