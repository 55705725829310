import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as routes from 'router/Routes';
import {
  authSelector,
  fetchMetaData,
  metaSelector,
  setStartRoute
} from 'store';
import { getBaseRoute } from 'utils/layout';
import { showErrorToast } from 'utils/toast';

// Auth route
export const AuthRoute: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    session: { isAuthenticated },
    authExpired,
    authRateLimited
  } = useAppSelector(authSelector);
  const meta = useAppSelector(metaSelector);
  const { pathname } = useLocation();

  // startRoute
  const startRoute = useMemo(() => getBaseRoute(pathname), [pathname]);

  // Previous route
  const prevRoute = useMemo(() => {
    // Full path if auth expires
    if (authExpired) {
      return pathname;
    }
    return startRoute;
  }, [pathname, startRoute, authExpired]);

  useEffect(() => {
    return () => {
      // Set start route on unmount
      dispatch(setStartRoute(startRoute));
    };
  }, [dispatch, startRoute]);

  // Fetch meta data
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchMetaData());
    }
  }, [dispatch, isAuthenticated]);

  // Show toasts when auth errors
  useEffect(() => {
    if (authExpired) {
      showErrorToast(intl.formatMessage({ id: 'misc.expired_session' }));
    }
    if (authRateLimited) {
      showErrorToast(intl.formatMessage({ id: 'misc.rate_limit' }));
    }
  }, [intl, authExpired, authRateLimited]);

  // Redirect if unauthenticated
  if (!isAuthenticated || meta.isError) {
    return <Navigate to={routes.LOGIN} state={{ prevRoute }} />;
  }

  return <Outlet />;
};
