import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'router/Routes';
import { ProductAttributes } from 'models';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';

const headerCells: HeaderCell[] = [
  {
    intl: 'products.product_table_header_image',
    sortable: false
  },
  {
    intl: 'products.product_table_header_title',
    sortable: true
  },
  {
    intl: 'products.product_table_header_category',
    sortable: true
  },
  {
    intl: 'products.product_table_header_pnc',
    sortable: true
  },
  {
    intl: 'products.product_table_header_serial',
    sortable: true
  }
];

type Props = {
  products: ProductAttributes[];
};

const ProductTable: FC<Props> = ({ products }) => {
  const navigate = useNavigate();

  // On click
  const onClick = useCallback(
    (iprId: string) => navigate(routes.productDetailsLink(iprId)),
    [navigate]
  );

  return (
    <SortableTable
      headerCells={headerCells}
      bodyRows={products.map((product) => ({
        imageUrl: product.imageUrl,
        title: product.modelName
          ? `${product.brand} ${product.modelName}`
          : '–',
        productCategory: product.productCategory || '–',
        pnc: product.pnc || '–',
        serialNumber: product.serialNumber || '–',
        link: product.iprId
      }))}
      onClick={onClick}
      sortIndex={1}
    />
  );
};

export default ProductTable;
