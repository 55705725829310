import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserPasswordPolicy, UserPasswordPolicyState } from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserPasswordPolicyState = {
  list: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  update: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userPasswordPolicySlice = createSlice({
  name: 'user-password-policy',
  initialState: defaultState,
  reducers: {
    getUserPasswordPoliciesInit: (state) => {
      state.list.isSuccess = false;
      state.list.isLoading = true;
      state.list.isError = false;
      state.list.error = null;
    },
    getUserPasswordPoliciesSuccess: (
      state,
      action: PayloadAction<UserPasswordPolicy[]>
    ) => {
      state.list.data = action.payload;
      state.list.isSuccess = true;
      state.list.isLoading = false;
    },
    getUserPasswordPoliciesFailure: (
      state,
      action: PayloadAction<APIError>
    ) => {
      state.list.isLoading = false;
      state.list.isError = true;
      state.list.error = action.payload;
    },
    getUserPasswordPoliciesReset: (state) => {
      state.list = defaultState.list;
    },
    updateUserPasswordPolicy: (state) => {
      state.update.isSuccess = false;
      state.update.isLoading = true;
      state.update.isError = false;
      state.update.error = null;
    },
    updateUserPasswordPolicySuccess: (state) => {
      state.update.isSuccess = true;
      state.update.isLoading = false;
    },
    updateUserPasswordPolicyFailure: (
      state,
      action: PayloadAction<APIError>
    ) => {
      state.update.isLoading = false;
      state.update.isError = true;
      state.update.error = action.payload;
    },
    updateUserPasswordPolicyReset: (state) => {
      state.update = defaultState.update;
    }
  }
});

// Export selectors
export const userPasswordPolicySelector = (state: RootState) =>
  state.userPasswordPolicy;

// Export actions
export const {
  getUserPasswordPoliciesInit,
  getUserPasswordPoliciesSuccess,
  getUserPasswordPoliciesFailure,
  getUserPasswordPoliciesReset,
  updateUserPasswordPolicy,
  updateUserPasswordPolicySuccess,
  updateUserPasswordPolicyFailure,
  updateUserPasswordPolicyReset
} = userPasswordPolicySlice.actions;

// Export reducer
export const userPasswordPolicyReducer = userPasswordPolicySlice.reducer;

// Export thunk
export function fetchPasswordPolicies() {
  return async (dispatch: Dispatch) => {
    dispatch(getUserPasswordPoliciesInit());
    try {
      const result = await services.getPasswordPolicies();
      dispatch(getUserPasswordPoliciesSuccess(result));
    } catch (e: any) {
      dispatch(getUserPasswordPoliciesFailure(e));
    }
  };
}

export function updateUserPasswordPolicyById(userId: string, policy: string) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserPasswordPolicy());
    try {
      await services.updateUserPasswordPolicy(userId, policy);
      dispatch(updateUserPasswordPolicySuccess());
    } catch (e: any) {
      dispatch(updateUserPasswordPolicyFailure(e));
    }
  };
}
