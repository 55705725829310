import { isEmptyObject } from 'cs-alpha-utils/utils/object-utils';
import { apiFetch, createFilterUrl } from 'utils/fetch';
import * as config from 'config';
import {
  DeviceFilter,
  DevicesResponse,
  SoftwareUpdatesResponse,
  DeviceResponse
} from 'models/connectivity';

export async function findDevices(
  offset: number,
  limit: number,
  filter: DeviceFilter
): Promise<DevicesResponse> {
  const url = createFilterUrl(
    `${config.backendApi}/connectivity/devices`,
    filter
  );
  const params = `${
    isEmptyObject(filter) ? '?' : '&'
  }limit=${limit}&offset=${offset}`;

  return apiFetch<DevicesResponse>(`${url}${params}`);
}

export async function getDeviceByIprId(iprId: string): Promise<DeviceResponse> {
  const url = `${config.backendApi}/connectivity/devices/${iprId}`;

  return apiFetch<DeviceResponse>(url);
}

export async function getSoftwareUpdateHistory(
  iprId: string,
  offset: number,
  limit: number
): Promise<SoftwareUpdatesResponse> {
  const params = `?offset=${offset}&limit=${limit}`;
  const url = `${config.backendApi}/connectivity/devices/${iprId}/software-updates${params}`;

  return apiFetch<SoftwareUpdatesResponse>(url);
}
