import { FC, ReactNode } from 'react';
import { Root } from './styled';

type TableHeaderProps = {
  children?: ReactNode;
};

const TableHeader: FC<TableHeaderProps> = ({ children }) => {
  return <Root>{children}</Root>;
};

export default TableHeader;
