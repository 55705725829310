import SortIcon from 'components/UI/Icon/SortIcon';
import { FC, ReactNode, useCallback } from 'react';
import { Root } from './styled';

enum SortOrder {
  Asc = 1,
  Desc = -1
}
type Props = {
  children?: ReactNode;
  active?: boolean;
  onClick?: () => void;
  sortable?: boolean;
  sortOrder?: SortOrder;
};

const TableHeaderCell: FC<Props> = ({
  children,
  active = false,
  onClick,
  sortable = false,
  sortOrder = SortOrder.Asc
}) => {
  // OnClick
  const onClickHandler = useCallback(() => {
    if (sortable) {
      onClick?.();
    }
  }, [sortable, onClick]);

  return (
    <Root $clickable={sortable}>
      <button onClick={onClickHandler}>
        <p>{children}</p>
        {sortable && (
          <SortIcon
            active={active}
            direction={sortOrder === SortOrder.Asc ? 'up' : 'down'}
          />
        )}
      </button>
    </Root>
  );
};

export default TableHeaderCell;
