import * as locales from 'date-fns/locale';
import { dateToStringInFormat } from 'cs-alpha-utils/utils/date-utils';
import { Language } from 'models';

export function getDateLocale(locale: Language) {
  if (locale === 'en') {
    return;
  }
  return locales[locale];
}

export function formatDate(
  date: number,
  dateFormat: string = 'yyyy-MM-dd HH:mm'
): string {
  // Convert unix timeStamp to ms
  const timeStamp = date.toString().length === 10 ? date * 1000 : date;
  return dateToStringInFormat(timeStamp, dateFormat);
}
