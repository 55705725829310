import { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
};

const SEO: FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>
        {`
          ${'Husqvarna'} 
          ${'Header'}
          - 
          ${title || 'Login'}
        `}
      </title>
    </Helmet>
  );
};

export default SEO;
