import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  APIError,
  CompanyFilter,
  ExternalCompany,
  CompanyState,
  CompanyFilterAttribute
} from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: CompanyState = {
  search: {
    inputCountryCode: '',
    inputValue: '',
    data: [],
    advancedData: [],
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  select: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const companySlice = createSlice({
  name: 'company',
  initialState: defaultState,
  reducers: {
    setSearchInputCountryCode: (state, action: PayloadAction<string>) => {
      state.search.inputCountryCode = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<string>) => {
      state.search.inputValue = action.payload;
    },
    findExternalCompaniesInit: (state) => {
      state.search.isSuccess = false;
      state.search.isLoading = true;
      state.search.isError = false;
      state.search.error = null;
    },
    findExternalCompaniesSuccess: (
      state,
      action: PayloadAction<ExternalCompany[]>
    ) => {
      state.search.data = action.payload;
      state.search.isSuccess = true;
      state.search.isLoading = false;
    },
    findExternalCompaniesAdvancedSuccess: (
      state,
      action: PayloadAction<ExternalCompany[]>
    ) => {
      state.search.advancedData = action.payload;
      state.search.isSuccess = true;
      state.search.isLoading = false;
    },
    findExternalCompaniesFailure: (state, action: PayloadAction<APIError>) => {
      state.search.isLoading = false;
      state.search.isError = true;
      state.search.error = action.payload;
    },
    selectExternalCompanyInit: (state) => {
      state.select.isSuccess = false;
      state.select.isLoading = true;
      state.select.isError = false;
      state.select.error = null;
    },
    selectExternalCompanySuccess: (
      state,
      action: PayloadAction<ExternalCompany>
    ) => {
      state.select.data = action.payload;
      state.select.isSuccess = true;
      state.select.isLoading = false;
    },
    selectExternalCompanyFailure: (state, action: PayloadAction<APIError>) => {
      state.select.isLoading = false;
      state.select.isError = true;
      state.select.error = action.payload;
    },
    resetSearchResultState: (state) => {
      state.search = {
        ...defaultState.search,
        inputCountryCode: state.search.inputCountryCode,
        inputValue: state.search.inputValue
      };
    },
    resetSelectState: (state) => {
      state.select.data = null;
    },
    resetCompanyState: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const companySearchSelector = (state: RootState) => state.company.search;
export const companySelectSelector = (state: RootState) => state.company.select;

// Export actions
export const {
  setSearchInputValue,
  setSearchInputCountryCode,
  findExternalCompaniesInit,
  findExternalCompaniesSuccess,
  findExternalCompaniesAdvancedSuccess,
  findExternalCompaniesFailure,
  selectExternalCompanyInit,
  selectExternalCompanySuccess,
  selectExternalCompanyFailure,
  resetSearchResultState,
  resetSelectState,
  resetCompanyState
} = companySlice.actions;

// Export reducer
export const companyReducer = companySlice.reducer;

// Export thunk
export function findExternalCompanies(
  filters: CompanyFilter[],
  advanced = false
) {
  return async (dispatch: Dispatch) => {
    dispatch(findExternalCompaniesInit());
    try {
      const result = await services.findExternalCompaniesByFilter(filters);

      if (advanced) {
        dispatch(findExternalCompaniesAdvancedSuccess(result));
      } else {
        dispatch(findExternalCompaniesSuccess(result));
      }
    } catch (e: any) {
      dispatch(findExternalCompaniesFailure(e));
    }
  };
}

export function selectExternalCompany(dunsNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(selectExternalCompanyInit());
    try {
      const [firstCompany] = await services.findExternalCompaniesByFilter([
        {
          attribute: CompanyFilterAttribute.DunsNumber,
          value: dunsNumber
        }
      ]);

      dispatch(selectExternalCompanySuccess(firstCompany));
    } catch (e: any) {
      dispatch(selectExternalCompanyFailure(e));
    }
  };
}
