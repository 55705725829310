import { FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { motion, Transition } from 'framer-motion';
import { Hamburger, Button } from './styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  transition?: Transition;
};

const HamburgerButton: FC<Props> = ({ setIsOpen, isOpen, transition }) => {
  const theme = useTheme();

  // Paths
  const motionPaths = useMemo(() => {
    const openColor = '#000000';
    const closedColor = theme.colors.onSurface;
    const props = [
      {
        id: 1,
        variants: {
          closed: {
            d: 'M 2 2.5 L 20 2.5',
            stroke: closedColor,
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            d: 'M 3 16.5 L 17 2.5',
            stroke: openColor,
            transition: { stroke: { delay: 0.1 } }
          }
        }
      },
      {
        id: 2,
        d: 'M 2 9.423 L 20 9.423',
        variants: {
          closed: {
            opacity: 1,
            stroke: closedColor,
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            opacity: 0,
            stroke: openColor,
            transition: { stroke: { delay: 0.1 } }
          }
        }
      },
      {
        id: 3,
        variants: {
          closed: {
            d: 'M 2 16.346 L 20 16.346',
            stroke: closedColor,
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            d: 'M 3 2.5 L 17 16.346',
            stroke: openColor,
            transition: { stroke: { delay: 0.1 } }
          }
        }
      }
    ];

    return props.map(({ id, d, variants }) => {
      return (
        <motion.path
          key={id}
          fill="transparent"
          strokeWidth="2.5"
          strokeLinecap="square"
          d={d}
          variants={variants}
        />
      );
    });
  }, [theme]);

  // Click
  const onClick = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  return (
    <Hamburger
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      transition={transition ?? { type: 'tween' }}
    >
      <Button type="button" onClick={onClick}>
        <svg width="20" height="20" viewBox="0 0 23 18">
          {motionPaths}
        </svg>
      </Button>
    </Hamburger>
  );
};

export default HamburgerButton;
