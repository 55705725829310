import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/Routes';

import Heading, { Type } from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Seo from 'components/SEO';

import { Container } from './styled';

const NotFoundPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  // Go to start
  const onClick = useCallback(() => navigate(routes.ROOT), [navigate]);

  return (
    <Container>
      <Seo title={intl.formatMessage({ id: 'not_found.title' })} />
      <Heading type={Type.h1}>
        <FormattedMessage id="not_found.title" />
      </Heading>
      <p>
        <FormattedMessage id="not_found.description" />
      </p>
      <div>
        <Button onClick={onClick}>
          <FormattedMessage id="not_found.button" />
        </Button>
      </div>
    </Container>
  );
};

export default NotFoundPage;
