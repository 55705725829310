import { FC, useEffect, useMemo } from 'react';

import {
  fetchServicePlans,
  resetServicePlanState,
  servicePlanSelector
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Heading from 'components/UI/Heading';
import ServicePlanViewer from 'components/serviceplans/ServicePlanViewer';

import { Container } from './styled';

const ServicePlansPage: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(servicePlanSelector);
  const [iprId, pnc, queryHr] = useQuery('iprId', 'pnc', 'humanResource');

  // Human resource
  const humanResource = useMemo<string>(() => {
    const param = queryHr?.toUpperCase();
    if (
      param !== 'OPERATOR' &&
      param !== 'OPERATOR_TECHNICIAN' &&
      param !== 'TECHNICIAN'
    ) {
      return '';
    }
    return param;
  }, [queryHr]);

  // Fetch service plan
  useEffect(() => {
    if (iprId || pnc) {
      dispatch(fetchServicePlans({ iprId, pnc }));
    }
    return () => {
      dispatch(resetServicePlanState());
    };
  }, [dispatch, iprId, pnc]);

  return (
    <Container>
      <ServicePlanViewer
        data={data}
        isLoading={isLoading}
        humanResource={humanResource}
      >
        <Heading>{data?.attributes.productName}</Heading>
      </ServicePlanViewer>
    </Container>
  );
};

export default ServicePlansPage;
