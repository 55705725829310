import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SCPUser } from 'models';
import { getDiamPermissions } from 'utils/roles';

import Heading, { Type } from 'components/UI/Heading';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { Content, ButtonGrid } from './styled';

// Azure header cells
const azureHeaderCells: HeaderCell[] = [
  {
    sortable: false,
    intl: 'modal.roles_permission'
  },
  {
    sortable: false,
    intl: 'modal.roles_scc'
  }
];

// DIAM header cells
const diamHeaderCells: HeaderCell[] = [
  {
    sortable: false,
    intl: 'modal.roles_permission'
  }
];

type Props = {
  user: SCPUser | null;
  open: boolean;
  onClose: () => void;
};

const RolesModal: FC<Props> = ({ user, open, onClose }) => {
  const intl = useIntl();

  // Azure roles
  const azureBodyRows = useMemo(() => {
    if (!user) {
      return [];
    }
    return user.roles.azure.map((role) => ({
      permission: role.permission,
      companyCode:
        role.companyCode ?? intl.formatMessage({ id: 'modal.roles_all_scc' })
    }));
  }, [user, intl]);

  // DIAM roles
  const diamBodyRows = useMemo(() => {
    if (!user) {
      return [];
    }
    return [
      ...getDiamPermissions(user, 'isGlobalAdmin'),
      ...getDiamPermissions(user, 'isGlobalReadonlyAdmin'),
      ...getDiamPermissions(user, 'isAdmin'),
      ...getDiamPermissions(user, 'isReadOnlyAdmin'),
      ...getDiamPermissions(user, 'canViewStatistics')
    ];
  }, [user]);

  return (
    <EditModal title="modal.roles_title" open={open} close={onClose}>
      <div>
        <Content>
          <FormattedMessage
            id="modal.roles_description"
            values={{
              name: user?.displayName,
              email: user?.email
            }}
          />
        </Content>
        <Heading type={Type.h3}>Azure</Heading>
        <SortableTable
          headerCells={azureHeaderCells}
          bodyRows={azureBodyRows}
          emptyTextId="modal.roles_empty"
        />
        <Heading type={Type.h3}>DIAM</Heading>
        <SortableTable
          headerCells={diamHeaderCells}
          bodyRows={diamBodyRows}
          emptyTextId="modal.roles_empty"
        />
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
          >
            <FormattedMessage id="modal.btn_close" />
          </Button>
        </ButtonGrid>
      </div>
    </EditModal>
  );
};

export default RolesModal;
