import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeaderGrid = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const HeaderLeftCol = styled.div`
  flex: 2;

  @media (max-width: ${breakpoints.l}px) {
    flex: 1;
  }
`;

export const HeaderRightCol = styled.div`
  flex: 1;
`;
