import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showSuccessToast } from 'utils/toast';
import Icon, { IconType } from 'components/UI/Icon';

import { Container } from './styled';

type Props = {
  title: string;
  children: string;
};

const CopyField: FC<Props> = ({ title, children }) => {
  const intl = useIntl();

  // Copy
  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(children);
    showSuccessToast(`${intl.formatMessage({ id: 'misc.copied' })} "${title}"`);
  }, [intl, title, children]);

  return (
    <Container onClick={onCopy}>
      {children}
      <Icon type={IconType.Copy} size="small" themeType="grey6" />
    </Container>
  );
};

export default CopyField;
