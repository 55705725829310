import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { createFilters } from 'utils/filtering';
import { splitArray, deleteItemFromArray } from 'utils/array';
import { showInfoToast } from 'utils/toast';
import { getProductText } from 'utils/product';
import {
  ProductAttributes,
  ProductFilter,
  ProductFilterObject,
  RegisterFormFields
} from 'models';
import {
  productRegistrationSelector,
  searchProductsToRegister,
  resetProductSearch,
  updateRegisterProducts,
  registerProducts,
  resetProductRegistration,
  productSearchSuccess,
  metaSelector
} from 'store';

import Heading, { Type } from 'components/UI/Heading';
import EditModal from 'components/UI/EditModal';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import SearchProductsField from './SearchProductsField';
import RegistrationForm from './RegistrationForm';

import { TableContainer } from './styled';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const headerCells: HeaderCell[] = [
  {
    sortable: false,
    intl: 'customers.product_table_title'
  },
  {
    sortable: false,
    intl: 'customers.product_table_pnc'
  },
  {
    sortable: false,
    intl: 'customers.product_table_serial'
  }
];

type Props = {
  onClose: () => void;
  open: boolean;
  customerId: string;
  onSuccess: () => void;
};

const RegisterProductModal: FC<Props> = ({
  onSuccess,
  onClose,
  open,
  customerId
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const meta = useAppSelector(metaSelector);
  const { search, register } = useAppSelector(productRegistrationSelector);

  // On register
  const onRegister = useCallback(
    (values: RegisterFormFields) => {
      const registerArray: ProductAttributes[][] = splitArray(
        register.products,
        3
      );

      registerArray.forEach((list) => {
        dispatch(
          registerProducts(
            {
              products: list.map((item) => ({
                pnc: item.pnc,
                serialNumber: item.serialNumber
              })),
              dealer: {
                companyCode: values.companyCode,
                customerNumber: values.customerNumber
              },
              soldDate: new Date(values.soldDate).valueOf(),
              customerId
            },
            intl.formatMessage({ id: 'modal.register_products_success' })
          )
        );
      });
    },
    [dispatch, customerId, register.products, intl]
  );

  // On search
  const onSearch = useCallback(
    (values: ProductFilterObject) => {
      const filters = createFilters<ProductFilter>(values);
      dispatch(searchProductsToRegister(filters));
    },
    [dispatch]
  );

  // Reset search
  const onReset = useCallback(() => {
    dispatch(resetProductSearch());
  }, [dispatch]);

  // Add register product
  const addRegisterProduct = useCallback(
    (product: ProductAttributes, index: number) => () => {
      if (!register.products.some((item) => item.iprId === product.iprId)) {
        dispatch(updateRegisterProducts([...register.products, product]));
        if (search.data) {
          const { array } = deleteItemFromArray(search.data, index);
          dispatch(productSearchSuccess(array));
        }
      } else {
        showInfoToast(
          intl.formatMessage({
            id: 'modal.register_products_already_added'
          })
        );
      }
    },
    [dispatch, search.data, register.products, intl]
  );

  // Remove register product
  const removeRegisterProduct = useCallback(
    (index: number) => () => {
      const { array, deleted } = deleteItemFromArray(register.products, index);
      dispatch(updateRegisterProducts(array));

      if (search.data) {
        dispatch(productSearchSuccess([...search.data, ...deleted]));
      }
    },
    [dispatch, search.data, register.products]
  );

  // Update on success
  useEffect(() => {
    if (register.success) {
      onSuccess();
    }
  }, [onSuccess, register.success]);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetProductRegistration());
    };
  }, [dispatch]);

  // Shared body cells
  const createProductBodyCells = (product: ProductAttributes) => ({
    product: {
      imageUrl: product.imageUrl,
      text: getProductText(product.brand, product.modelName)
    },
    pnc: product.pnc,
    serialNumber: product.serialNumber
  });

  // Render search results
  const searchTable = useMemo(() => {
    if (search.isLoading) {
      return <Loader center />;
    }
    if (!search.data) {
      return null;
    }

    // Table
    const bodyRows = search.data.map((product, i) => ({
      ...createProductBodyCells(product),
      addButton: (
        <IconButton onClick={addRegisterProduct(product, i)}>
          <Icon type={IconType.Add} themeType="primaryDark" />
        </IconButton>
      )
    }));

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="customer_details.products_empty"
      />
    );
  }, [addRegisterProduct, search.data, search.isLoading]);

  // Render search results
  const registerTable = useMemo(() => {
    if (register.isLoading) {
      return <Loader center />;
    }

    // Table
    const bodyRows = register.products.map((product, i) => ({
      ...createProductBodyCells(product),
      removeButton: (
        <IconButton onClick={removeRegisterProduct(i)}>
          <Icon type={IconType.Remove} />
        </IconButton>
      )
    }));

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="modal.register_products_empty_table"
      />
    );
  }, [register.isLoading, register.products, removeRegisterProduct]);

  if (meta.isLoading) {
    return <Loader center padding />;
  }

  return (
    <EditModal
      title="modal.register_products_title"
      open={open}
      close={onClose}
    >
      <div>
        <Heading type={Type.h3} uppercase>
          <FormattedMessage id="modal.register_products_search_title" />
        </Heading>
        <SearchProductsField onSearch={onSearch} onReset={onReset} />
        <TableContainer>{searchTable}</TableContainer>
        <Heading type={Type.h3} uppercase>
          <FormattedMessage id="modal.register_products_register_title" />
        </Heading>
        <TableContainer>{registerTable}</TableContainer>
        <RegistrationForm
          onClose={onClose}
          onRegister={onRegister}
          canRegister={Boolean(register.products.length)}
          salesCompanyCodes={meta.data.salesCompanyCodes.write}
        />
      </div>
    </EditModal>
  );
};

export default RegisterProductModal;
