import { useEffect } from 'react';

type Props = {
  /** The action to perform when the key is pressed. Should be memoized (e.g useCallback) for performance purposes */
  action: (event: KeyboardEvent) => void;
  /** If we should initialize the listener */
  addEventListener: boolean;
  /** The keyboard (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key) event keys that should trigger the provided `action`.*/
  eventKeys: KeyboardEvent['key'][];
};

export const useKeyEffect = ({
  action,
  addEventListener,
  eventKeys
}: Props) => {
  useEffect(() => {
    function clickEvent(event: KeyboardEvent) {
      if (eventKeys.includes(event.key)) {
        action(event);
      }
    }

    if (addEventListener) {
      window.addEventListener('keydown', clickEvent);
    }

    return () => {
      window.removeEventListener('keydown', clickEvent);
    };
  }, [action, addEventListener, eventKeys]);
};
