import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CustomerAttributes, CustomerUpdate, UpdateCustomer } from 'models';
import {
  getCustomerEmail,
  getCustomerName,
  getCustomerPhone
} from 'utils/customer';

import InputField from 'components/UI/InputField';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';

import { Description, ButtonGrid } from './styled';

type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type Props = {
  customer: CustomerAttributes;
  open: boolean;
  update: UpdateCustomer;
  onCustomerUpdate: (values: CustomerUpdate) => void;
  onClose: () => void;
};

const EditCustomerModal: FC<Props> = ({
  customer,
  open,
  update,
  onClose,
  onCustomerUpdate
}) => {
  // Refs
  const shouldClose = useRef(false);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<FormValues>({
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNumber: getCustomerPhone(customer)
    }
  });

  // Submit
  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (values) => {
      onCustomerUpdate({
        firstName: values.firstName,
        lastName: values.lastName,
        type: customer.type,
        eContact: {
          phoneContacts: [
            {
              phoneNumber: values.phoneNumber,
              isVerified: false
            }
          ]
        }
      });
    },
    [onCustomerUpdate, customer]
  );

  // Close modal on success
  useEffect(() => {
    if (update.isSuccess) {
      if (shouldClose.current) {
        onClose();
        shouldClose.current = false;
      }
    } else {
      shouldClose.current = true;
    }
  }, [onClose, update.isSuccess]);

  // Render data
  const formData = useMemo(() => {
    if (update.isLoading) {
      return <Loader padding center />;
    }
    return (
      <Fragment>
        <InputField
          description="modal.update_customer_input_firstname"
          register={register('firstName', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.firstName}
        />
        <InputField
          description="modal.update_customer_input_lastname"
          register={register('lastName', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.lastName}
        />
        <InputField
          description="modal.update_customer_input_phonenumber"
          register={register('phoneNumber', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          error={errors.phoneNumber}
        />
      </Fragment>
    );
  }, [register, update, errors]);

  return (
    <EditModal title="modal.update_customer_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Description>
          <FormattedMessage
            id="modal.update_customer_text"
            values={{
              name: getCustomerName(customer),
              email: getCustomerEmail(customer)
            }}
          />
        </Description>
        {formData}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || update.isLoading} submit>
            <FormattedMessage id="modal.btn_update" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditCustomerModal;
