import styled, { DefaultTheme } from 'styled-components';

type Props = {
  theme: DefaultTheme;
  $isActive?: boolean;
};

export const SearchContainer = styled.div<Props>`
  position: relative;
`;

export const SearchField = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const SearchIcon = styled.div`
  position: absolute;
  display: flex;
  top: 13px;
  left: 16px;
`;

export const SearchInput = styled.input<Props>`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 24px;
  border: solid 1px ${({ theme }) => theme.colors.grey3};
  border-radius: ${({ $isActive }) => ($isActive ? '16px 16px 0 0' : '16px')};
  padding: 12px 16px 12px 48px;
  outline: none;

  &:disabled {
    opacity: 70%;
  }
`;

export const SearchArea = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  top: 13px;
  right: 16px;
`;

export const SearchButton = styled.button`
  display: flex;
  background: ${({ theme }) => theme.colors.grey2};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 4px;
`;

export const SearchResults = styled.ul<Props>`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  max-height: 300px;
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
  background: ${({ theme }) => theme.colors.surface};
  border: solid 1px ${({ theme }) => theme.colors.grey3};
  border-radius: ${({ $isActive }) => ($isActive ? '16px 16px 0 0' : '16px')};
  padding: 0;
  margin: 0;
  border-radius: 0 0 16px 16px;
  overflow-y: auto;
  z-index: 99;
`;

export const SearchResultItem = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 24px;
  color: ${({ theme }) => theme.colors.onSurface};
  padding: 14px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey2};
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.grey6};
    font-size: 14px;
    line-height: 16px;
  }
`;

export const EmptySearchResults = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.onSurface};
  padding: 14px 16px;

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
`;
