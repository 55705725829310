import { FC, ReactNode } from 'react';
import Icon, { IconType } from 'components/UI/Icon';
import { ThemeColor } from 'styles';

import { IconWrapper, StyledTag } from './styled';

type Props = {
  children: ReactNode;
  onRemove?: () => void;
  color?: ThemeColor;
  background?: ThemeColor;
};

const Tag: FC<Props> = ({
  children,
  onRemove,
  color = 'grey6',
  background = 'grey3'
}) => {
  return (
    <StyledTag $color={color} $background={background}>
      {children}
      {onRemove && (
        <IconWrapper onClick={onRemove}>
          <Icon type={IconType.Remove} themeType="grey6" size="small" />
        </IconWrapper>
      )}
    </StyledTag>
  );
};

export default Tag;
