import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'router/Routes';
import { User } from 'models/user';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Icon, { IconType } from 'components/UI/Icon';
import Tag from 'components/UI/Tag';

import { TagGrid } from 'styles';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'users.user_table_email'
  },
  {
    sortable: true,
    intl: 'users.user_table_name'
  },
  {
    sortable: false,
    intl: 'users.user_table_groups'
  },
  {
    sortable: false,
    intl: 'users.user_table_roles'
  },
  {
    sortable: false,
    intl: 'users.user_table_status'
  },
  {
    sortable: true,
    intl: 'users.user_table_country'
  }
];

type Props = {
  users: User[];
};

const UserTable: FC<Props> = ({ users }) => {
  const navigate = useNavigate();

  // On click
  const onClick = useCallback(
    (userId: string) => navigate(routes.userDetailsLink(userId)),
    [navigate]
  );

  // Body
  const bodyRows = useMemo(
    () =>
      users.map(({ id, attributes }) => {
        const {
          username,
          firstName,
          lastName,
          groups,
          roles,
          disabled,
          country
        } = attributes;

        return {
          email: username || '-',
          name: firstName && lastName ? `${firstName} ${lastName}` : '-',
          groups: (
            <TagGrid>
              {groups.length
                ? groups.map((group) => <Tag key={group}>{group}</Tag>)
                : '–'}
            </TagGrid>
          ),
          roles: (
            <TagGrid>
              {roles.length
                ? roles.map((role) => <Tag key={role}>{role}</Tag>)
                : '–'}
            </TagGrid>
          ),
          status: (
            <Icon
              type={disabled ? IconType.Inactive : IconType.Active}
              themeType={disabled ? 'error' : 'success'}
            />
          ),
          country: country || '-',
          link: id
        };
      }),
    [users]
  );

  return (
    <SortableTable
      headerCells={headerCells}
      bodyRows={bodyRows}
      onClick={onClick}
    />
  );
};

export default UserTable;
