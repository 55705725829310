import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ProductAttributes,
  ProductDetailsResponse,
  ProductFilter,
  ProductState,
  APIError
} from 'models';
import {
  findProductByIprId,
  findFilteredProducts,
  getFactoryPinByIprId
} from 'services';
import type { RootState } from 'store/store';

export const defaultState: ProductState = {
  list: {
    data: null,
    isLoading: false,
    error: null,
    filters: {
      active: false
    }
  },
  details: {
    data: null,
    dealer: null,
    customers: [],
    relations: [],
    contracts: [],
    warranties: [],
    isLoading: false,
    error: null
  },
  factoryPin: {
    data: null,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const productSlice = createSlice({
  name: 'product',
  initialState: defaultState,
  reducers: {
    getProductList: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    getProductListSuccess: (
      state,
      action: PayloadAction<ProductAttributes[]>
    ) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    getProductListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    setProductFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    getProduct: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getProductSuccess: (
      state,
      action: PayloadAction<ProductDetailsResponse>
    ) => {
      state.details.isLoading = false;
      state.details.data = action.payload.product;
      state.details.dealer = action.payload.dealer;
      state.details.customers = action.payload.customers;
      state.details.contracts = action.payload.contracts;
      state.details.warranties = action.payload.warranties;
      state.details.relations = action.payload.relations;
    },
    getProductFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.error = action.payload;
    },
    viewFactoryPinInit: (state) => {
      state.factoryPin.data = null;
      state.factoryPin.isLoading = true;
      state.factoryPin.isError = false;
      state.factoryPin.error = null;
    },
    viewFactoryPinSuccess: (state, action: PayloadAction<string>) => {
      state.factoryPin.data = action.payload;
      state.factoryPin.isLoading = false;
      state.factoryPin.isError = false;
      state.factoryPin.error = null;
    },
    viewFactoryPinFailure: (state, action: PayloadAction<APIError>) => {
      state.factoryPin.data = null;
      state.factoryPin.isLoading = false;
      state.factoryPin.isError = true;
      state.factoryPin.error = action.payload;
    },
    resetViewFactoryPinNumber: (state) => {
      state.factoryPin = defaultState.factoryPin;
    }
  }
});

// Export selectors
export const productListSelector = (state: RootState) => state.product.list;
export const productDetailsSelector = (state: RootState) =>
  state.product.details;
export const viewFactoryPinSelector = (state: RootState) =>
  state.product.factoryPin;

// Export actions
export const {
  getProductList,
  getProductListSuccess,
  getProductListFailure,
  setProductFilters,
  getProduct,
  getProductSuccess,
  getProductFailure,
  viewFactoryPinInit,
  viewFactoryPinSuccess,
  viewFactoryPinFailure,
  resetViewFactoryPinNumber
} = productSlice.actions;

// Export reducer
export const productReducer = productSlice.reducer;

// Export thunk
export function fetchProductList(productFilters: ProductFilter[]) {
  return async (dispatch: Dispatch) => {
    dispatch(getProductList());
    try {
      const response = await findFilteredProducts(productFilters);
      dispatch(getProductListSuccess(response.data.attributes.products));
    } catch (e: any) {
      dispatch(getProductListFailure(e));
    }
  };
}

export function fetchProductByIprId(iprId: string, languageCode?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getProduct());
    try {
      const response = await findProductByIprId(iprId, languageCode);
      dispatch(getProductSuccess(response));
    } catch (e: any) {
      dispatch(getProductFailure(e));
    }
  };
}

export function fetchFactoryPin(iprId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(viewFactoryPinInit());
    try {
      const response = await getFactoryPinByIprId(iprId);
      dispatch(viewFactoryPinSuccess(response.data.attributes.factorypin));
    } catch (e: any) {
      dispatch(viewFactoryPinFailure(e));
    }
  };
}
