import {
  FC,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { useKeyEffect } from 'hooks/useKeyEffect';
import { getNextListIndex } from 'utils/array';

import Icon, { IconType } from 'components/UI/Icon';
import ArrowIcon from 'components/UI/Icon/ArrowIcon';

import {
  Container,
  SelectContainer,
  Select,
  SelectGroup,
  SelectInput,
  SelectLabel,
  SelectButton,
  Options,
  Option,
  Mark,
  Message
} from './styled';

export type OptionType = {
  key: string;
  value: string;
  label: string;
};

type Props = {
  name: string;
  label: string;
  options: OptionType[];
  onSelect: (value: OptionType) => void;
  register?: any;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  message?: string;
  disabled?: boolean;
  noBorder?: boolean;
  error?: any;
  enableSearch?: boolean;
  optional?: boolean;
  tight?: boolean;
};

export const SelectField: FC<Props> = ({
  name,
  label,
  options,
  onSelect,
  register,
  placeholder,
  defaultValue,
  value,
  message,
  error,
  enableSearch = false,
  optional = false,
  tight = false,
  disabled = false,
  noBorder = false
}) => {
  const intl = useIntl();

  // Refs
  const selectRef = useRef<HTMLDivElement>(null);
  const floatInputRef = useRef<HTMLInputElement>(null);

  // State
  const [selected, setSelected] = useState<OptionType | null>(null);
  const [highlighted, setHighlighted] = useState<OptionType | null>(null);
  const [searchState, setSearchState] = useState<string>('');

  // Hooks
  const [isOpen, setIsOpen] = useDetectOutsideClick([selectRef], false);

  // Empty list placeholder
  const placeholderOption = useMemo<OptionType | null>(() => {
    if (!placeholder) {
      return null;
    }
    return {
      value: '',
      label: placeholder || '',
      key: ''
    };
  }, [placeholder]);

  // Full list
  const fullList = useMemo(() => {
    if (!placeholderOption) {
      return options;
    }
    return [placeholderOption, ...options];
  }, [options, placeholderOption]);

  // Actions
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchState(event.target.value),
    []
  );

  // Set default value after rendering component
  useEffect(() => {
    const defaultOption = fullList.find((item) => item.value === defaultValue);

    if (defaultOption) {
      setSelected(defaultOption);
    } else if (placeholderOption) {
      setSelected(placeholderOption);
    }
  }, [defaultValue, placeholderOption, fullList]);

  // Update value
  useEffect(() => {
    const found = fullList.find((item) => item.value === value);

    if (found) {
      setSelected(found);
    }
  }, [value, fullList]);

  // Set focus and search state when activated or deactivated
  useEffect(() => {
    if (!floatInputRef.current) {
      return;
    }

    if (!isOpen) {
      setSearchState('');
      floatInputRef.current.blur();
    } else {
      floatInputRef.current.focus();
    }
  }, [isOpen]);

  // Filtered list by search term
  const list = useMemo(() => {
    if (!searchState) {
      return fullList;
    }

    return fullList.filter((item) => {
      const lowerCaseLabel = item.label.toLowerCase();
      const lowerCaseSearch = searchState.toLowerCase();
      return lowerCaseLabel.includes(lowerCaseSearch);
    });
  }, [fullList, searchState]);

  // Set selected option, call onSelectValue and close dropdown
  const onSelectOption = useCallback(
    (option: OptionType | null) => {
      if (!option) {
        return null;
      }
      setSelected(option);
      onSelect(option);
      onClose();
    },
    [onClose, onSelect]
  );

  // Highlight and scroll to the selected option's element
  const highlightOption = useCallback(
    (option: OptionType | null) => {
      if (!option) {
        return null;
      }
      setHighlighted(option);
      const highlightedElement = selectRef.current?.querySelector<HTMLElement>(
        `#${name}${option.key}`
      );
      highlightedElement?.scrollIntoView({ block: 'nearest' });
    },
    [name]
  );

  // Handle keypresses within dropdown element
  const onEnter = useCallback(
    () => onSelectOption(highlighted),
    [highlighted, onSelectOption]
  );

  // On arrow click
  const onArrow = useCallback(
    (direction: 'up' | 'down') => (event: KeyboardEvent) => {
      event.preventDefault();

      const nextIndex = getNextListIndex(list, highlighted, direction === 'up');
      const nextOption = list[nextIndex];
      highlightOption(nextOption);
    },
    [highlightOption, highlighted, list]
  );

  // Keyboard navigation within dropdown
  useKeyEffect({
    action: onClose,
    addEventListener: isOpen,
    eventKeys: ['Escape', 'Tab']
  });

  useKeyEffect({
    action: onEnter,
    addEventListener: isOpen,
    eventKeys: ['Enter']
  });

  useKeyEffect({
    action: onArrow('down'),
    addEventListener: isOpen,
    eventKeys: ['ArrowDown']
  });

  useKeyEffect({
    action: onArrow('up'),
    addEventListener: isOpen,
    eventKeys: ['ArrowUp']
  });

  // Render option with highlight based on current search phrase
  const renderOptionLabel = useCallback(
    (optionLabel: string) => {
      if (!searchState) {
        return optionLabel;
      }

      const lowerCaseLabel = optionLabel.toLowerCase();
      const startIndex = lowerCaseLabel.indexOf(searchState);
      const endIndex = startIndex + searchState.length;

      return (
        <p>
          {optionLabel.substring(0, startIndex)}
          <Mark>{optionLabel.substring(startIndex, endIndex)}</Mark>
          {optionLabel.substring(endIndex)}
        </p>
      );
    },
    [searchState]
  );

  // Empty list
  const emptyList = useMemo(() => {
    if (list.length) {
      return null;
    }
    return (
      <Option>
        <FormattedMessage id="select.no_match" />
      </Option>
    );
  }, [list]);

  const minimizeLabel = useMemo(() => {
    if (isOpen) {
      return enableSearch;
    }
    return selected !== null;
  }, [enableSearch, selected, isOpen]);

  // Message
  const messageText = useMemo(() => {
    if (error && message) {
      return `${message} (${error.message})`;
    }
    if (error) {
      return error.message;
    }
    if (message) {
      return message;
    }
    return null;
  }, [message, error]);

  return (
    <Container>
      <SelectContainer $isTight={tight}>
        <Select
          ref={selectRef}
          $isOpen={isOpen}
          $isError={!!error}
          $noBorder={noBorder}
          disabled={disabled}
        >
          <SelectGroup $isOpen={isOpen} disabled={disabled}>
            <input type="hidden" readOnly autoComplete="off" {...register} />
            <SelectInput
              id={name}
              ref={floatInputRef}
              value={isOpen ? searchState : selected?.label ?? ''}
              onChange={onChange}
              onFocus={onOpen}
              autoComplete="off"
              readOnly={!enableSearch}
              disabled={disabled}
              $isTight={tight}
            />
            <SelectLabel
              htmlFor={name}
              $isError={!!error}
              $minimize={minimizeLabel}
              $isTight={tight}
              disabled={disabled}
            >
              {label}{' '}
              {optional && ` (${intl.formatMessage({ id: 'misc.optional' })})`}
            </SelectLabel>
            <SelectButton
              type="button"
              disabled={disabled}
              onClick={isOpen ? onClose : onOpen}
              tabIndex={-1}
            >
              <ArrowIcon
                direction={isOpen ? 'up' : 'down'}
                themeType={disabled ? 'grey4' : 'onBackground'}
              />
            </SelectButton>
          </SelectGroup>
          <Options $isOpen={isOpen}>
            {emptyList}
            {list.map((item) => (
              <Option
                key={item.key}
                id={`${name}${item.key}`}
                $isHighlighted={highlighted?.key === item.key}
                onClick={() => onSelectOption(item)}
              >
                {renderOptionLabel(item.label)}
                {selected?.key === item.key && (
                  <Icon type={IconType.Check} themeType="primary" />
                )}
              </Option>
            ))}
          </Options>
        </Select>
      </SelectContainer>
      <Message $isHidden={tight} $isError={!!error} disabled={disabled}>
        {messageText}
      </Message>
    </Container>
  );
};

export default SelectField;
