import styled from 'styled-components';
import { ThemeColor } from 'styles';
import { setColor } from '../styled';

export type StyleProps = {
  $themeType?: ThemeColor;
  $color: string;
};

export const StyledGroup = styled.g<StyleProps>`
  fill: ${({ theme, $themeType, $color }) =>
    setColor(theme, $color, $themeType)}};
`;

export const StyledPath = styled.path<StyleProps>`
  fill: ${({ theme, $themeType, $color }) =>
    setColor(theme, $color, $themeType)}};
`;
