import { FC, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Heading from 'components/UI/Heading';
import ErrorState from 'components/UI/ErrorState';
import Button from 'components/UI/Button';

import { ButtonGrid, Text } from './styled';

type FallbackProps = {
  resetError: () => void;
};

const FallbackError: FC<FallbackProps> = ({ resetError }) => {
  // Reset
  const onReset = useCallback(() => resetError(), [resetError]);

  return (
    <ErrorState padding>
      <Heading>
        <FormattedMessage id="misc.crash_title" />
      </Heading>
      <Text>
        <FormattedMessage id="misc.crash_description" />
      </Text>
      <ButtonGrid>
        <Button onClick={onReset}>
          <FormattedMessage id="misc.crash_btn" />
        </Button>
      </ButtonGrid>
    </ErrorState>
  );
};

function fallBack(props: FallbackProps) {
  return <FallbackError resetError={props.resetError} />;
}

const ErrorBoundary: FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={fallBack} showDialog>
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
