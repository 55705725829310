import styled from 'styled-components';
import { ThemeColor } from 'styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LegendGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 24px;
`;

export const LegendCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type LegendIconProps = {
  $color: ThemeColor;
};

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LegendItemIcon = styled.div<LegendIconProps>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: ${({ theme, $color }) => theme.colors[$color]};
`;

export const LegendItemText = styled.p`
  margin: 0;
`;

export const LegendDataCol = styled(LegendCol)`
  gap: 24px;
`;
