import { APIError, FilterObject, FilterParam, JsonData } from 'models';
import { refreshToken } from 'services';
import { store, logout } from 'store';
import { authExpired, authRateLimited } from 'store/authState/authState';
import * as asyncLock from 'utils/asyncLock';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

function extractJsonApiError(json: JsonData): APIError {
  if (Array.isArray(json.errors)) {
    // Assume JSON:API standard error
    return json.errors[0];
  }

  console.warn('Unknown error response:', json);
  throw new Error(json.message);
}

export async function apiFetch<T>(
  url: string,
  options: RequestInit = {},
  attempt = 1
): Promise<T> {
  // Fetch
  const res = await fetch(url, {
    credentials: 'include',
    headers: defaultHeaders,
    ...options
  });

  const text = await res.text();

  // No response body
  if (!text) {
    return null as unknown as T;
  }

  // JSON
  const json = await JSON.parse(text);

  // Success
  if (res.ok) {
    return json;
  }

  console.warn(
    'Failed - statusText: %s, status: %d',
    res.statusText,
    res.status
  );

  // Extract error
  const apiError = extractJsonApiError(json);

  // Error types
  const expiredTokenError =
    apiError.status === 401 && apiError.code === 'expired.token';
  const invalidTokenError =
    apiError.status === 401 && apiError.code === 'invalid.token';
  const rateLimitError =
    apiError.status === 429 && apiError.code === 'rate.limit';

  // Log out on invalid token error or on expired token error after one refetch attempt
  if (invalidTokenError || (expiredTokenError && attempt > 1)) {
    store.dispatch(authExpired());
    store.dispatch(logout(false));
  }

  // Log out on rate limit error
  if (rateLimitError) {
    store.dispatch(authRateLimited());
    store.dispatch(logout(false));
  }

  // Throw error if not expired token error
  if (!expiredTokenError) {
    throw apiError;
  }

  // Acquire lock and continue on the first request, or wait for the lock to be released
  await asyncLock.acquireLock();

  // Try refreshing the session and release the lock
  if (asyncLock.isLocked()) {
    await refreshToken();
    asyncLock.releaseLock();
  }

  // Retry the request
  return apiFetch(url, options, attempt + 1);
}

export function createFilterParams(
  url: string,
  filters: FilterParam[]
): string {
  // Add filters
  filters.forEach(({ attribute, value }, i) => {
    if (i === 0) {
      url += '?';
    }

    url += `filter[${attribute}]=${encodeURIComponent(value)}`;

    if (filters.length !== i + 1) {
      url += '&';
    }
  });
  return url;
}

export function createFilterUrl<T extends Record<string, string | null>>(
  url: string,
  filterObj: T
): string {
  const filters = Object.entries(filterObj).filter((item) => item[1]?.length);

  filters.forEach(([key, value], i) => {
    if (i === 0) {
      url += '?';
    }

    url += `filter[${key}]=${encodeURIComponent(String(value))}`;

    if (filters.length !== i + 1) {
      url += '&';
    }
  });

  return url;
}

export function getFilterObject(filters: FilterParam[]): FilterObject {
  return filters.reduce<FilterObject>((obj, item) => {
    obj[item.attribute] = item.value;
    return obj;
  }, {});
}
