import styled from 'styled-components';

export const Description = styled.div`
  margin-bottom: 24px;
`;

export const ButtonGrid = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 200px;
  }
`;
