import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 120px;
  }
`;
