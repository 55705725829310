import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { Language, LocalizationState } from 'models';
import type { RootState } from 'store/store';

import en from 'assets/languages/compiled/en.json';
import sv from 'assets/languages/compiled/sv.json';
import da from 'assets/languages/compiled/da.json';
import de from 'assets/languages/compiled/de.json';

export const defaultState: LocalizationState = {
  locale: Language.EN,
  languages: [Language.EN, Language.SV, Language.DA, Language.DE],
  messages: { en, sv, da, de }
};

// Export slice
export const localizationSlice = createSlice({
  name: 'localization',
  initialState: defaultState,
  reducers: {
    setLocale: (state, action: PayloadAction<Language>) => {
      localStorage.setItem('locale', action.payload);
      state.locale = action.payload;
    }
  }
});

// Export selectors
export const localizationSelector = (state: RootState) => state.localization;

// Export actions
export const { setLocale } = localizationSlice.actions;

// Export reducer
export const localizationReducer = localizationSlice.reducer;

// Thunks
export const getLocale = () => async (dispatch: Dispatch) => {
  const locale = localStorage.getItem('locale');
  if (locale) {
    dispatch(setLocale(locale as Language));
  }
};
