import { APIError } from 'models/errors';

export type AuditLogAttributes = {
  customerId?: string;
  operationType: string;
  operationSubtype?: string;
  dataBefore: object | null;
  dataAfter: object | null;
  createdDate: number;
  description: string;
  iprId?: string;
  userId: string;
  userEmail: string;
  userFullName: string;
};

export type AuditLog = {
  type: string;
  attributes: AuditLogAttributes;
};

export enum AuditLogAttribute {
  CustomerId = 'customerId',
  IprId = 'iprId',
  UserId = 'userId',
  ServiceContractId = 'serviceContractId'
}

export type AuditLogFilter = {
  attribute: AuditLogAttribute;
  value: string;
};

export interface AuditLogResponse {
  data: AuditLog[];
}

export type EmailBounceAttributes = {
  userId: string;
  username: string;
  bounceType: string;
  bounceSubtype: string;
  timestamp: string;
  diagnosticCode: string;
  data: object;
};

export type EmailBounce = {
  id: string;
  type: string;
  attributes: EmailBounceAttributes;
};

export interface EmailBounceResponse {
  data: EmailBounce[];
}

export type AuditLogs = {
  data: AuditLog[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type EmailBounces = {
  data: EmailBounce[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type AuditState = {
  auditLogs: AuditLogs;
  emailBounces: EmailBounces;
};
