import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import ArrowIcon from '../Icon/ArrowIcon';

import {
  Container,
  Column,
  CenterColumn,
  InnerButton,
  Text,
  CenterText,
  SubCenterText
} from './styled';

export type PagePagination = {
  limit: number;
  offset: number;
  page: number;
};

type Props = {
  pagination: PagePagination;
  setPagination: (data: PagePagination) => void;
  noItems: number;
};

const Pagination: FC<Props> = ({ pagination, setPagination, noItems }) => {
  const { page, limit, offset } = pagination;
  const isFirst = page === 1;
  const isLast = noItems < limit;

  // Previous
  const onPrev = () =>
    setPagination({
      ...pagination,
      page: page - 1,
      offset: offset - limit
    });

  // Next
  const onNext = () =>
    setPagination({
      ...pagination,
      page: page + 1,
      offset: offset + limit
    });

  return (
    <Container>
      <Column>
        <div>
          {!isFirst && (
            <Button
              onClick={onPrev}
              backgroundColor="transparent"
              color="primary"
            >
              <InnerButton>
                <ArrowIcon
                  size="small"
                  color="#f35321"
                  direction="left"
                  transition={false}
                />
                <Text>
                  <FormattedMessage id="pagination.previous" />
                </Text>
              </InnerButton>
            </Button>
          )}
        </div>
      </Column>
      <CenterColumn>
        <div>
          <CenterText>
            <FormattedMessage id="pagination.page" /> {page}
          </CenterText>
          <SubCenterText>
            <FormattedMessage
              id="pagination.items"
              values={{ value: noItems }}
            />
          </SubCenterText>
        </div>
      </CenterColumn>
      <Column $right>
        <div>
          {!isLast && (
            <Button
              onClick={onNext}
              backgroundColor="transparent"
              color="primary"
            >
              <InnerButton>
                <Text>
                  <FormattedMessage id="pagination.next" />
                </Text>
                <ArrowIcon
                  size="small"
                  color="#f35321"
                  direction="right"
                  transition={false}
                />
              </InnerButton>
            </Button>
          )}
        </div>
      </Column>
    </Container>
  );
};

export default Pagination;
