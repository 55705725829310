import { FC, ReactNode } from 'react';
import { Root } from './styled';

type Props = {
  children?: ReactNode;
};

const TableRow: FC<Props> = ({ children }) => {
  return <Root>{children}</Root>;
};

export default TableRow;
