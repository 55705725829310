import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ProductAttributes,
  ProductFilter,
  APIError,
  ProductRegistrationState,
  RegisterProductsCustomer
} from 'models';
import { findFilteredProducts, registerProductsCustomer } from 'services';
import { showInfoToast, showSuccessToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: ProductRegistrationState = {
  search: {
    data: null,
    isLoading: false,
    error: null
  },
  register: {
    products: [],
    isLoading: false,
    success: null,
    error: null
  }
};

// Export slice
export const productRegistrationSlice = createSlice({
  name: 'product-registration',
  initialState: defaultState,
  reducers: {
    resetProductSearch: (state) => {
      state.search = defaultState.search;
    },
    productSearch: (state) => {
      state.search.isLoading = true;
      state.search.error = null;
    },
    productSearchSuccess: (
      state,
      action: PayloadAction<ProductAttributes[]>
    ) => {
      state.search.isLoading = false;
      state.search.data = action.payload;
    },
    productSearchFailure: (state, action: PayloadAction<APIError>) => {
      state.search.data = null;
      state.search.isLoading = false;
      state.search.error = action.payload;
    },
    updateRegisterProducts: (
      state,
      action: PayloadAction<ProductAttributes[]>
    ) => {
      state.register.products = action.payload;
    },
    productRegistration: (state) => {
      state.register.isLoading = true;
      state.register.error = null;
    },
    productRegistrationSuccess: (state) => {
      state.register.products = [];
      state.register.isLoading = false;
      state.register.success = Date.now();
    },
    productRegistrationFailure: (state, action: PayloadAction<APIError>) => {
      state.register.isLoading = false;
      state.register.error = action.payload;
    },
    resetProductRegistration: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const productRegistrationSelector = (state: RootState) =>
  state.productRegistration;

// Export actions
export const {
  resetProductSearch,
  productSearch,
  productSearchSuccess,
  productSearchFailure,
  updateRegisterProducts,
  productRegistration,
  productRegistrationSuccess,
  productRegistrationFailure,
  resetProductRegistration
} = productRegistrationSlice.actions;

// Export reducer
export const productRegistrationReducer = productRegistrationSlice.reducer;

// Export thunk
export const searchProductsToRegister =
  (productFilters: ProductFilter[]) => async (dispatch: Dispatch) => {
    dispatch(productSearch());
    try {
      const response = await findFilteredProducts(productFilters);
      dispatch(productSearchSuccess(response.data.attributes.products));
    } catch (e: any) {
      dispatch(productSearchFailure(e));
      showInfoToast(e.title);
    }
  };

export const registerProducts =
  (data: RegisterProductsCustomer, successMessage: string) =>
  async (dispatch: Dispatch) => {
    dispatch(productRegistration());
    try {
      await registerProductsCustomer(data);
      dispatch(productRegistrationSuccess());
      showSuccessToast(successMessage);
    } catch (e: any) {
      dispatch(productRegistrationFailure(e));
      showInfoToast(e.title);
    }
  };
