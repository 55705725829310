import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { UserFilter, UserFilterData } from 'models';
import countries from 'assets/data/countries.json';
import languages from 'assets/data/languages.json';

import Button from 'components/UI/Button';
import SelectField, { OptionType } from 'components/UI/SelectField';

import { TwoColumnGrid, ButtonGrid } from 'styles';

type Props = {
  onFilter: (filters: Partial<UserFilterData>) => void;
  onClear: (filters: Partial<UserFilterData>) => void;
};

const UserFilters: FC<Props> = ({ onFilter, onClear }) => {
  const intl = useIntl();

  // Form
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  // Watch
  const [country, language] = watch([UserFilter.Country, UserFilter.Language]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(onFilter, [
    onFilter
  ]);

  // Select
  const onSelect = useCallback(
    (name: UserFilter) => (option: OptionType) => setValue(name, option.value),
    [setValue]
  );

  // Reset
  const onReset = useCallback(() => {
    const empty = {
      country: '',
      language: ''
    };
    onClear(empty);
    reset(empty);
  }, [onClear, reset]);

  // Options
  const countryOptions = useMemo(() => {
    return countries.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  const languageOptions = useMemo(() => {
    return languages.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TwoColumnGrid>
        <SelectField
          value={country}
          name={UserFilter.Country}
          register={register(UserFilter.Country)}
          onSelect={onSelect(UserFilter.Country)}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'users.input_country' })}
          options={countryOptions}
          error={errors.country}
          enableSearch
        />
        <SelectField
          value={language}
          name={UserFilter.Language}
          register={register(UserFilter.Language)}
          onSelect={onSelect(UserFilter.Language)}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'users.input_language' })}
          options={languageOptions}
          error={errors.language}
          enableSearch
        />
      </TwoColumnGrid>
      <ButtonGrid>
        <Button
          color="primary"
          backgroundColor="transparent"
          onClick={onReset}
          marginRight
        >
          <FormattedMessage id="filters.btn_clear" />
        </Button>
        <Button submit>
          <FormattedMessage id="filters.btn_submit" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default UserFilters;
