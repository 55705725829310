import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  @media (max-width: ${breakpoints.s}px) {
    gap: 16px;
  }
`;

export const Image = styled.img`
  height: 250px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;

  @media (max-width: ${breakpoints.m}px) {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.grey9};
  text-transform: capitalize;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

export const DetailGroup = styled.span`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;
