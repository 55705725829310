import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import ArrowIcon from 'components/UI/Icon/ArrowIcon';

import {
  Root,
  Divider,
  ContentContainer,
  Title,
  NavButtonContainer,
  LeftColumn,
  RightColumn
} from './styled';

type Props = {
  children?: ReactNode;
  divider?: boolean;
  title?: string;
  intl?: string;
  navUrl?: string;
};

const CardHeader: FC<Props> = ({
  children,
  divider = false,
  title,
  intl,
  navUrl
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // Back
  const onBack = useCallback(() => {
    if (navUrl) {
      navigate(state?.from ?? navUrl);
    }
  }, [navigate, state, navUrl]);

  // Title
  const cardTitle = useMemo(() => {
    if (navUrl) {
      return (
        <NavButtonContainer onClick={onBack}>
          <ArrowIcon direction="left" themeType="grey5" />
          <Title>
            <FormattedMessage id={state?.from ? 'misc.back' : intl} />
          </Title>
        </NavButtonContainer>
      );
    }

    if (intl) {
      return (
        <Title>
          <FormattedMessage id={intl} />
        </Title>
      );
    }

    return <Title>{title}</Title>;
  }, [navUrl, state, title, intl, onBack]);

  return (
    <Root $bottomMargin={divider}>
      <ContentContainer>
        <LeftColumn>{cardTitle}</LeftColumn>
        <RightColumn>{children}</RightColumn>
      </ContentContainer>
      {divider && <Divider />}
    </Root>
  );
};

export default CardHeader;
