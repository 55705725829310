import { StatusColor } from 'models';

export function getConnectivityStatusColors(status?: string): StatusColor {
  switch (status) {
    case 'in_sync':
    case 'connected':
    case 'finished':
      return {
        background: 'success',
        color: 'onPrimary'
      };
    case 'registered':
    case 'disconnected':
      return {
        background: 'info',
        color: 'onPrimary'
      };
    case 'pending':
      return {
        background: 'warning',
        color: 'onWarning'
      };
    case 'error':
      return {
        background: 'error',
        color: 'onPrimary'
      };
    default:
      return {
        background: 'grey3',
        color: 'grey6'
      };
  }
}
