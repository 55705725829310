import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { findServicePlans } from 'services';
import {
  APIError,
  ServicePlanState,
  ServicePlan,
  ServicePlanParams
} from 'models';
import type { RootState } from 'store/store';

export const defaultState: ServicePlanState = {
  data: null,
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: null
};

// Export slice
export const servicePlanSlice = createSlice({
  name: 'service-plan',
  initialState: defaultState,
  reducers: {
    getServicePlans: (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    },
    getServicePlansSuccess: (state, action: PayloadAction<ServicePlan>) => {
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
    },
    getServicePlansFailure: (state, action: PayloadAction<APIError>) => {
      state.isError = true;
      state.isLoading = false;
      state.error = action.payload;
    },
    resetServicePlanState: () => defaultState
  }
});

// Export selectors
export const servicePlanSelector = (state: RootState) => state.servicePlan;

// Export actions
export const {
  getServicePlans,
  getServicePlansSuccess,
  getServicePlansFailure,
  resetServicePlanState
} = servicePlanSlice.actions;

// Export reducer
export const servicePlanReducer = servicePlanSlice.reducer;

// Export thunk
export function fetchServicePlans(params: ServicePlanParams) {
  return async (dispatch: Dispatch) => {
    dispatch(getServicePlans());
    try {
      const response = await findServicePlans(params);
      dispatch(getServicePlansSuccess(response));
    } catch (e: any) {
      dispatch(getServicePlansFailure(e));
    }
  };
}
