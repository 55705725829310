import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Steps, StepItem, Indicator } from './styled';

export type StepItem = {
  id: number;
  intl?: string;
  text?: string;
};

type Props = {
  activeIndex: number;
  steps: StepItem[];
};

const Stepper: FC<Props> = ({ steps, activeIndex }) => {
  return (
    <Steps>
      {steps.map((step, i) => (
        <StepItem key={step.id}>
          <h4>
            <FormattedMessage id="misc.step" values={{ step: i + 1 }} />
          </h4>
          <p>{step.intl ? <FormattedMessage id={step.intl} /> : step.text}</p>
          <Indicator $isActive={i <= activeIndex} />
        </StepItem>
      ))}
    </Steps>
  );
};

export default Stepper;
