import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  fetchProductList,
  productListSelector,
  setProductFilters
} from 'store';

import { ProductFilter } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { defineProductFilterAttribute } from 'utils/filtering';

import Card from 'components/UI/Card';
import FoldableCard from 'components/UI/FoldableCard';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import SearchField from 'components/UI/SearchField';
import Loader from 'components/UI/Loader';

import ProductTable from 'components/products/ProductTable';
import ProductFilters from 'components/products/ProductFilters';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';

const ProductSearchPage: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { data, isLoading, filters } = useAppSelector(productListSelector);

  // Toggle filters
  const toggleFilters = useCallback(() => {
    dispatch(setProductFilters(!filters.active));
  }, [dispatch, filters.active]);

  // Fetch contracts
  const fetchProducts = useCallback(
    (productFilters: ProductFilter[]) => {
      dispatch(fetchProductList(productFilters));
    },
    [dispatch]
  );

  // Search
  const onSearch = useCallback(
    (searchValue: string) => {
      fetchProducts(
        searchValue
          .split(/[ +]+/)
          .map((item) => defineProductFilterAttribute(item))
      );
    },
    [fetchProducts]
  );

  // Render results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    if (!data) {
      return null;
    }
    if (!data.length) {
      return (
        <EmptyState icon={IconType.Product} padding>
          <FormattedMessage id="product_details.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="products.table_title" />
        </Heading>
        <ProductTable products={data} />
      </Card>
    );
  }, [data, isLoading]);

  return (
    <Fragment>
      <Heading>
        <FormattedMessage id="products.title" />
      </Heading>
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.title" />
        </Heading>
        <SearchField
          onSearch={onSearch}
          placeholder={intl.formatMessage({
            id: 'products.search_placeholder'
          })}
          buttons={
            <Button
              onClick={toggleFilters}
              backgroundColor="surface"
              color={filters.active ? 'primaryDark' : 'onSurface'}
            >
              <FormattedMessage id="search.advanced_btn" />
            </Button>
          }
        />
      </Card>
      <FoldableCard open={filters.active}>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.advanced_title" />
        </Heading>
        <ProductFilters onFilter={fetchProducts} />
      </FoldableCard>
      {results}
    </Fragment>
  );
};

export default ProductSearchPage;
