import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import * as diff from 'diff';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import { Container, Added, Removed, Normal, TextType, Text } from './styled';

type Props = {
  before: object | null;
  after: object | null;
};

const DiffView: FC<Props> = ({ before, after }) => {
  const size = useRef<number>(16);
  const indent = useRef<number>(0);
  const difference = useMemo<diff.Change[]>(
    () => diff.diffJson(before ?? '', after ?? ''),
    [before, after]
  );

  // Render row
  const renderRow = useCallback(
    (row: string, add: boolean, remove: boolean, index: number) => {
      if (!row) {
        return null;
      }
      if (row.includes('}')) {
        indent.current -= size.current;
      }
      const text = (
        <TextType $add={add} $remove={remove} key={index}>
          <Text $indent={indent.current}>{row}</Text>
        </TextType>
      );
      if (row.includes('{')) {
        indent.current += size.current;
      }
      return text;
    },
    []
  );

  // Render value type
  const renderValueType = useCallback(
    (value: ReactNode, add: boolean, remove: boolean, index: number) => {
      if (remove) {
        return <Removed key={index}>{value}</Removed>;
      }
      if (add) {
        return <Added key={index}>{value}</Added>;
      }
      return <Normal key={index}>{value}</Normal>;
    },
    []
  );

  // Empty state
  if (!before && !after) {
    return (
      <EmptyState icon={IconType.Compare}>
        <FormattedMessage id="misc.no_changes" />
      </EmptyState>
    );
  }

  return (
    <Container>
      {difference.map(({ value, added = false, removed = false }, i) => {
        const valueList = value.split('\n').map((row, j) => {
          return renderRow(row, added, removed, j);
        });
        return renderValueType(valueList, added, removed, i);
      })}
    </Container>
  );
};

export default DiffView;
