import styled from 'styled-components';

export const ButtonGrid = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 200px;
  }
`;

export const DetailGroup = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin: 24px 0 32px;
`;

export const SelectList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SelectListItem = styled.li`
  display: flex;
  margin: 16px 0;

  label {
    cursor: pointer;
    padding: 0 16px;
  }
`;
