import styled from 'styled-components';

export const CardHeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CardHeaderTopColumn = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }
`;
