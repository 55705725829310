import styled from 'styled-components';
import { breakpoints } from 'styles';

import loginImage from 'assets/images/login.jpg';

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ImageSection = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  background: url(${loginImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const ImageContent = styled.div`
  color: white;
  padding: 64px;
  margin-bottom: 160px;

  h1 {
    max-width: 240px;
    background-color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    padding: 12px;
  }

  p {
    max-width: 400px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
  }
`;

export const LoginSection = styled.div`
  width: 40%;
  min-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.l}px) {
    min-width: 100%;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    min-width: 100%;
    display: block;
  }
`;

export const LoginContent = styled.div`
  max-width: 360px;
  margin: 0 24px;

  @media (max-height: ${breakpoints.l}px) {
    margin: 48px auto;
  }

  @media (max-width: ${breakpoints.m}px) {
    max-width: 360px;
    margin: 0 auto;
    padding: 24px;
  }
`;

export const LoginDescription = styled.p`
  margin-bottom: 24px;
`;

export const ButtonGrid = styled.div`
  margin-bottom: 32px;

  button {
    width: 100%;
  }
`;

export const LogoGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 32px 0;
  }
`;

export const LogoGroupImage = styled.img`
  margin-bottom: 8px;
`;

export const LogoGroupText = styled.p`
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 14px;
  text-align: center;
`;

export const LanguageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: ${breakpoints.l}px) {
    padding: 8px 16px;
  }
`;
