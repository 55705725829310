import { apiFetch, createFilterParams } from 'utils/fetch';
import {
  CompanyFilter,
  ExternalCompany,
  ExternalCompanyResponse
} from 'models';
import * as config from 'config';

export async function findExternalCompaniesByFilter(
  filters: CompanyFilter[]
): Promise<ExternalCompany[]> {
  const url = createFilterParams(`${config.backendApi}/companies`, filters);
  const json = await apiFetch<ExternalCompanyResponse>(url);
  return json.data;
}
