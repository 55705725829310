import { diamRolePermissions, emptyRoles } from 'utils/constants';
import {
  AzurePermission,
  JsonData,
  MetaSalesCompanyCode,
  SCPRoles,
  SCPUser,
  ServiceContractResponse,
  DIAMGroup,
  Session,
  User,
  CustomerRelation
} from 'models';

enum PermissionAttributes {
  ADMIN = 'Admin',
  READONLY = 'Readonly'
}

/**
 * Validate role and handle restrictions
 */
export function validateAzureEditPermission(
  permissions: AzurePermission[],
  salesCompanyCodes?: string[],
  allowAnySalesCompanyAdmin: boolean = false
): boolean {
  return permissions.some(({ permission, companyCode }) => {
    // Restrict for readonly permissions
    if (permission.includes(PermissionAttributes.READONLY)) {
      return false;
    }

    // Restrict for non admin permissions
    if (!permission.includes(PermissionAttributes.ADMIN)) {
      return false;
    }

    // Allow with any sales company admin access or if there is no company code
    if (allowAnySalesCompanyAdmin || !companyCode) {
      return true;
    }

    // Allow if companyCode is found in salesCompanyCodes
    if (salesCompanyCodes?.includes(companyCode)) {
      return true;
    }

    return false;
  });
}

/**
 * Get salesCompanyCodes from service contract sellerId
 */
export function getSalesCompanyCodesFromSellerId(
  salesCompanyCodes: MetaSalesCompanyCode[],
  sellerId?: string
): string[] {
  if (!sellerId) {
    return [];
  }

  // Check if sellerId contains a salesCompanyCode from meta
  const found = salesCompanyCodes.find((item) =>
    sellerId.includes(item.salesCompanyCode)
  );

  if (!found) {
    return [];
  }

  return [found.salesCompanyCode];
}

export function getSalesCompanyCodesFromCustomerRelations(
  relations: CustomerRelation[]
): string[] {
  const salesCompanyCodes = relations.map(
    (relation) => relation.attributes.dealer.companyCode
  ) as string[];
  return salesCompanyCodes;
}

export function getUserRoles(session: Session): SCPRoles {
  return session.scpUser?.roles ?? emptyRoles;
}

export function getSellerId(
  res?: ServiceContractResponse | null
): string | undefined {
  return res?.attributes.serviceContract?.attributes.salesOrder?.attributes
    .sellerId;
}

export function filterSalescompanyCodesByRole(
  codes: MetaSalesCompanyCode[],
  permissions: AzurePermission[]
): MetaSalesCompanyCode[] {
  return codes.filter((code) => {
    return permissions.some((permission) => {
      const canEdit = !permission.permission.includes(
        PermissionAttributes.READONLY
      );
      if (!permission.companyCode && canEdit) {
        return true;
      }
      return permission.companyCode === code.salesCompanyCode && canEdit;
    });
  });
}

export function hasAzureAccess(scpUser: SCPUser): boolean {
  return scpUser.roles.azure.length > 0;
}

export function hasAzureGlobalAdminAccess(scpUser: SCPUser): boolean {
  return scpUser.roles.azure.some(
    (permission) => permission.permission === 'GlobalAdmin'
  );
}

export function hasDiamAccess(scpUser: SCPUser): boolean {
  return scpUser.roles.diam.isAnyAdmin;
}

export function hasDiamStatisticsAccess(scpUser: SCPUser): boolean {
  return scpUser.roles.diam.canViewStatistics;
}

export function isDiamGlobalAdmin(scpUser: SCPUser): boolean {
  return scpUser.roles.diam.isGlobalAdmin;
}

export function isAnyDiamGlobalAdmin(scpUser: SCPUser): boolean {
  const { isGlobalAdmin, isGlobalReadonlyAdmin } = scpUser.roles.diam;
  return isGlobalAdmin || isGlobalReadonlyAdmin;
}

export function isAnyReadonlyAdmin(scpUser: SCPUser): boolean {
  const { isGlobalAdmin, isGlobalReadonlyAdmin, isReadOnlyAdmin } =
    scpUser.roles.diam;

  if (isGlobalAdmin) {
    return false;
  }

  return isGlobalReadonlyAdmin || isReadOnlyAdmin;
}

export function isAnyDiamAdminOnUser(scpUser: SCPUser, user: User): boolean {
  const { isGlobalAdmin, adminGroups } = scpUser.roles.diam;
  const { groups } = user.attributes;

  if (isGlobalAdmin) {
    return true;
  }

  return adminGroups.some((group) => groups.includes(group.id));
}

export function getDiamPermissions(scpUser: SCPUser, role: string) {
  const roles: JsonData = scpUser.roles.diam;
  const permission = diamRolePermissions[role];
  return roles[role] ? [{ permission }] : [];
}

export function getDiamSystemGroups(scpUser: SCPUser): DIAMGroup[] {
  return scpUser.roles.diam.systemGroups;
}
