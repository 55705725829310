import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useParseOptionLabel = () => {
  const intl = useIntl();

  // Parse option label
  return useCallback(
    (translationKey: string, listName: string): string => {
      const name = listName.split(',').join('_').toLowerCase();
      const id = `${translationKey}_${name}`;
      return `${intl.formatMessage({ id })}`;
    },
    [intl]
  );
};
