import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Input = styled.input`
  flex: 1;
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.grey3};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  border-radius: 16px;
  outline: none;
  padding: 12px 16px;

  :disabled {
    opacity: 60%;
  }
`;
export const InputLarge = styled(Input)`
  flex: 2;
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 120px;
  }
`;

export const Divider = styled.div`
  padding: 6px;
`;
