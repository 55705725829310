import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  companySearchSelector,
  companySelectSelector,
  findExternalCompanies,
  setSearchInputCountryCode,
  setSearchInputValue,
  resetSelectState,
  resetSearchResultState,
  resetCompanyState,
  selectExternalCompany
} from 'store';
import { CompanyFilter, CompanyFilterAttribute } from 'models';
import { getExternalCompanyInfo } from 'utils/customer';
import { ButtonGrid, TwoColumnGrid } from 'styles';

import countries from 'assets/data/countries.json';
import * as routes from 'router/Routes';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import InputField from 'components/UI/InputField';
import SelectField, { OptionType } from 'components/UI/SelectField';
import ArrowIcon from 'components/UI/Icon/ArrowIcon';
import Icon, { IconType } from 'components/UI/Icon';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import FoldableContainer from 'components/UI/FoldableContainer';
import TypeaheadSearchField from 'components/UI/TypeaheadSearchField';
import SortableTable from 'components/UI/Table/SortableTable';
import { InfoBox } from 'components/UI/InfoBox';

import {
  Container,
  InnerButton,
  SearchButtonGrid,
  AdvancedSearchForm,
  AdvancedButtonGrid,
  ResultTitle,
  Selected,
  SelectedIconContainer,
  ExistingCustomer
} from './styled';

type DataType = 'data' | 'advancedData';

type CompanySearchForm = {
  advancedSearchActive: boolean;
  countryCode: string;
  vatNumber: string;
  dunsNumber: string;
  value: string;
};

type Props = {
  isOpen: boolean;
  onForward: () => void;
  onClose: () => void;
};

const SearchProCustomer: FC<Props> = ({ isOpen, onForward, onClose }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux
  const search = useAppSelector(companySearchSelector);
  const select = useAppSelector(companySelectSelector);

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<CompanySearchForm>({
    defaultValues: {
      advancedSearchActive: false,
      vatNumber: '',
      value: ''
    }
  });

  // Watch
  const advancedSearchActive = watch('advancedSearchActive');

  // Select country
  const onSelectCountry = useCallback(
    (option: OptionType) => dispatch(setSearchInputCountryCode(option.value)),
    [dispatch]
  );

  // Active filters
  const onAdvancedToggle = useCallback(
    () => setValue('advancedSearchActive', !advancedSearchActive),
    [setValue, advancedSearchActive]
  );

  // Create
  const onSubmit: SubmitHandler<CompanySearchForm> = useCallback(
    (values) => {
      const { vatNumber, dunsNumber } = values;

      const filters: CompanyFilter[] = [];
      if (vatNumber) {
        filters.push({
          attribute: CompanyFilterAttribute.VatNumber,
          value: vatNumber
        });
      } else if (dunsNumber) {
        filters.push({
          attribute: CompanyFilterAttribute.DunsNumber,
          value: dunsNumber
        });
      }

      dispatch(findExternalCompanies(filters, true));
    },
    [dispatch]
  );

  // Search
  const onSearch = useCallback(
    (value: string) => {
      if (!search.inputCountryCode) {
        return;
      }

      dispatch(setSearchInputValue(value));

      if (!value) {
        dispatch(resetSearchResultState());
        return;
      }

      dispatch(
        findExternalCompanies([
          {
            attribute: CompanyFilterAttribute.CompanyName,
            value
          },
          {
            attribute: CompanyFilterAttribute.CountryCode,
            value: search.inputCountryCode
          }
        ])
      );
    },
    [dispatch, search.inputCountryCode]
  );

  // Clear
  const onClear = useCallback(
    () =>
      reset({
        advancedSearchActive: true,
        vatNumber: '',
        dunsNumber: ''
      }),
    [reset]
  );

  // Select
  const onSelect = useCallback(
    (id: string, type: DataType = 'data') => {
      const company = search[type].find((item) => item.id === id);
      if (company) {
        dispatch(selectExternalCompany(company.id));
      }
    },
    [dispatch, search]
  );

  // Reset and close
  const onCloseAndReset = useCallback(() => {
    onClose();
    dispatch(resetCompanyState());
    reset({
      advancedSearchActive: false,
      dunsNumber: '',
      vatNumber: ''
    });
  }, [dispatch, onClose, reset]);

  // Reset select
  const onResetSelect = useCallback(
    () => dispatch(resetSelectState()),
    [dispatch]
  );

  // Go to existing customer
  const onGoToExistingCustomer = useCallback(
    (customerId: string) => () => navigate(`${routes.CUSTOMERS}/${customerId}`),
    [navigate]
  );

  // Country options
  const countryOptions = useMemo(() => {
    return countries.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  // Results
  const results = useMemo(
    () => search.data.map((item) => getExternalCompanyInfo(item)),
    [search]
  );

  // Content
  const content = useMemo(() => {
    if ((search.isLoading && advancedSearchActive) || select.isLoading) {
      return <Loader center padding />;
    }
    if (select.data) {
      const { primary, secondary } = getExternalCompanyInfo(select.data);
      return (
        <Selected>
          <SelectedIconContainer>
            <Icon type={IconType.Retail} themeType="grey6" size="large" />
          </SelectedIconContainer>
          <Heading type={HeadingType.h3}>{primary}</Heading>
          <p>{secondary}</p>
          {select.data.attributes.customerId && (
            <ExistingCustomer>
              <FormattedMessage id="modal.create_customer_search_already_exists" />
            </ExistingCustomer>
          )}
          <Button
            onClick={onResetSelect}
            backgroundColor="transparent"
            color="primary"
            size="small"
          >
            <FormattedMessage id="button.deselect" />
          </Button>
        </Selected>
      );
    }
    if (search.advancedData.length > 0 && advancedSearchActive) {
      return (
        <div>
          <ResultTitle>
            <FormattedMessage id="modal.create_customer_search_advanced_title" />
          </ResultTitle>
          <SortableTable
            headerCells={[]}
            bodyRows={search.advancedData.map((item) => {
              const { primary, secondary, disabled } =
                getExternalCompanyInfo(item);

              return {
                primary,
                secondary,
                select: (
                  <Button
                    onClick={() => {
                      onSelect(item.id, 'advancedData');
                      setValue('advancedSearchActive', false);
                    }}
                    backgroundColor="transparent"
                    color="primary"
                    size="small"
                    disabled={disabled}
                  >
                    <FormattedMessage id="button.select" />
                  </Button>
                )
              };
            })}
          />
        </div>
      );
    }

    return (
      <EmptyState icon={IconType.Search} padding>
        <FormattedMessage id="modal.create_customer_empty_state" />
      </EmptyState>
    );
  }, [setValue, onSelect, onResetSelect, search, select, advancedSearchActive]);

  return (
    <>
      <Container>
        {search.data.length === 25 && (
          <InfoBox>
            <FormattedMessage id="customers.search_info" />
          </InfoBox>
        )}
        {isOpen && (
          <>
            <SelectField
              name="countryCode"
              onSelect={onSelectCountry}
              placeholder={intl.formatMessage({ id: 'select.placeholder' })}
              label={intl.formatMessage({
                id: 'modal.edit_user_input_country'
              })}
              options={countryOptions}
              defaultValue={search.inputCountryCode}
              enableSearch
              tight
            />
            <TypeaheadSearchField
              onSearch={onSearch}
              onSelect={onSelect}
              placeholder={intl.formatMessage({
                id: 'modal.create_customer_search_company'
              })}
              defaultValue={search.inputValue}
              disabled={!search.inputCountryCode}
              isFetching={search.isLoading}
              results={results}
            />
          </>
        )}
      </Container>
      <SearchButtonGrid>
        <Button
          onClick={onAdvancedToggle}
          backgroundColor="transparent"
          color="primary"
          size="small"
        >
          <InnerButton>
            <FormattedMessage id="modal.create_customer_search_advanced" />
            <ArrowIcon
              size="small"
              direction={advancedSearchActive ? 'up' : 'down'}
              themeType="primary"
              transition={false}
            />
          </InnerButton>
        </Button>
        <Button
          onClick={onForward}
          backgroundColor="transparent"
          color="primary"
          size="small"
        >
          <FormattedMessage id="modal.create_customer_search_unlucky" />
        </Button>
      </SearchButtonGrid>
      <FoldableContainer open={advancedSearchActive}>
        <AdvancedSearchForm onSubmit={handleSubmit(onSubmit)}>
          <TwoColumnGrid>
            <InputField
              type="text"
              description="customers.input_vat_number"
              register={register('vatNumber')}
              error={errors.vatNumber}
            />
            <InputField
              type="text"
              description="customers.input_duns_number"
              register={register('dunsNumber')}
              error={errors.dunsNumber}
            />
          </TwoColumnGrid>
          <AdvancedButtonGrid>
            <Button
              onClick={onClear}
              backgroundColor="surface"
              color="onSurface"
              marginRight
            >
              <FormattedMessage id="search.clear" />
            </Button>
            <Button submit disabled={!search.inputCountryCode}>
              <FormattedMessage id="search.advanced_btn_submit" />
            </Button>
          </AdvancedButtonGrid>
        </AdvancedSearchForm>
      </FoldableContainer>
      {content}
      <ButtonGrid>
        <Button
          onClick={onCloseAndReset}
          backgroundColor="surface"
          color="onSurface"
          marginRight
        >
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        {select.data?.attributes.customerId ? (
          <Button
            onClick={onGoToExistingCustomer(select.data.attributes.customerId)}
          >
            <FormattedMessage id="modal.btn_go_to_customer" />
          </Button>
        ) : (
          <Button onClick={onForward} disabled={!select.data}>
            <FormattedMessage id="modal.btn_continue" />
          </Button>
        )}
      </ButtonGrid>
    </>
  );
};

export default SearchProCustomer;
