import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  CustomerDetailsAttributes,
  CustomerRelation,
  CustomerState,
  APIError,
  ChangeEmailBody,
  CustomerUpdate,
  CustomerAttributes,
  Customer,
  CustomerFilter,
  CustomerType,
  OrganizationCustomerCreate
} from 'models';
import * as services from 'services';
import { mapProductAndRelation } from 'utils/joining';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: CustomerState = {
  list: {
    data: null,
    isLoading: false,
    error: null,
    filters: {
      active: false,
      customerType: CustomerType.IndividualCustomer
    }
  },
  details: {
    data: null,
    isLoading: false,
    error: null,
    deleteRelations: {
      timeStamp: null,
      isLoading: false,
      error: null
    },
    changeEmail: {
      newEmail: null,
      isSuccess: false,
      isLoading: false,
      error: null
    },
    update: {
      isSuccess: false,
      isLoading: false,
      error: null
    },
    confirmDeleteCustomer: {
      data: null,
      isSuccess: false,
      isLoading: false,
      error: null
    }
  },
  create: {
    data: null,
    isSuccess: false,
    isLoading: false,
    error: null
  }
};

// Export slice
export const customerSlice = createSlice({
  name: 'customer',
  initialState: defaultState,
  reducers: {
    getCustomerListInit: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    getCustomerListSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    getCustomerListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    setCustomerListFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    setCustomerTypeFilter: (state, action: PayloadAction<CustomerType>) => {
      state.list.filters.customerType = action.payload;
    },
    resetCustomerListState: (state) => {
      state.list = defaultState.list;
    },
    getCustomerDetailsInit: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getCustomerDetailsSuccess: (
      state,
      action: PayloadAction<CustomerDetailsAttributes>
    ) => {
      state.details.isLoading = false;
      state.details.data = action.payload;
    },
    getCustomerDetailsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.error = action.payload;
    },
    resetCustomerDetailsState: (state) => {
      state.details = defaultState.details;
    },
    deleteRelationsInit: (state) => {
      state.details.deleteRelations.isLoading = true;
      state.details.deleteRelations.error = null;
    },
    deleteRelationsSuccess: (state) => {
      state.details.deleteRelations.timeStamp = Date.now();
      state.details.deleteRelations.isLoading = false;
    },
    deleteRelationsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.deleteRelations.timeStamp = null;
      state.details.deleteRelations.isLoading = false;
      state.details.deleteRelations.error = action.payload;
    },
    changeEmailInit: (state) => {
      state.details.changeEmail.isSuccess = false;
      state.details.changeEmail.isLoading = true;
      state.details.changeEmail.error = null;
    },
    changeEmailSuccess: (state, action: PayloadAction<string>) => {
      state.details.changeEmail.isSuccess = true;
      state.details.changeEmail.isLoading = false;
      state.details.changeEmail.newEmail = action.payload;
    },
    changeEmailFailure: (state, action: PayloadAction<APIError>) => {
      state.details.changeEmail.isLoading = false;
      state.details.changeEmail.error = action.payload;
    },
    confirmDeleteCustomerInit: (state) => {
      state.details.confirmDeleteCustomer.isSuccess = false;
      state.details.confirmDeleteCustomer.isLoading = true;
      state.details.confirmDeleteCustomer.error = null;
    },
    confirmDeleteCustomerSuccess: (state) => {
      state.details.confirmDeleteCustomer.isSuccess = true;
      state.details.confirmDeleteCustomer.isLoading = false;
    },
    confirmDeleteCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.details.confirmDeleteCustomer.isLoading = false;
      state.details.confirmDeleteCustomer.error = action.payload;
    },
    updateCustomerInit: (state) => {
      state.details.update.isSuccess = false;
      state.details.update.isLoading = true;
      state.details.update.error = null;
    },
    updateCustomerSuccess: (
      state,
      action: PayloadAction<CustomerAttributes>
    ) => {
      state.details.update.isLoading = false;
      if (state.details.data) {
        state.details.update.isSuccess = true;
        state.details.data.customer = action.payload;
      }
    },
    updateCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.details.update.isLoading = false;
      state.details.update.error = action.payload;
    },
    createCustomerInit: (state) => {
      state.create.isSuccess = false;
      state.create.isLoading = true;
      state.create.error = null;
    },
    createCustomerSuccess: (
      state,
      action: PayloadAction<CustomerAttributes>
    ) => {
      state.create.isLoading = false;
      state.create.isSuccess = true;
      state.create.data = action.payload;
    },
    createCustomerFailure: (state, action: PayloadAction<APIError>) => {
      state.create.isLoading = false;
      state.create.error = action.payload;
    },
    resetCustomerCreateState: (state) => {
      state.create = defaultState.create;
    }
  }
});

// Export selectors
export const customerListSelector = (state: RootState) => state.customer.list;
export const customerDetailsSelector = (state: RootState) =>
  state.customer.details;
export const customerCreateSelector = (state: RootState) =>
  state.customer.create;

// Export actions
export const {
  getCustomerListInit,
  getCustomerListSuccess,
  getCustomerListFailure,
  setCustomerListFilters,
  setCustomerTypeFilter,
  resetCustomerListState,
  getCustomerDetailsInit,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  resetCustomerDetailsState,
  deleteRelationsInit,
  deleteRelationsSuccess,
  deleteRelationsFailure,
  changeEmailInit,
  changeEmailSuccess,
  changeEmailFailure,
  confirmDeleteCustomerInit,
  confirmDeleteCustomerSuccess,
  confirmDeleteCustomerFailure,
  createCustomerInit,
  createCustomerSuccess,
  createCustomerFailure,
  resetCustomerCreateState,
  updateCustomerInit,
  updateCustomerSuccess,
  updateCustomerFailure
} = customerSlice.actions;

// Export reducer
export const customerReducer = customerSlice.reducer;

// Export thunk
export function findCustomersByFilter(filter: CustomerFilter[]) {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerListInit());
    try {
      const customers = await services.findCustomersByFilter(filter);
      dispatch(getCustomerListSuccess(customers));
    } catch (e: any) {
      dispatch(getCustomerListFailure(e));
    }
  };
}

export function fetchCustomer(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerDetailsInit());
    try {
      const [details, relations]: [
        CustomerDetailsAttributes,
        CustomerRelation[]
      ] = await Promise.all([
        services.findCustomerDataById(id),
        services.getCustomerRelations(id)
      ]);
      const products = mapProductAndRelation(details.products, relations);
      dispatch(getCustomerDetailsSuccess({ ...details, products }));
    } catch (e: any) {
      dispatch(getCustomerDetailsFailure(e));
    }
  };
}

export function deleteCustomerProductRelations(
  customerId: string,
  relationshipIds: string[]
) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteRelationsInit());
    try {
      await services.deleteCustomerRelations(customerId, relationshipIds);
      dispatch(deleteRelationsSuccess());
    } catch (e: any) {
      dispatch(deleteRelationsFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function createCustomer(data: OrganizationCustomerCreate) {
  return async (dispatch: Dispatch) => {
    dispatch(createCustomerInit());
    try {
      const response = await services.createCustomer(data);
      const { id, attributes } = response.data;
      dispatch(
        createCustomerSuccess({
          ...attributes,
          customerId: id
        })
      );
    } catch (e: any) {
      dispatch(createCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function deleteCustomer(customerId: string, deleteReason: string) {
  return async (dispatch: Dispatch) => {
    dispatch(confirmDeleteCustomerInit());
    try {
      await services.confirmDeleteCustomer(customerId, deleteReason);
      dispatch(confirmDeleteCustomerSuccess());
    } catch (e: any) {
      dispatch(confirmDeleteCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function requestChangeEmail(customerId: string, body: ChangeEmailBody) {
  return async (dispatch: Dispatch) => {
    dispatch(changeEmailInit());
    try {
      await services.requestChangeCustomerEmail(customerId, body);
      dispatch(changeEmailSuccess(body.newEmail));
    } catch (e: any) {
      dispatch(changeEmailFailure(e));
      showErrorToast(e.title);
    }
  };
}

export function updateCustomerData(customerId: string, update: CustomerUpdate) {
  return async (dispatch: Dispatch) => {
    dispatch(updateCustomerInit());
    try {
      const response = await services.updateCustomerById(customerId, update);
      const { id, attributes } = response.data;
      dispatch(
        updateCustomerSuccess({
          ...attributes,
          customerId: id
        })
      );
    } catch (e: any) {
      dispatch(updateCustomerFailure(e));
      showErrorToast(e.title);
    }
  };
}
