import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  justify-content space-between;
  margin: 0 auto;
  width: 100%;
`;

type ColumnProps = {
  $right?: boolean;
};

export const Column = styled.div<ColumnProps>`
  flex: 1;
  display: flex;
  justify-content: ${({ $right }) => ($right ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

export const CenterColumn = styled.div`
  flex: 1;
  text-align: center;
  padding: 16px;
`;

export const InnerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  display: block;
  padding: 0 8px;

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`;

export const CenterText = styled.p`
  margin: 0;
  font-size: 18px;
`;

export const SubCenterText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey5};
`;
