/**
 * Allows for blocking the execution of async requests until the lock is released.
 */
let locked: boolean = false;

export const queue: Array<() => void> = [];

export async function acquireLock(): Promise<void> {
  return new Promise<void>((resolve) => {
    if (!locked) {
      locked = true;
      resolve();
    } else {
      queue.push(resolve);
    }
  });
}

export function releaseLock(): void {
  while (queue.length > 0) {
    const nextResolve = queue.shift();
    if (nextResolve) {
      nextResolve();
    }
  }
  locked = false;
}

export function isLocked(): boolean {
  return locked;
}
