import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { APIError, UserStatistics, UserStatisticsState } from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: UserStatisticsState = {
  list: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  exports: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const userStatisticsSlice = createSlice({
  name: 'user-statistics',
  initialState: defaultState,
  reducers: {
    getUserStatisticsInit: (state) => {
      state.list.isSuccess = false;
      state.list.isLoading = true;
      state.list.isError = false;
      state.list.error = null;
    },
    getUserStatisticsSuccess: (
      state,
      action: PayloadAction<UserStatistics[]>
    ) => {
      state.list.data = action.payload;
      state.list.isSuccess = true;
      state.list.isLoading = false;
    },
    getUserStatisticsFailure: (state, action: PayloadAction<APIError>) => {
      state.list.isLoading = false;
      state.list.isError = true;
      state.list.error = action.payload;
    },
    exportStatisticsInit: (state) => {
      state.exports.isSuccess = false;
      state.exports.isLoading = true;
      state.exports.isError = false;
      state.exports.error = null;
    },
    exportStatisticsSuccess: (state, action: PayloadAction<string>) => {
      state.exports.data = action.payload;
      state.exports.isSuccess = true;
      state.exports.isLoading = false;
    },
    exportStatisticsFailure: (state, action: PayloadAction<APIError>) => {
      state.exports.isLoading = false;
      state.exports.isError = true;
      state.exports.error = action.payload;
    },
    resetUserStatisticsState: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const userStatisticsSelector = (state: RootState) =>
  state.userStatistics;

// Export actions
export const {
  getUserStatisticsInit,
  getUserStatisticsSuccess,
  getUserStatisticsFailure,
  exportStatisticsInit,
  exportStatisticsSuccess,
  exportStatisticsFailure,
  resetUserStatisticsState
} = userStatisticsSlice.actions;

// Export reducer
export const userStatisticsReducer = userStatisticsSlice.reducer;

// Export thunk
export function fetchUserStatistics() {
  return async (dispatch: Dispatch) => {
    dispatch(getUserStatisticsInit());
    try {
      const result = await services.getStatistics();
      dispatch(getUserStatisticsSuccess(result));
    } catch (e: any) {
      dispatch(getUserStatisticsFailure(e));
    }
  };
}

export function fetchUserStatisticsCsvExport() {
  return async (dispatch: Dispatch) => {
    dispatch(exportStatisticsInit());
    try {
      const result = await services.getStatisticsCsvExport();
      dispatch(exportStatisticsSuccess(result));
    } catch (e: any) {
      dispatch(exportStatisticsFailure(e));
    }
  };
}
