import { FC, useCallback, useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import {
  MoveServiceContractField,
  ServiceContract,
  ServiceContractProduct
} from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  contractMoveSelector,
  moveServiceContract,
  resetMoveContract
} from 'store';
import { ButtonGrid } from 'styles';
import { showSuccessToast } from 'utils/toast';

type Props = {
  open: boolean;
  contract: ServiceContract;
  product: ServiceContractProduct;
  fetchServiceContractDetails: () => void;
  fetchAuditLogs: () => void;
  close: () => void;
};

const MoveServiceContractModal: FC<Props> = ({
  open,
  contract,
  product,
  fetchServiceContractDetails,
  fetchAuditLogs,
  close
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(contractMoveSelector);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Success
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        intl.formatMessage({
          id: 'contract_details.contract_move_success'
        })
      );
      dispatch(resetMoveContract());
      fetchServiceContractDetails();
      fetchAuditLogs();
      close();
    }
  }, [
    close,
    dispatch,
    fetchAuditLogs,
    fetchServiceContractDetails,
    intl,
    isSuccess
  ]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      dispatch(
        moveServiceContract(
          contract.id,
          values[MoveServiceContractField.Pnc],
          values[MoveServiceContractField.SerialNumber]
        )
      );
    },
    [contract.id, dispatch]
  );

  return (
    <EditModal
      title="contract_details.move_modal_title"
      open={open}
      close={close}
    >
      <p>
        <FormattedMessage id="contract_details.move_modal_description" />
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputField
            description="contract_details.move_modal_pnc"
            defaultValue={product.data.pnc}
            register={register(MoveServiceContractField.Pnc, {
              required: intl.formatMessage({
                id: 'contract_details.move_modal_pnc_serial_number_input_required_error'
              })
            })}
            error={errors[MoveServiceContractField.Pnc]}
          />
          <InputField
            description="contract_details.move_modal_serial_number"
            register={register(MoveServiceContractField.SerialNumber, {
              required: intl.formatMessage({
                id: 'contract_details.move_modal_pnc_serial_number_input_required_error'
              })
            })}
            error={errors[MoveServiceContractField.SerialNumber]}
          />
        </div>
        <ButtonGrid>
          <Button
            onClick={close}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button submit disabled={isLoading}>
            <FormattedMessage id="modal.btn_confirm" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default MoveServiceContractModal;
