import * as config from 'config';
import { MetaDataAttributes } from 'models';
import { apiFetch } from 'utils/fetch';

export async function fetchAllMetaData(): Promise<MetaDataAttributes> {
  const url = `${config.backendApi}/metadata`;

  const json = await apiFetch<any>(url);

  return json.data.attributes;
}
