import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Dealer, Warranty } from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';

import { DetailGroup } from './styled';

type Props = {
  dealer: Dealer | null;
  warranty: Warranty | null;
};

const ProductDealerCard: FC<Props> = ({ dealer, warranty }) => {
  const intl = useIntl();

  // Dealer Id
  const dealerId = useMemo(() => {
    if (warranty) {
      const { companyCode, customerNo } = warranty.attributes;

      if (companyCode && customerNo) {
        return `${companyCode}-${customerNo}`;
      }
    }
    if (dealer) {
      const { id, attributes } = dealer;
      const { companyCode } = attributes;
      return `${companyCode}-${id}`;
    }
    return null;
  }, [warranty, dealer]);

  // Dealer address
  const dealerAddress = useMemo(() => {
    if (!dealer) {
      return null;
    }
    const { street, zipCity, country } = dealer.attributes.address;

    let addressLine = `${street}, ${zipCity}`;
    if (country) {
      addressLine += `, ${country}`;
    }
    return addressLine;
  }, [dealer]);

  // Details
  const details = useMemo(() => {
    // Detail list
    const detailList = [
      {
        id: 1,
        title: 'product_details.dealer_id',
        text: dealerId
      },
      {
        id: 2,
        title: 'product_details.dealer_name',
        text: dealer?.attributes.address.name
      },
      {
        id: 3,
        title: 'product_details.dealer_address',
        text: dealerAddress
      }
    ];
    return detailList.map(({ id, title, text }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={text || '—'}
      />
    ));
  }, [intl, dealer, dealerId, dealerAddress]);

  return (
    <Card>
      <CardHeader intl="product_details.dealer_title" />
      <DetailGroup>{details}</DetailGroup>
    </Card>
  );
};

export default ProductDealerCard;
