import { OptionType } from 'components/UI/SelectField';
import { MetaSalesCompanyCode } from 'models';
import { sortObjectArray } from 'utils/array';
import { brands } from 'utils/constants';

export function getSalesCompanyCodeOptions(
  salesCompanyCodes: MetaSalesCompanyCode[],
  displayNames: Intl.DisplayNames
): OptionType[] {
  return sortObjectArray(
    salesCompanyCodes.map((item) => {
      const salesCompanyBrands = item.brands
        .map((brand) => brands?.[brand] ?? brand)
        .join(', ');

      const countryName =
        item.countryCode.length === 2
          ? displayNames.of(item.countryCode)
          : item.countryCode;

      return {
        key: `${item.countryCode}-${item.salesCompanyCode}`,
        label: `${countryName} ${item.salesCompanyCode} (${salesCompanyBrands})`,
        value: item.salesCompanyCode
      };
    }),
    'label'
  );
}
