import { FC, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { checkSession, authSelector } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Layout from 'components/Layout';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/UI/Loader';

import Login from 'pages/Login';
import Users from 'pages/Users';
import UserDetails from 'pages/Users/UserDetails';
import UserStatistics from 'pages/Users/UserStatistics';
import Customers from 'pages/Customers';
import CustomerDetails from 'pages/Customers/CustomerDetails';
import Products from 'pages/Products';
import ProductDetails from 'pages/Products/ProductDetails';
import ProductServicePlans from 'pages/Products/ProductServicePlans';
import ServicePlans from 'pages/ServicePlans';
import ServiceContracts from 'pages/ServiceContracts';
import ServiceContractDetails from 'pages/ServiceContracts/ServiceContractDetails';
import NotFoundPage from 'pages/NotFoundPage';

import { AuthRoute } from './AuthRoute';
import { AccessRoute } from './AccessRoute';
import { RootRoute } from './RootRoute';
import * as routes from './Routes';

// Router
const router = createBrowserRouter([
  {
    element: <ErrorBoundary />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            element: <AuthRoute />,
            children: [
              {
                element: <AccessRoute allow="azureAccess" />,
                children: [
                  {
                    path: routes.CUSTOMERS,
                    children: [
                      {
                        index: true,
                        element: <Customers />
                      },
                      {
                        path: ':customerId',
                        element: <CustomerDetails />
                      }
                    ]
                  },
                  {
                    path: routes.PRODUCTS,
                    children: [
                      {
                        index: true,
                        element: <Products />
                      },
                      {
                        path: ':iprId',
                        children: [
                          {
                            index: true,
                            element: <ProductDetails />
                          },
                          {
                            path: 'service-plans',
                            element: <ProductServicePlans />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: routes.SERVICE_CONTRACTS,
                    children: [
                      {
                        index: true,
                        element: <ServiceContracts />
                      },
                      {
                        path: ':contractId',
                        element: <ServiceContractDetails />
                      }
                    ]
                  }
                ]
              },
              {
                element: <AccessRoute allow="diamAccess" />,
                children: [
                  {
                    path: routes.USERS,
                    children: [
                      {
                        index: true,
                        element: <Users />
                      },
                      {
                        path: ':userId',
                        element: <UserDetails />
                      },
                      {
                        element: <AccessRoute allow="diamStatisticsAccess" />,
                        children: [
                          {
                            path: 'statistics',
                            element: <UserStatistics />
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: routes.SERVICE_PLANS,
        element: <ServicePlans />
      },
      {
        path: routes.LOGIN,
        element: <Login />
      },
      {
        path: routes.ROOT,
        element: <RootRoute />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
]);

const Router: FC = () => {
  const dispatch = useAppDispatch();
  const { authCheck } = useAppSelector(authSelector);

  // Check if active session
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  // Show loader until auth check is done
  if (!authCheck) {
    return <Loader center padding />;
  }

  return <RouterProvider router={router} />;
};

export default Router;
