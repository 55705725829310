import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { findServiceRecordsByIprId } from 'services';
import { APIError, ServiceRecordState, ServiceRecord } from 'models';
import type { RootState } from 'store/store';

export const defaultState: ServiceRecordState = {
  data: [],
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: null
};

// Export slice
export const serviceRecordSlice = createSlice({
  name: 'service-record',
  initialState: defaultState,
  reducers: {
    getServiceRecord: (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    },
    getServiceRecordSuccess: (
      state,
      action: PayloadAction<ServiceRecord[]>
    ) => {
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
    },
    getServiceRecordFailure: (state, action: PayloadAction<APIError>) => {
      state.isError = true;
      state.isLoading = false;
      state.error = action.payload;
    },
    resetServiceRecordState: () => defaultState
  }
});

// Export selectors
export const serviceRecordSelector = (state: RootState) => state.serviceRecord;

// Export actions
export const {
  getServiceRecord,
  getServiceRecordSuccess,
  getServiceRecordFailure,
  resetServiceRecordState
} = serviceRecordSlice.actions;

// Export reducer
export const serviceRecordReducer = serviceRecordSlice.reducer;

// Export thunk
export const fetchServiceRecordsByIprId =
  (iprId: string) => async (dispatch: Dispatch) => {
    dispatch(getServiceRecord());
    try {
      const response = await findServiceRecordsByIprId(iprId);
      dispatch(getServiceRecordSuccess(response));
    } catch (e: any) {
      dispatch(getServiceRecordFailure(e));
    }
  };
