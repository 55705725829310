import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useScreenSize } from 'hooks/useScreenSize';
import {
  getServicePlanGroupIntervals,
  getServicePlanTitle
} from 'utils/product';
import { ServicePlan, ServicePlanGroupIntervals } from 'models';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import ServicePlanCard from 'components/serviceplans/ServicePlanCard';
import IconButton from 'components/UI/IconButton';

import {
  SmallScreenControls,
  SmallScreenButtonCol,
  SmallScreenControlSpacer
} from './styled';

type Props = {
  data: ServicePlan | null;
  isLoading: boolean;
  humanResource: string;
  children: ReactNode;
};

const ServicePlanViewer: FC<Props> = ({
  data,
  isLoading,
  humanResource,
  children
}) => {
  const { isTablet } = useScreenSize();

  // State
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // Set active index
  const onActiveIndex = useCallback(
    (value: number) => () => setActiveIndex(activeIndex + value),
    [activeIndex]
  );

  // Get grouped intervals
  const servicePlanGroupIntervals = useMemo<ServicePlanGroupIntervals[]>(() => {
    if (!data?.attributes) {
      return [];
    }
    return getServicePlanGroupIntervals(data.attributes.serviceIntervals);
  }, [data]);

  // Loading
  if (isLoading) {
    return <Loader center padding />;
  }

  // Empty state
  if (!data || !servicePlanGroupIntervals.length) {
    return (
      <EmptyState icon={IconType.Product} padding includeParams>
        <FormattedMessage id="service_plan.not_found" />
      </EmptyState>
    );
  }

  const [firstGroup] = servicePlanGroupIntervals;

  return (
    <Fragment>
      {children}
      {servicePlanGroupIntervals.map((item) => (
        <ServicePlanCard
          key={item.title}
          title={item.title}
          allParts={item.allParts}
          intervals={item.intervals}
          activeIndex={activeIndex}
          humanResource={humanResource}
          smallScreen={isTablet}
        />
      ))}
      <SmallScreenControlSpacer />
      <SmallScreenControls>
        <SmallScreenButtonCol>
          {activeIndex !== 0 && (
            <IconButton onClick={onActiveIndex(-1)}>
              <Icon type={IconType.ArrowLeft} />
            </IconButton>
          )}
        </SmallScreenButtonCol>
        <div>{getServicePlanTitle(firstGroup.intervals[activeIndex])}</div>
        <SmallScreenButtonCol>
          {activeIndex < firstGroup.intervals.length - 1 && (
            <IconButton onClick={onActiveIndex(1)}>
              <Icon type={IconType.ArrowRight} />
            </IconButton>
          )}
        </SmallScreenButtonCol>
      </SmallScreenControls>
    </Fragment>
  );
};

export default ServicePlanViewer;
