import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SearchButtonGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const InnerButton = styled.div`
  display: flex;
  gap: 4px;
`;

export const AdvancedSearchForm = styled.form`
  margin-top: 24px;
`;

export const AdvancedButtonGrid = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ResultTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grey5};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 16px;
`;

export const Selected = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 24px 0 36px;

  h3,
  p {
    margin: 0;
  }
`;

export const ExistingCustomer = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const SelectedIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: 36px;
`;
