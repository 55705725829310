import { FC } from 'react';
import { Root } from './styled';

type Props = {
  size?: 'small' | 'medium' | 'large';
  center?: boolean;
  padding?: boolean;
};

const Loader: FC<Props> = ({
  size = 'medium',
  center = false,
  padding = false
}) => {
  return (
    <Root
      $size={size}
      $center={center}
      $padding={padding}
      data-testid="loader"
    />
  );
};

export default Loader;
