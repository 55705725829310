import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { fetchAllMetaData } from 'services';
import { MetaDataAttributes, MetaState, APIError } from 'models';
import type { RootState } from 'store/store';

export const defaultState: MetaState = {
  data: {
    contractStatuses: [],
    contractTypes: [],
    customerTypes: [],
    onboardingSources: [],
    paymentProviders: [],
    salesCompanyCodes: {
      read: [],
      write: []
    },
    userRoles: [],
    updateStatuses: [],
    warrantyConsumerTypes: []
  },
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: null
};

// Export slice
export const metaSlice = createSlice({
  name: 'meta',
  initialState: defaultState,
  reducers: {
    getMetaData: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    },
    getMetaDataSuccess: (state, action: PayloadAction<MetaDataAttributes>) => {
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
    },
    getMetaDataFailure: (state, action: PayloadAction<APIError>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    }
  }
});

// Export selectors
export const metaSelector = (state: RootState) => state.meta;

// Export actions
export const { getMetaData, getMetaDataSuccess, getMetaDataFailure } =
  metaSlice.actions;

// Export reducer
export const metaReducer = metaSlice.reducer;

// Export thunk
export function fetchMetaData() {
  return async (dispatch: Dispatch) => {
    dispatch(getMetaData());
    try {
      const response = await fetchAllMetaData();
      dispatch(getMetaDataSuccess(response));
    } catch (e: any) {
      dispatch(getMetaDataFailure(e));
    }
  };
}
