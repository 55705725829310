import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { SCPUserAccessType } from 'models';
import { useAccess } from 'hooks/useAccess';
import NoAccessState from 'components/UI/NoAccessState';

// Access route
type Props = {
  allow: SCPUserAccessType;
};

export const AccessRoute: FC<Props> = ({ allow }) => {
  const userAccess = useAccess();

  if (!userAccess[allow]) {
    return <NoAccessState />;
  }

  return <Outlet />;
};
