import { FC, ReactNode } from 'react';
import Icon, { IconType } from '../Icon';

import { Container } from './styled';

type Props = {
  children: ReactNode;
  padding?: boolean;
};

const ErrorState: FC<Props> = ({ children, padding }) => {
  return (
    <Container $padding={padding}>
      <Icon type={IconType.Cross} size="extraLarge" themeType="error" />
      {children}
    </Container>
  );
};

export default ErrorState;
