import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Group, User } from 'models';
import {
  removeUserGroup,
  removeUserGroupReset,
  resetUserSearchState,
  userGroupSelector
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { showSuccessToast } from 'utils/toast';
import * as routes from 'router/Routes';

import Loader from 'components/UI/Loader';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { ButtonGrid } from './styled';

type Props = {
  onClose: () => void;
  userGroups: Group[];
  user: User;
  open: boolean;
};

const ConfirmUserDeletionModal: FC<Props> = ({
  onClose,
  userGroups,
  user,
  open
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { removeGroup } = useAppSelector(userGroupSelector);

  const { username, firstName, lastName } = user.attributes;

  // Clear state and navigate back if deletion was successful
  useEffect(() => {
    if (removeGroup.isSuccess && open) {
      dispatch(resetUserSearchState());
      showSuccessToast(
        intl.formatMessage({ id: 'modal.confirm_delete_user_success' })
      );
      navigate(routes.USERS);
      onClose();
    }

    return () => {
      dispatch(removeUserGroupReset());
    };
  }, [dispatch, intl, navigate, onClose, open, removeGroup]);

  // Confirm
  const onConfirm = useCallback(() => {
    const [group] = userGroups;
    dispatch(removeUserGroup(user.id, { name: group.name, value: group.id }));
  }, [dispatch, user, userGroups]);

  return (
    <EditModal
      title="modal.confirm_delete_user_title"
      open={open}
      close={onClose}
    >
      <p>
        <FormattedMessage
          id="modal.confirm_delete_user_text"
          values={{
            name: `${firstName} ${lastName}`
          }}
        />
      </p>
      <p>({username})</p>
      {removeGroup.isLoading ? <Loader center padding /> : null}
      <ButtonGrid>
        <Button onClick={onClose} backgroundColor="transparent" color="primary">
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button onClick={onConfirm} disabled={removeGroup.isLoading}>
          <FormattedMessage id="modal.btn_confirm" />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};

export default ConfirmUserDeletionModal;
