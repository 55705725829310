import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import * as routes from 'router/Routes';

import { ButtonGrid } from './styled';

export const NoAccessState: FC = () => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(routes.ROOT, { replace: true });
  }, [navigate]);

  return (
    <EmptyState icon={IconType.Lock} padding>
      <FormattedMessage id="misc.no_access" />
      <ButtonGrid>
        <Button onClick={onClick}>
          <FormattedMessage id="misc.back" />
        </Button>
      </ButtonGrid>
    </EmptyState>
  );
};

export default NoAccessState;
