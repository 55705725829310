import styled from 'styled-components';

type Props = {
  error: any;
};

export const CheckboxContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 16px;

  label {
    color: ${({ error, theme }) =>
      theme.colors[error ? 'error' : 'onBackground']};
    cursor: pointer;
  }
`;
