/* istanbul ignore file */

// Declaration
declare global {
  interface Window {
    __env__: {
      ENV: string;
      BACKEND_API: string;
      AUTH_URL: string;
      AUTH_CLIENT_ID: string;
      SENTRY_DSN: string;
    };
  }
}

// Variables
export const environment = window.__env__.ENV;
export const backendApi = window.__env__.BACKEND_API;
export const authUrl = window.__env__.AUTH_URL;
export const authClientId = window.__env__.AUTH_CLIENT_ID;
export const sentryDsn = window.__env__.SENTRY_DSN;
export const isLocal = environment === 'local';
export const isDev = environment === 'dev';
export const isLive = environment === 'live';

// Handle variables not set
if (!backendApi) {
  throw new Error('No backend endpoint set! Application will not work!');
}
