import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { authSelector, layoutSelector } from 'store';
import { useAppSelector } from 'hooks/redux';
import { useAccess } from 'hooks/useAccess';
import * as routes from 'router/Routes';

export const RootRoute: FC = () => {
  const { state } = useLocation();
  const { azureAccess, diamAccess } = useAccess();
  const {
    session: { isAuthenticated }
  } = useAppSelector(authSelector);
  const { startRoute } = useAppSelector(layoutSelector);

  // Redirect to login
  if (!isAuthenticated) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  // Redirect to users if only diam access
  if (diamAccess && !azureAccess) {
    return <Navigate to={routes.USERS} replace />;
  }

  // Redirect to prev route
  if (state?.prevRoute) {
    return <Navigate to={state.prevRoute} replace />;
  }

  // Redirect to start route
  return <Navigate to={startRoute} replace />;
};
