import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ChangeEmail, CustomerAttributes } from 'models';
import { getCustomerEmail, getCustomerName } from 'utils/customer';
import { regExp } from 'utils/constants';

import InputField from 'components/UI/InputField';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';

import { ButtonGrid } from 'styles';
import { Description } from './styled';

type Props = {
  customer: CustomerAttributes;
  onChangeEmail: (newEmail: string, oldEmail: string) => void;
  changeEmail: ChangeEmail;
  onClose: () => void;
  open: boolean;
};

const ChangeEmailModal: FC<Props> = ({
  customer,
  open,
  onClose,
  onChangeEmail,
  changeEmail
}) => {
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Refs
  const shouldClose = useRef(false);

  // Customer email
  const email = useMemo(() => getCustomerEmail(customer), [customer]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ newEmail }) => {
      onChangeEmail(newEmail, email);
    },
    [onChangeEmail, email]
  );

  // Close modal on success
  useEffect(() => {
    if (changeEmail.isSuccess) {
      if (shouldClose.current) {
        onClose();
        shouldClose.current = false;
      }
    } else {
      shouldClose.current = true;
    }
  }, [onClose, changeEmail.isSuccess]);

  // Render data
  const formData = useMemo(() => {
    if (changeEmail.isLoading) {
      return <Loader padding center />;
    }
    return (
      <InputField
        description="modal.change_email_input_new_email"
        register={register('newEmail', {
          required: {
            value: true,
            message: 'input.email_required'
          },
          pattern: {
            value: regExp.email,
            message: 'input.email_invalid'
          }
        })}
        error={errors.newEmail}
      />
    );
  }, [register, changeEmail, errors]);

  return (
    <EditModal title="modal.change_email_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Description>
          <FormattedMessage
            id="modal.change_email_text"
            values={{
              name: getCustomerName(customer),
              email
            }}
          />
        </Description>
        {formData}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={changeEmail.isLoading} submit>
            <FormattedMessage id="modal.btn_send_request" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default ChangeEmailModal;
