import { FC, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  CustomerAddress,
  CustomerAttributes,
  CustomerUpdate,
  UpdateCustomer
} from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';

import EditCustomerAddressModal from '../EditCustomerAddressModal';

import {
  ContentContainer,
  AddressGrid,
  AddressDetails,
  FieldText
} from './styled';

type Props = {
  customer: CustomerAttributes;
  onCustomerUpdate: (values: CustomerUpdate) => void;
  update: UpdateCustomer;
  canEdit: boolean;
};

const CustomerAddressCard: FC<Props> = ({
  customer,
  onCustomerUpdate,
  canEdit,
  update
}) => {
  const intl = useIntl();
  const { locationAddress, billingAddress, shippingAddress } = customer;

  // State
  const [editAddressModal, setEditAddressModal] = useState<boolean>(false);

  // Open / close modals
  const openEditAddressModal = () => setEditAddressModal(true);
  const closeEditAddressModal = () => setEditAddressModal(false);

  // Fields
  const renderFields = (address: CustomerAddress) => {
    if (Object.values(address).every((item) => !item)) {
      return (
        <FieldText>
          <FormattedMessage id="customer_details.address_fields_empty" />
        </FieldText>
      );
    }
    const { street, zipCode, city, country } = address;
    return (
      <Fragment>
        <FieldText>{street}</FieldText>
        <FieldText>
          {zipCode} {city && `${city},`} {country}
        </FieldText>
      </Fragment>
    );
  };

  // Section
  const renderSection = (
    title: string,
    address: CustomerAddress,
    noBorder: boolean = false
  ) => {
    return (
      <AddressGrid $border={!noBorder}>
        <AddressDetails>
          <Heading type={HeadingType.h4}>
            <FormattedMessage id={title} />
          </Heading>
          {renderFields(address)}
        </AddressDetails>
      </AddressGrid>
    );
  };

  // Menu
  const menu = [
    {
      id: 1,
      text: intl.formatMessage({
        id: 'customer_details.btn_edit_customer_address'
      }),
      onClick: openEditAddressModal
    }
  ];

  return (
    <Card>
      <CardHeader intl="customer_details.address_title">
        {canEdit && (
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        {renderSection(
          'customer_details.address_location_title',
          locationAddress
        )}
        {renderSection(
          'customer_details.address_billing_title',
          billingAddress
        )}
        {renderSection(
          'customer_details.address_shipping_title',
          shippingAddress,
          true
        )}
      </ContentContainer>
      <EditCustomerAddressModal
        update={update}
        customer={customer}
        open={editAddressModal}
        onCustomerUpdate={onCustomerUpdate}
        onClose={closeEditAddressModal}
      />
    </Card>
  );
};

export default CustomerAddressCard;
