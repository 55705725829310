import * as config from 'config';
import { CustomerConsentResponse, CustomerConsentTypeResponse } from 'models';
import { apiFetch } from 'utils/fetch';

export async function findCustomerConsents(
  customerId: string
): Promise<CustomerConsentResponse> {
  const url = `${config.backendApi}/customers/${customerId}/consents`;

  return apiFetch<any>(url);
}

export async function findCustomerConsentTypes(): Promise<CustomerConsentTypeResponse> {
  const url = `${config.backendApi}/proxy/customers/consents/consenttypes?filter[serviceId]=husqvarna`;

  return apiFetch<any>(url);
}
