import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as routes from 'router/Routes';
import { CustomerAttributes, ProductAttributes } from 'models';
import { getProductText } from 'utils/product';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import EditProductModal from 'components/products/EditProductModal';
import RegisterProductsModal from 'components/products/RegisterProductsModal';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import Tag from 'components/UI/Tag';
import { isEmptyArray } from 'utils/array';

const headerCells: HeaderCell[] = [
  {
    sortable: false,
    intl: ''
  },
  {
    sortable: true,
    intl: 'customers.product_table_title'
  },
  {
    sortable: true,
    intl: 'customers.product_table_pnc'
  },
  {
    sortable: true,
    intl: 'customers.product_table_serial'
  },
  {
    sortable: true,
    intl: 'customers.product_table_relationship'
  },
  {
    sortable: false,
    intl: 'customers.product_table_remove'
  }
];

type RemoveModal = {
  open: boolean;
  product: ProductAttributes | null;
};

type RenderProduct = {
  product: string;
  pnc: string;
  serialNumber: string;
  link: string;
};

type Props = {
  products: ProductAttributes[];
  customer: CustomerAttributes;
  onDeleteRelations: (id: string[], iprId: string) => void;
  updateData: () => void;
  isLoading: boolean;
  canEdit: boolean;
};

const CustomerProductCard: FC<Props> = ({
  products,
  customer,
  onDeleteRelations,
  updateData,
  isLoading,
  canEdit
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // State
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<RemoveModal>({
    open: false,
    product: null
  });

  // Callbacks
  const onClick = useCallback(
    (id: string) => {
      navigate(routes.productDetailsLink(id), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Actions
  const onToggleRegisterModal = () => setRegisterModal(!registerModal);
  const onCloseRemoveModal = () =>
    setRemoveModal({ open: false, product: null });

  // Render table
  const table = useMemo(() => {
    // Rows
    const bodyRows: RenderProduct[] = products.map((product) => {
      const {
        relations,
        brand,
        modelName,
        imageUrl,
        serialNumber,
        pnc,
        iprId
      } = product;
      return {
        imageUrl,
        product: getProductText(brand, modelName),
        pnc,
        serialNumber,
        relation:
          relations && !isEmptyArray(relations) ? (
            <Tag>{relations[0].attributes.relationshipType}</Tag>
          ) : (
            '—'
          ),
        editButton: canEdit ? (
          <IconButton onClick={() => setRemoveModal({ open: true, product })}>
            <Icon type={IconType.Remove} />
          </IconButton>
        ) : (
          '–'
        ),
        link: iprId
      };
    });

    if (isLoading) {
      return <Loader center />;
    }

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="customer_details.products_empty"
        onClick={onClick}
      />
    );
  }, [products, isLoading, onClick, canEdit]);

  return (
    <Card>
      <CardHeader intl="customer_details.products_title">
        {canEdit && (
          <IconButton onClick={onToggleRegisterModal}>
            <Icon type={IconType.Add} themeType="grey6" />
          </IconButton>
        )}
      </CardHeader>
      {table}
      <EditProductModal
        customer={customer}
        product={removeModal.product}
        open={removeModal.open}
        onDeleteRelations={onDeleteRelations}
        onClose={onCloseRemoveModal}
      />
      <RegisterProductsModal
        open={registerModal}
        onSuccess={updateData}
        onClose={onToggleRegisterModal}
        customerId={customer.customerId}
      />
    </Card>
  );
};

export default CustomerProductCard;
