import { useMemo } from 'react';

import { useAppSelector } from 'hooks/redux';
import { User } from 'models';
import { authSelector } from 'store';
import { isAnyDiamAdminOnUser } from 'utils/roles';

export const useDiamUserAdminAccess = (user: User): boolean => {
  const { session } = useAppSelector(authSelector);

  return useMemo(() => {
    const { scpUser } = session;
    if (!scpUser) {
      return false;
    }

    return isAnyDiamAdminOnUser(scpUser, user);
  }, [session, user]);
};
