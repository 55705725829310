import { FC, ReactNode } from 'react';

import mobileLogo from 'assets/vectors/scp-mobile.svg';
import logo from 'assets/vectors/scp-logo.svg';

import { MenuItem } from 'models';
import * as routes from 'router/Routes';

import HamburgerButton from 'components/UI/HamburgerButton';
import TabMenu from 'components/UI/TabMenu';

import {
  Logo,
  Container,
  TopBorder,
  Content,
  PrimaryArea,
  SecondaryArea,
  SideArea,
  LogoLink,
  MobileLogo,
  ActionMenu,
  Menu,
  MobileMenu
} from './styled';

type Props = {
  children?: ReactNode;
  leftMenu: MenuItem[];
  rightMenu?: MenuItem[];
  setMobileMenuOpen: (isOpen: boolean) => void;
  mobileMenuOpen: boolean;
};

const NavBar: FC<Props> = ({
  children,
  leftMenu,
  rightMenu,
  mobileMenuOpen,
  setMobileMenuOpen
}) => {
  return (
    <Container>
      <TopBorder />
      <Content>
        <PrimaryArea>
          <LogoLink to={routes.ROOT}>
            <MobileLogo src={mobileLogo} alt="Husqvarna Group SCP" />
            <Logo src={logo} alt="Husqvarna Group SCP" />
          </LogoLink>
          <Menu>
            <TabMenu menuItems={leftMenu} includeSub />
          </Menu>
        </PrimaryArea>
        {rightMenu && (
          <SecondaryArea>
            <TabMenu menuItems={rightMenu} includeSub />
          </SecondaryArea>
        )}
        <ActionMenu>
          <svg width="40" height="56">
            <path
              d="m24.85 11.538-9.41 32.924A15.905 15.905 0 0 1 .14 56V0h40c-7.1 0-13.34 4.709-15.29 11.538Z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </svg>
          <SideArea>
            {children}
            <MobileMenu>
              <HamburgerButton
                isOpen={mobileMenuOpen}
                setIsOpen={setMobileMenuOpen}
              />
            </MobileMenu>
          </SideArea>
        </ActionMenu>
      </Content>
    </Container>
  );
};

export default NavBar;
