import { toast } from 'react-toastify';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export function showToast(message: string, type = ToastType.ERROR) {
  toast[type](message, {
    position: 'top-center',
    hideProgressBar: false,
    draggable: false,
    autoClose: 16000
  });
}

export function showErrorToast(message: string) {
  toast.error(message, {
    position: 'top-center',
    draggable: false,
    autoClose: false
  });
}
export function showWarningToast(message: string) {
  return showToast(message, ToastType.WARNING);
}
export function showInfoToast(message: string) {
  return showToast(message, ToastType.INFO);
}
export function showSuccessToast(message: string) {
  return showToast(message, ToastType.SUCCESS);
}
