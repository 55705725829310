import { FC } from 'react';
import { motion } from 'framer-motion';
import { foldableVariants } from 'utils/constants';
import Card from 'components/UI/Card';

type Props = {
  children: any;
  open: boolean;
};

const FoldableCard: FC<Props> = ({ children, open }) => {
  return (
    <motion.div
      initial={false}
      animate={open ? 'open' : 'closed'}
      variants={foldableVariants}
    >
      <Card>{children}</Card>
    </motion.div>
  );
};

export default FoldableCard;
