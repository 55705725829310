import 'react-toastify/dist/ReactToastify.css';
import { FC } from 'react';

import { StyledSnackbar } from './styled';

const Toast: FC = () => {
  return <StyledSnackbar />;
};

export default Toast;
