import { FC, ReactNode } from 'react';
import { Root } from './styled';

type TableProps = {
  children?: ReactNode;
};

const Table: FC<TableProps> = ({ children }) => {
  return <Root cellSpacing="0">{children}</Root>;
};

export default Table;
