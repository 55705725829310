import styled from 'styled-components';

export const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.grey5};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
`;
