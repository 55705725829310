import styled, { DefaultTheme } from 'styled-components';
import { boxShadows, colorTransitionDuration, ThemeColor } from 'styles';

export type ButtonColor = ThemeColor | 'transparent';

export type ButtonSize = 'large' | 'medium' | 'small';

type ButtonProps = {
  $color: ThemeColor;
  $size: ButtonSize;
  $backgroundColor: ButtonColor;
  $marginRight?: boolean;
};

function renderColor(theme: DefaultTheme, color: ThemeColor) {
  return theme.colors[color];
}

function renderBackgroundColor(
  theme: DefaultTheme,
  backgroundColor: ButtonColor
) {
  if (backgroundColor === 'transparent') {
    return 'transparent';
  }
  return theme.colors[backgroundColor];
}

function renderHoverColor(theme: DefaultTheme, backgroundColor: ButtonColor) {
  if (backgroundColor === 'primaryDark') {
    return theme.colors.primary;
  }

  if (backgroundColor === 'danger') {
    return theme.colors.danger;
  }

  return 'transparent';
}

function renderPadding(size: ButtonSize) {
  if (size === 'large') {
    return '16px 24px';
  }
  if (size === 'small') {
    return '9px 16px';
  }
  return '13px 16px';
}

export const StyledButton = styled.button<ButtonProps>`
  margin: 0;
  margin-right: ${({ $marginRight }) => ($marginRight ? '16px' : '0')};
  background-color: ${({ theme, $backgroundColor }) =>
    renderBackgroundColor(theme, $backgroundColor)};
  color: ${({ theme, $color }) => renderColor(theme, $color)};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  border: 1px solid
    ${({ theme, $backgroundColor }) =>
      renderBackgroundColor(theme, $backgroundColor)};
  transition: background-color ${colorTransitionDuration};
  border-radius: 16px;
  font-size: ${({ $size }) => ($size === 'large' ? '16px' : '14px')};
  line-height: ${({ $size }) => ($size === 'large' ? '16px' : '14px')};
  padding: ${({ $size }) => renderPadding($size)};
  box-shadow: ${({ $backgroundColor }) =>
    $backgroundColor === 'transparent' ? 'none' : boxShadows.normal};
  text-transform: ${({ $backgroundColor }) =>
    $backgroundColor === 'transparent' ? 'initial' : 'uppercase'};
  font-weight: bold;
  cursor: pointer;

  :hover {
    background-color: ${({ theme, $backgroundColor }) =>
      renderHoverColor(theme, $backgroundColor)};
  }

  :active {
    background-color: ${({ theme, $backgroundColor }) =>
      renderBackgroundColor(theme, $backgroundColor)};
  }
`;
