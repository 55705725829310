import styled from 'styled-components';

export const CheckBoxContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 0;
  width: 24px;
  height: 24px;
`;

export const CheckBoxIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
`;

export const CheckBoxInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
`;
