import styled from 'styled-components';
import { breakpoints } from 'styles';

export const StatusGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${breakpoints.l}px) {
    gap: 16px;
  }
`;
