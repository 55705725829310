import { FC, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import ArrowIcon from 'components/UI/Icon/ArrowIcon';

import { Title, NavButtonContainer } from './styled';

type Props = {
  intl?: string;
  title?: string;
  navUrl?: string;
};

const BackButton: FC<Props> = ({ title, intl, navUrl }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // Back
  const onBack = useCallback(() => {
    if (navUrl) {
      navigate(state?.from || navUrl);
    }
  }, [navigate, state, navUrl]);

  return (
    <NavButtonContainer onClick={onBack}>
      <ArrowIcon direction="left" themeType="grey5" />
      <Title>{title ?? <FormattedMessage id={intl ?? 'misc.back'} />}</Title>
    </NavButtonContainer>
  );
};

export default BackButton;
