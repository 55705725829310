import styled from 'styled-components';

export const DetailGroup = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  flex: 2;
  margin-bottom: 32px;
`;

export const TableTitle = styled.h4`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey6};
  text-transform: uppercase;
  margin: 0 16px 4px 0;
`;
