import styled from 'styled-components';

type RootProps = {
  $isOpen: boolean;
};

export const DatePickerRoot = styled.div<RootProps>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  z-index: 99;

  .react-calendar {
    border: 1px solid ${({ theme }) => theme.colors.grey3};
    border-radius: 16px;
    width: 100%;
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.colors.primaryDark};
    color: ${({ theme }) => theme.colors.onPrimary};
  }

  .react-calendar__tile {
    &:hover {
      background: ${({ theme }) => theme.colors.primaryDisabled};
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.grey2};
      color: ${({ theme }) => theme.colors.grey5};
    }
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.grey0};

    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.grey0};
    }

    &:focus {
      background: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.onSecondary};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    > abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .react-calendar__navigation__arrow {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.grey5};
  }

  .react-calendar__navigation__label__labelText {
    font-size: 18px;
  }
`;
