import { useMemo } from 'react';
import { localizationSelector } from 'store';
import { useAppSelector } from './redux';

export const useNumberFormat = (): Intl.NumberFormat => {
  const { locale } = useAppSelector(localizationSelector);

  return useMemo(() => {
    return new Intl.NumberFormat(locale);
  }, [locale]);
};
