import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.colors.grey9};
  background: ${({ theme }) => theme.colors.warning};
  border-radius: 16px;
  padding: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h4`
  font-size: 16px;
  margin: 0;
`;

export const Description = styled.div`
  font-size: 16px;
`;
