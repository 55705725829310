import { DefaultTheme } from 'styled-components';
import { ThemeColor } from 'styles';

export const setColor = (
  theme: DefaultTheme,
  color: string,
  themeType?: ThemeColor
): string => {
  if (themeType) {
    return theme.colors[themeType];
  }
  return color;
};
