import { FC } from 'react';
import { useNumberFormat } from 'hooks/useNumberFormat';
import TableDataCell from '../TableDataCell';

type ImageTextCell = {
  imageUrl: string;
  text: string;
};

// Check if entry has image
const hasImage = (obj: any): obj is ImageTextCell => {
  return (
    obj &&
    typeof obj === 'object' &&
    (typeof obj.imageUrl === 'string' || typeof obj.text === 'string')
  );
};

type Props = {
  name: string;
  value: any;
};

const TableBodyCell: FC<Props> = ({ name, value }) => {
  const numberFormatter = useNumberFormat();

  if (hasImage(value)) {
    return (
      <TableDataCell imageUrl={value.imageUrl}>{value.text}</TableDataCell>
    );
  }

  if (name === 'link' || name === 'disabled') {
    return null;
  }

  if (name === 'imageUrl') {
    return <TableDataCell imageUrl={value} />;
  }

  if (typeof value === 'number') {
    return <TableDataCell>{numberFormatter.format(value)}</TableDataCell>;
  }

  return <TableDataCell>{value ?? '–'}</TableDataCell>;
};

export default TableBodyCell;
