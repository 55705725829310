import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
`;

export const AvatarBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey2};
  width: 55px;
  height: 55px;
  border-radius: 50%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.grey9};
  text-transform: capitalize;
  max-width: 300px;
  font-size: 24px;
  margin: 0;
  flex: 1;
`;

export const DetailGroup = styled.span`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;
