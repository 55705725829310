import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { boxShadows } from 'styles';

export const StyledSnackbar = styled(ToastContainer)`
  --toastify-toast-width: 500px;

  .Toastify__toast {
    font-weight: 900;
    box-shadow: ${boxShadows.xxl};
    color: ${({ theme }) => theme.colors.onPrimary};
    padding: 16px;
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.error};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.info};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.warning};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    color: ${({ theme }) => theme.colors.onPrimary};
  }

  .Toastify__progress-bar {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.9136029411764706) 0%,
      rgba(255, 255, 255, 0.7931547619047619) 100%
    );
  }
`;
