import { FC, useMemo, useState } from 'react';
import { CheckBoxContainer, CheckBoxIcon, CheckBoxInput } from './styled';

type Props = {
  id?: string;
  value?: string;
  register?: any;
  defaultChecked?: boolean;
  error?: any;
};

const Checkbox: FC<Props> = ({
  id,
  value,
  register,
  defaultChecked = false,
  error
}) => {
  // State
  const [checked, setChecked] = useState(defaultChecked);

  const onClick = (event: any) => setChecked(event.target.checked);

  // Render check mark
  const checkMark = useMemo(() => {
    if (checked) {
      return (
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-489 -666)">
            <g transform="translate(470 516)">
              <g transform="translate(19 145)">
                <g transform="translate(0 5)">
                  <rect
                    width="20"
                    height="20"
                    rx="7"
                    fill="#D0471C"
                    fillRule="nonzero"
                  />
                  <polyline
                    points="5 11 9 15 15 6"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      );
    }
    return (
      <g fill="none" fillRule="evenodd">
        <g
          transform="translate(-489 -702)"
          fill={error ? '#DC2832' : '#686867'}
        >
          <g transform="translate(470 516)">
            <g transform="translate(19 145)">
              <g transform="translate(0 36)">
                <path d="m13 5c3.866 0 7 3.134 7 7v6c0 3.866-3.134 7-7 7h-6c-3.866 0-7-3.134-7-7v-6c-4.7345e-16 -3.866 3.134-7 7-7h6zm0 2h-6c-2.7614 0-5 2.2386-5 5v6c0 2.7614 2.2386 5 5 5h6c2.7614 0 5-2.2386 5-5v-6c0-2.7614-2.2386-5-5-5z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    );
  }, [checked, error]);

  return (
    <CheckBoxContainer>
      <CheckBoxIcon>
        <svg width="24" height="24" viewBox="0 0 20 20">
          {checkMark}
        </svg>
      </CheckBoxIcon>
      <CheckBoxInput
        {...register}
        id={id}
        value={value}
        type="checkbox"
        onClick={onClick}
        defaultChecked={defaultChecked}
      />
    </CheckBoxContainer>
  );
};

export default Checkbox;
