import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ServiceRecord, Workshop } from 'models';
import { formatDate } from 'utils/date';

import EditModal from 'components/UI/EditModal';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import InfoItem from 'components/UI/InfoItem';

import { DetailGroup, TableTitle } from './styled';

const headerCells: HeaderCell[] = [
  {
    intl: 'service_book.task_table_description',
    sortable: true
  },
  {
    intl: 'service_book.task_table_parts',
    sortable: true
  }
];

type Props = {
  data?: ServiceRecord;
  isOpen: boolean;
  onClose: () => void;
  getDoneBy: (type?: string, workshop?: Workshop) => void;
};

const ServiceRecordModal: FC<Props> = ({
  data,
  isOpen,
  onClose,
  getDoneBy
}) => {
  const intl = useIntl();

  // Record details
  const details = useMemo(() => {
    const detailList = [
      {
        id: 1,
        title: 'service_book.table_title',
        value: !data?.attributes.title ? '-' : data.attributes.title
      },
      {
        id: 2,
        title: 'service_book.table_type',
        value: data?.attributes.type ?? '-'
      },
      {
        id: 3,
        title: 'service_book.table_date',
        value: data?.attributes.recordDate
          ? formatDate(data?.attributes.recordDate, 'yyyy-MM-dd')
          : '-'
      },
      {
        id: 4,
        title: 'service_book.table_done_by',
        value: getDoneBy(
          data?.attributes.performedByType,
          data?.attributes.workshop
        )
      }
    ];

    return detailList.map(({ id, title, value }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={value ?? '-'}
      />
    ));
  }, [getDoneBy, intl, data]);

  // Performed tasks
  const performedTasks = useMemo(() => {
    if (!data) {
      return null;
    }

    // Rows
    const bodyRows = data.attributes.performedTasks.map(({ attributes }) => {
      const { description, servicedParts } = attributes;

      const parts = servicedParts.length
        ? servicedParts.map((item) => item.genericName).join(', ')
        : '-';

      return {
        description: description || '–',
        parts
      };
    });

    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={bodyRows}
        emptyTextId="service_book.task_table_empty"
      />
    );
  }, [data]);

  return (
    <EditModal title="service_book.title" open={isOpen} close={onClose}>
      <DetailGroup>{details}</DetailGroup>
      <TableTitle>
        <FormattedMessage id="service_book.task_table_title" />
      </TableTitle>
      {performedTasks}
    </EditModal>
  );
};

export default ServiceRecordModal;
