import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { AcceptanceState, CustomerAcceptance, APIError } from 'models';
import { findCustomerAcceptances, deleteCustomerAcceptance } from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: AcceptanceState = {
  data: [],
  deleteStamp: null,
  isLoading: false,
  error: null
};

// Export slice
export const acceptanceSlice = createSlice({
  name: 'acceptance',
  initialState: defaultState,
  reducers: {
    resetAcceptanceState: () => {
      return defaultState;
    },
    getAcceptance: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getAcceptanceSuccess: (
      state,
      action: PayloadAction<CustomerAcceptance[]>
    ) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    getAcceptanceFailure: (state, action: PayloadAction<APIError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteAcceptance: (state) => {
      state.error = null;
    },
    deleteAcceptanceSuccess: (state) => {
      state.deleteStamp = Date.now();
    },
    deleteAcceptanceFailure: (state, action: PayloadAction<APIError>) => {
      state.error = action.payload;
      state.deleteStamp = null;
    }
  }
});

// Export selectors
export const acceptanceSelector = (state: RootState) => state.acceptance;

// Export actions
export const {
  getAcceptance,
  getAcceptanceSuccess,
  getAcceptanceFailure,
  resetAcceptanceState,
  deleteAcceptance,
  deleteAcceptanceSuccess,
  deleteAcceptanceFailure
} = acceptanceSlice.actions;

// Export reducer
export const acceptanceReducer = acceptanceSlice.reducer;

// Export thunk
export const fetchAcceptance =
  (customerId: string) => async (dispatch: Dispatch) => {
    dispatch(getAcceptance());
    try {
      const result = await findCustomerAcceptances(customerId);
      dispatch(getAcceptanceSuccess(result.data));
    } catch (e: any) {
      dispatch(getAcceptanceFailure(e));
    }
  };

export const removeAcceptance =
  (customerId: string, acceptanceId: string) => async (dispatch: Dispatch) => {
    dispatch(deleteAcceptance());
    try {
      await deleteCustomerAcceptance(customerId, acceptanceId);
      dispatch(deleteAcceptanceSuccess());
    } catch (e: any) {
      dispatch(deleteAcceptanceFailure(e));
      showErrorToast(e.title);
    }
  };
