import { FC } from 'react';
import { IconSize, iconSizeMap } from '..';
import { ThemeColor } from 'styles';
import { StyledSVG, StyledPath } from './styled';

type Props = {
  direction?: 'up' | 'right' | 'down' | 'left';
  transitionDuration?: number;
  themeType?: ThemeColor;
  color?: string;
  transition?: boolean;
  size?: IconSize;
};

const ArrowIcon: FC<Props> = ({
  direction = 'up',
  transitionDuration = 0.5,
  themeType,
  color = 'black',
  transition = true,
  size = 'medium'
}) => {
  return (
    <StyledSVG
      $transition={transition}
      $transitionDuration={transitionDuration}
      $direction={direction}
      width={iconSizeMap[size]}
      height={iconSizeMap[size]}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <StyledPath
          $themeType={themeType}
          $color={color}
          d="M14.15 11.9L6.353 4.103c-.196-.196-.196-.512 0-.708l1.042-1.042c.196-.196.512-.196.708 0l9.192 9.192c.195.195.195.512 0 .707l-9.192 9.192c-.196.196-.512.196-.708 0l-1.042-1.042c-.196-.196-.196-.512 0-.708l7.795-7.796z"
        />
      </g>
    </StyledSVG>
  );
};

export default ArrowIcon;
