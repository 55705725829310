import styled from 'styled-components';

type Props = {
  $padding?: boolean;
};

export const Container = styled.div<Props>`
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  padding: ${({ $padding }) => ($padding ? '64px 24px' : '24px')};
`;
