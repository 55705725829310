import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CustomerAttributes, ProductAttributes } from 'models';

import InfoItem from 'components/UI/InfoItem';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';

import { ButtonGrid, DetailGroup, SelectList, SelectListItem } from './styled';
import { showInfoToast } from 'utils/toast';

type FormValues = {
  relations: string[] | string;
  warrantyConditionIprId: string;
};

type Props = {
  customer: CustomerAttributes;
  product: ProductAttributes | null;
  onDeleteRelations: (id: string[], iprId: string) => void;
  onClose: () => void;
  open: boolean;
};

const EditProductModal: FC<Props> = ({
  customer,
  product,
  open,
  onClose,
  onDeleteRelations
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();

  // Data
  const { firstName, lastName } = customer;
  const { brand, modelName, pnc, serialNumber } = product || {};

  // Submit
  const onSubmit: SubmitHandler<any> = useCallback(
    ({ relations, warrantyConditionIprId }: FormValues) => {
      if (!relations?.length) {
        return setError('relationships', {
          message: intl.formatMessage({ id: 'select.required' })
        });
      }

      // Relations can be both string and array if removing more than 1 at once
      onDeleteRelations(
        Array.isArray(relations) ? relations : [relations],
        warrantyConditionIprId
      );
      onClose();
    },
    [onDeleteRelations, onClose, setError, intl]
  );

  // Render relations
  const relationsCheckboxes = useMemo(() => {
    if (!product?.relations) {
      return null;
    }

    return product.relations.map((relation) => {
      const { relationshipType } = relation.attributes;
      return (
        <SelectListItem key={relationshipType}>
          <Checkbox
            id={relationshipType}
            value={relation.id}
            register={register('relations')}
            defaultChecked
          />
          <label htmlFor={relationshipType}>{relationshipType}</label>
        </SelectListItem>
      );
    });
  }, [product, register]);

  const warrantyConditionCheckbox = useMemo(() => {
    if (!product?.iprId) {
      return null;
    }

    return (
      <SelectListItem key="warrantyConditionIprId">
        <Checkbox
          id="warrantyConditionIprId"
          value={product?.iprId}
          register={register('warrantyConditionIprId')}
          defaultChecked
        />
        <label htmlFor="warrantyConditionIprId">
          <FormattedMessage id="modal.remove_product_warranty_condition_text" />
        </label>
      </SelectListItem>
    );
  }, [product, register]);

  // Show error toast
  useEffect(() => {
    const error = errors.relationships;
    if (error) {
      showInfoToast(error.message as string);
      clearErrors();
    }
  }, [intl, errors, clearErrors]);

  // Details
  const details = [
    {
      id: 1,
      title: 'customer_details.title',
      value: `${firstName} ${lastName}`
    },
    {
      id: 2,
      title: 'customers.product_table_title',
      value: product && `${brand} ${modelName}`
    },
    {
      id: 3,
      title: 'customers.product_table_pnc',
      value: pnc
    },
    {
      id: 4,
      title: 'customers.product_table_serial',
      value: serialNumber
    }
  ];

  return (
    <EditModal title="modal.remove_product_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormattedMessage id="modal.remove_product_text" />
        <DetailGroup>
          {details.map(({ id, title, value }) => (
            <InfoItem
              key={id}
              title={intl.formatMessage({ id: title })}
              value={value ?? '–'}
            />
          ))}
        </DetailGroup>
        <DetailGroup>
          <div>
            <FormattedMessage id="modal.remove_product_relations" />:
            <SelectList>{relationsCheckboxes}</SelectList>
          </div>
          <div>
            <FormattedMessage id="modal.remove_product_warranty_condition" />:
            <SelectList>{warrantyConditionCheckbox}</SelectList>
          </div>
        </DetailGroup>
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button submit>
            <FormattedMessage id="modal.btn_remove" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditProductModal;
