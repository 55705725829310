import { Customer, CustomerRelation, Dealer } from 'models/customers';
import { ServiceContract } from 'models/contracts';
import { APIError } from 'models/errors';

export type WarrantyAttributes = {
  expireDate: string;
  extendedWarrantyPeriod: number;
  iprId: string;
  soldDate: string;
  source: string | null;
  verified: boolean;
  verifiedBy: string | null;
  verifiedWhen: string | null;
  warrantyPeriod: number;
  extendedWarrantyCampaigns: object[];
  createDate: string | null;
  updateDate: string | null;
  companyCode: string | null;
  customerNo: string | null;
  customerType: string | null;
};

export type Warranty = {
  id: string;
  type: string;
  attributes: WarrantyAttributes;
};

export type WarrantyResponse = {
  data: Warranty;
};

export type CreateWarranty = {
  soldDate: string;
  consumerType: string;
  companyCode: string;
  customerNo: string;
};

export type ProductAttributes = {
  brand: string;
  description: string;
  husqvarnaId: string;
  imageId: string;
  imageUrl: string;
  iprId: string;
  modelName: string;
  pnc: string;
  productCategory: string;
  serialNumber: string;
  productType: string;
  localIdGardena?: string;
  relations?: CustomerRelation[];
};

export type ProductList = {
  data: ProductAttributes[] | null;
  isLoading: boolean;
  error: APIError | null;
  filters: {
    active: boolean;
  };
};

export type ProductDetails = {
  data: ProductAttributes | null;
  dealer: Dealer | null;
  customers: Customer[];
  contracts: ServiceContract[];
  relations: CustomerRelation[];
  warranties: Warranty[];
  isLoading: boolean;
  error: APIError | null;
};

export type FactoryPinState = {
  data: string | null;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ProductState = {
  list: ProductList;
  details: ProductDetails;
  factoryPin: FactoryPinState;
};

export type ProductSearch = {
  data: ProductAttributes[] | null;
  isLoading: boolean;
  error: APIError | null;
};

export type DealerData = {
  companyCode: string;
  customerNumber: string;
};

export type RegisterFormFields = {
  companyCode: string;
  customerNumber: string;
  soldDate: string;
};

export type ProductRegister = {
  products: ProductAttributes[];
  isLoading: boolean;
  success: number | null;
  error: APIError | null;
};

export type ProductRegistrationState = {
  search: ProductSearch;
  register: ProductRegister;
};

export type Product = {
  type: string;
  attributes: {
    products: ProductAttributes[];
  };
};

export type FactoryPin = {
  id: string;
  type: string;
  attributes: {
    factorypin: string;
  };
};

export interface ProductResponse {
  data: Product;
  errors: APIError[];
}

export enum ProductFilterAttribute {
  PNC = 'pnc',
  SN = 'serialNumber',
  IprId = 'iprId',
  Email = 'email',
  LocalIdGardena = 'localIdGardena'
}

export type ProductFilter = {
  attribute: ProductFilterAttribute;
  value: string;
};

export type ProductFilterObject = {
  pnc: string;
  serialNumber: string;
  husqvarnaId?: string;
  localIdGardena?: string;
};

export type RegisterProductData = {
  pnc?: string;
  serialNumber?: string;
  husqvarnaId?: string;
};

export interface RegisterProductsCustomer {
  customerId: string;
  products: RegisterProductData[];
  dealer: DealerData;
  soldDate: number;
}

export interface ProductDetailsResponse {
  product: ProductAttributes | null;
  dealer: Dealer | null;
  contracts: ServiceContract[];
  customers: Customer[];
  relations: CustomerRelation[];
  warranties: Warranty[];
}

export interface ProductFactoryPinResponse {
  data: FactoryPin;
  errors?: APIError[];
}

export type CreateWarrantyState = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type RemoveWarrantyState = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type WarrantyState = {
  data: Warranty | null;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type WarrantyConditionState = {
  warranty: WarrantyState;
  createWarranty: CreateWarrantyState;
  removeWarranty: RemoveWarrantyState;
};
