import { FC } from 'react';
import { motion, Variants } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { MenuItem } from 'models';
import Icon, { IconType } from 'components/UI/Icon';
import { getLinkVariants, getShieldVariants } from 'utils/layout';

import {
  Container,
  MenuCard,
  MenuLink,
  LinkText,
  LinkIcon,
  AnimatedMenuLinkContainer
} from './styled';

type Props = {
  menu: MenuItem[];
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const containerVariants: Variants = {
  open: {
    left: '0'
  },
  closed: {
    left: '-100%'
  }
};

const MobileMenu: FC<Props> = ({ menu, open, setOpen }: Props) => {
  const { pathname } = useLocation();

  return (
    <Container
      initial={false}
      variants={containerVariants}
      transition={{ type: 'tween', duration: 0.25 }}
      animate={open ? 'open' : 'closed'}
    >
      <MenuCard>
        {menu.map(({ id, link = '', text, intl }, index) => (
          <AnimatedMenuLinkContainer
            key={id}
            variants={getLinkVariants(index)}
            transition={{ duration: 0.25 }}
            animate={open ? 'open' : 'closed'}
          >
            <MenuLink to={link} onClick={() => setOpen(!open)}>
              <LinkText>
                <LinkIcon>
                  <motion.svg
                    width="4"
                    variants={getShieldVariants(index)}
                    animate={open && pathname === link ? 'open' : 'closed'}
                  >
                    <g fill="#F35321" fillRule="evenodd">
                      <path
                        d="M3.662 4.087c.22.31.338.676.338 1.05V6H0V.473C0 .006.64-.177.916.21l2.746 3.877Z"
                        fillRule="nonzero"
                      />
                      <path d="M4 6v10H0V6z" />
                      <path
                        d="M3.662 17.913c.22-.31.338-.676.338-1.05V16H0v5.527c0 .467.64.65.916.263l2.746-3.877Z"
                        fillRule="nonzero"
                      />
                    </g>
                  </motion.svg>
                </LinkIcon>
                {intl ? <FormattedMessage id={intl} /> : text}
              </LinkText>
              <Icon type={IconType.ArrowRight} themeType="onSurface" />
            </MenuLink>
          </AnimatedMenuLinkContainer>
        ))}
      </MenuCard>
    </Container>
  );
};

export default MobileMenu;
