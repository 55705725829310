import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Menu = styled.div`
  display: flex;
  min-height: 48px;
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 12px 0;
  min-width: 72px;
`;

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
`;
