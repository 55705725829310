import { Pagination } from 'models/common';
import { APIError } from 'models/errors';

export type UserAttributes = {
  address2: string;
  city: string;
  country: string;
  customerId: string;
  detailedGroups: Group[];
  disabled: boolean;
  firstName: string;
  groups: string[];
  language: string;
  lastLogin: number;
  lastName: string;
  mfaActivated: boolean;
  passwordPolicy: PasswordPolicy;
  postalAddress: string;
  postalCode: string;
  roles: string[];
  telephoneNumber: string;
  username: string;
};

export type UserBody = {
  first_name?: string;
  last_name?: string;
  country?: string;
  language?: string;
  postal_address?: string;
  postal_code?: string;
  city?: string;
  telephone_number?: string;
  address2?: string;
};

export interface User {
  id: string;
  type: string;
  attributes: UserAttributes;
}

export type Group = {
  id: string;
  name: string;
  groupType: string;
  administrators?: string[];
  readOnlyAdministrators?: string[];
  created?: number;
  createdBy?: string;
  updated?: number;
  updatedBy?: string;
  description?: string;
};

export type UserGroupData = {
  name: string;
  value: string;
};

export type UserGroup = {
  id: string;
  type: string;
  attributes: {
    name: string;
    group_type: string;
  };
};

export type UserRole = {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    type: string;
  };
};

export type UserPasswordPolicy = {
  id: string;
  type: string;
  attributes: {
    maximum_length: number;
    minimum_length: number;
  };
};

export type UserGroupResponse = {
  data: UserGroup[];
};

export type UserRoleResponse = {
  data: UserRole[];
};

export type UserPasswordPolicyResponse = {
  data: UserPasswordPolicy[];
};

export type PasswordPolicy = {
  id: string;
  maximumLength: number;
  minimumLength: number;
  requireLower?: boolean;
  requireCapital?: boolean;
  requireNumber?: boolean;
  requireSpecial?: boolean;
};

export type UserResponse = {
  data: User;
};

export type UsersResponse = {
  data: User[];
};

export type UserAuditLogData = {
  activations: {
    activation: {
      code: string;
      forward?: any;
      time: number;
    };
  };
  address: {
    address2?: string;
    city?: string;
    postalAddress?: string;
    postalCode?: string;
    telephoneNumber?: string;
  };
  country: string;
  customAttributes: any;
  customerId: string;
  disabled: boolean;
  emailVerified: true;
  firstName: string;
  groups: string[];
  id: string;
  includedPasswordPolicy: any;
  language: string;
  lastLogin: number;
  lastName: string;
  mfaActivated: boolean;
  mfaSecret: any;
  modification: {
    created: number;
    createdBy: string;
    updated: number;
    updatedBy: string;
  };
  passwordNeedsReset: boolean;
  passwordPolicy: string;
  registrationProvider: string;
  removalNotification: any;
  revision: number;
  roles: string[];
  salutation?: string;
  username: string;
};

export type UserAuditLogAttributes = {
  changesubtype: string;
  changetype: string;
  data_after: UserAuditLogData;
  data_before: UserAuditLogData;
  description: string;
  done_by: string;
  timestamp: string;
};

export type UserAuditLog = {
  id: string;
  type: string;
  attributes: UserAuditLogAttributes;
};

export type UserSearchFilters = {
  isActive: boolean;
  data: UserFilterData;
};

export type UserSearch = {
  data: User[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isError: boolean;
  error: APIError | null;
  filters: UserSearchFilters;
  pagination: Pagination;
};

export type UserUpdate = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UserDetails = {
  data: User | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UserState = {
  search: UserSearch;
  details: UserDetails;
};

export type UserUpdateState = {
  update: UserUpdate;
  status: UserUpdate;
  username: UserUpdate;
};

export type UserRequestState = {
  activation: UserUpdate;
  changeUsername: UserUpdate;
  deletion: UserUpdate;
};

export type UserAuthState = {
  passwordRecovery: UserUpdate;
  invalidateTokens: UserUpdate;
};

export type UserPasswordPolicies = {
  data: UserPasswordPolicy[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UserPasswordPolicyState = {
  list: UserPasswordPolicies;
  update: UserUpdate;
};

export enum UserFilter {
  Username = 'username',
  Group = 'group',
  Country = 'country',
  Language = 'language'
}

export type UserFilterData = {
  [UserFilter.Username]: string | null;
  [UserFilter.Group]: string | null;
  [UserFilter.Country]: string | null;
  [UserFilter.Language]: string | null;
};

export type UserGroups = {
  data: UserGroup[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UpdateUserGroup = {
  data: UserGroupData | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UserGroupState = {
  groups: UserGroups;
  addGroup: UpdateUserGroup;
  removeGroup: UpdateUserGroup;
};

export type UserRoles = {
  data: UserRole[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UpdateUserRole = {
  data: string | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type UserRoleState = {
  roles: UserRoles;
  addRole: UpdateUserRole;
  removeRole: UpdateUserRole;
};

export type UserStatisticsAttributes = {
  total: number;
  enabled: number;
};

export type UserStatistics = {
  id: string;
  type: string;
  attributes: UserStatisticsAttributes;
};

export type UserStatisticsResponse = {
  data: UserStatistics[];
};

export type UserStatisticsExportResponse = {
  data: string;
};

export type UserStatisticsState = {
  list: {
    data: UserStatistics[] | null;
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    error: APIError | null;
  };
  exports: {
    data: string | null;
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    error: APIError | null;
  };
};
