import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getItem, setItem } from 'utils/localStorage';
import { LayoutState } from 'models/layout';
import type { RootState } from 'store/store';
import * as routes from 'router/Routes';

export const defaultState: LayoutState = {
  sidebar: getItem('sidebar') || { isOpen: true },
  startRoute: getItem('startRoute') || routes.CUSTOMERS
};

// Export slice
export const layoutSlice = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {
    toggleSidebar: (state) => {
      setItem('sidebar', {
        isOpen: !state.sidebar.isOpen
      });
      state.sidebar.isOpen = !state.sidebar.isOpen;
    },
    setStartRoute: (state, action: PayloadAction<string>) => {
      setItem('startRoute', action.payload);
      state.startRoute = action.payload;
    }
  }
});

// Export selectors
export const layoutSelector = (state: RootState) => state.layout;

// Export actions
export const { toggleSidebar, setStartRoute } = layoutSlice.actions;

// Export reducer
export const layoutReducer = layoutSlice.reducer;
