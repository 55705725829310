import styled from 'styled-components';

export const ButtonGrid = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 200px;
  }
`;

export const TagGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
`;

export const SelectGroupContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 88px;
`;
