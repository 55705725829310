import { FC } from 'react';
import { ThemeColor } from 'styles';
import { StyledButton, ButtonColor, ButtonSize } from './styled';

type Props = {
  children: any;
  submit?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
  color?: ThemeColor;
  size?: ButtonSize;
  backgroundColor?: ButtonColor;
  marginRight?: boolean;
};

const Button: FC<Props> = ({
  children,
  submit,
  onClick,
  color = 'onPrimary',
  backgroundColor = 'primaryDark',
  size = 'large',
  disabled = false,
  marginRight = false
}) => {
  return (
    <StyledButton
      onClick={onClick}
      className={backgroundColor}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      $backgroundColor={backgroundColor}
      $marginRight={marginRight}
      $color={color}
      $size={size}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
