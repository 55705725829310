import { FC, useEffect, useMemo, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import logoGroup from 'assets/vectors/logo-group.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as routes from 'router/Routes';
import { showErrorToast } from 'utils/toast';
import { useQuery } from 'hooks/useQuery';
import { Language } from 'models';
import { healthCheck, loginRedirect } from 'services';
import {
  authSelector,
  localizationSelector,
  authStart,
  setLocale,
  setStartRoute
} from 'store';

import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import NavDropdownButton from 'components/Layout/NavBar/NavDropdownButton';

import {
  PageContainer,
  ImageSection,
  ImageContent,
  LoginSection,
  LoginContent,
  LoginDescription,
  ButtonGrid,
  LogoGroup,
  LogoGroupImage,
  LogoGroupText,
  LanguageContainer
} from './styled';

const Login: FC = () => {
  const intl = useIntl();
  const { state } = useLocation();
  const { locale, languages } = useAppSelector(localizationSelector);
  const {
    session: { isAuthenticated },
    isLoading
  } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const [error, errorMessage] = useQuery('error', 'error_description');

  // Show error
  useEffect(() => {
    if (error) {
      // Message
      const showMessage = (): string => {
        if (error === 'interaction_required') {
          return `${intl.formatMessage({ id: 'login.unauthorized' })}`;
        }
        return `${errorMessage}`;
      };
      showErrorToast(showMessage());
    }
  }, [dispatch, error, errorMessage, intl]);

  // Azure login
  const onLogin = useCallback(() => {
    dispatch(authStart());

    // Use state prev route
    if (state) {
      dispatch(setStartRoute(state.prevRoute));
    }

    // Start up BFF Lambda instance with health check endpoint
    healthCheck();
    loginRedirect(locale);
  }, [dispatch, locale, state]);

  // Language menu
  const langMenu = useMemo(
    () =>
      languages.map((language: Language, i: number) => ({
        id: i + 1,
        text: language.toUpperCase(),
        onClick: () => dispatch(setLocale(language))
      })),
    [dispatch, languages]
  );

  // Render content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center size="medium" />;
    }
    return (
      <ButtonGrid>
        <Button onClick={onLogin}>
          <FormattedMessage id="login.btn_login" />
        </Button>
      </ButtonGrid>
    );
  }, [onLogin, isLoading]);

  // Authenticated
  if (isAuthenticated) {
    return <Navigate to={routes.ROOT} />;
  }

  return (
    <PageContainer>
      <ImageSection>
        <ImageContent>
          <Heading>
            <FormattedMessage id="login.image_title" />
          </Heading>
          <LoginDescription>
            <FormattedMessage id="login.image_description" />
          </LoginDescription>
        </ImageContent>
      </ImageSection>
      <LoginSection>
        <LanguageContainer>
          <NavDropdownButton text={locale.toUpperCase()} menu={langMenu} />
        </LanguageContainer>
        <LoginContent>
          <Heading>
            <FormattedMessage id="login.title" />
          </Heading>
          <LoginDescription>
            <FormattedMessage id="login.description" />
          </LoginDescription>
          {content}
          <LogoGroup>
            <LogoGroupImage src={logoGroup} alt="husqvarna group logo" />
            <LogoGroupText>
              <FormattedMessage id="login.group_text" />
            </LogoGroupText>
          </LogoGroup>
        </LoginContent>
      </LoginSection>
    </PageContainer>
  );
};

export default Login;
