import styled from 'styled-components';
import { motion } from 'framer-motion';

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

type TooltipProps = {
  $position: TooltipPosition;
};

export const Container = styled.div`
  position: relative;
`;

export const InnerContainer = styled.div`
  cursor: pointer;
`;

const tooltipPosMap: Record<TooltipPosition, string> = {
  top: `
    bottom: 100%;
    left: 0;
  `,
  right: `
    left: 100%;
    top: 0;
  `,
  bottom: `
    top: 100%;
    left: 0;
  `,
  left: `
    right: 100%;
    top: 0;
  `
};

function getPosition({ $position }: TooltipProps) {
  return tooltipPosMap[$position];
}

export const Popup = styled(motion.div)<TooltipProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  ${getPosition}
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 9999999999999;
  border-radius: 8px;
  transition: opacity 300ms;
  padding: 12px;
  z-index: 99;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey0};
  width: max-content;
  max-width: 300px;
  margin: 0;
  white-space: pre-line;
`;
