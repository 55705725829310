import * as config from 'config';
import {
  ProductResponse,
  ProductFilter,
  ProductDetailsResponse,
  RegisterProductsCustomer,
  ServiceRecord,
  ProductFactoryPinResponse,
  WarrantyResponse,
  Warranty,
  ServicePlan,
  ServicePlanResponse,
  ServicePlanParams,
  CreateWarranty
} from 'models';
import { apiFetch } from 'utils/fetch';
import { getServiceRecords } from 'utils/product';

export async function findFilteredProducts(
  filters: ProductFilter[]
): Promise<ProductResponse> {
  const lang = 'en';
  let url = `${config.backendApi}/proxy/product-registrations/search/${lang}?`;

  // Add filters
  filters.forEach((filter) => {
    url += `filter[${filter.attribute}]=${filter.value}&`;
  });

  url += 'filter[imageResolution]=180x180';

  return apiFetch<any>(url);
}

export type RegistrationIncludeOptions = {
  customer?: string;
  relationship?: string;
  dealer?: string;
  servicecontract?: string;
  'servicerecord.performedtask'?: string;
  'servicecontract-offering'?: string;
  document?: string;
  warranty?: string;
  all?: string;
  [key: string]: any;
};

export async function findProductByIprId(
  iprId: string,
  languageCode = 'en'
): Promise<ProductDetailsResponse> {
  const include = 'customer,relationship,dealer,servicecontract';
  const params = `filter[iprId]=${iprId}&filter[imageResolution]=500x500&include=${include}`;
  const url = `${config.backendApi}/proxy/product-registrations/search/${languageCode}?${params}`;

  const json = await apiFetch<any>(url);

  const details: ProductDetailsResponse = {
    product: json.data.attributes.products[0],
    dealer: null,
    contracts: [],
    customers: [],
    relations: [],
    warranties: []
  };

  // Return details if nothing is included
  if (!json.included) {
    return details;
  }

  json.included.forEach((data: any) => {
    if (data.type === 'servicecontract') {
      details.contracts.push(data);
    }
    if (data.type === 'customer') {
      const { name, ...attributes } = data.attributes;
      const customer = {
        ...data,
        attributes: {
          ...attributes,
          companyName: name
        }
      };
      details.customers.push(customer);
    }
    if (data.type === 'dealer') {
      details.dealer = data;
    }
    if (data.type === 'relationship') {
      details.relations.push(data);
    }
    if (data.type === 'warranty') {
      details.warranties.push(data);
    }
  });

  return details;
}

export async function registerProductsCustomer(
  data: RegisterProductsCustomer
): Promise<any> {
  const { customerId, products, dealer, soldDate } = data;

  // Url
  const url = `${config.backendApi}/product-registrations/register-products-customer/${customerId}`;

  return apiFetch<any>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'product-registration',
        attributes: {
          products,
          dealer,
          soldDate
        }
      }
    })
  });
}

export async function getWarrantyConditionByIprId(
  iprId: string
): Promise<Warranty> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;

  const res = await apiFetch<WarrantyResponse>(url);

  return res.data;
}

export async function createWarrantyConditionByIprId(
  iprId: string,
  createWarranty: CreateWarranty
): Promise<Warranty> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;
  const { companyCode, consumerType, customerNo, soldDate } = createWarranty;

  const res = await apiFetch<WarrantyResponse>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'operation',
        attributes: {
          name: 'addWarrantyCondition',
          warrantycondition: {
            soldDate,
            consumerType,
            companyCode,
            customerNo
          }
        }
      }
    })
  });

  return res.data;
}

export async function removeWarrantyConditionByIprId(
  iprId: string
): Promise<void> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;

  await apiFetch<void>(url, {
    method: 'DELETE'
  });
}

export async function findServiceRecordsByIprId(
  iprId: string
): Promise<ServiceRecord[]> {
  const url = `${config.backendApi}/proxy/servicerecords?filter[iprId]=${iprId}&include=performedtask`;

  const { data, included } = await apiFetch<any>(url);

  return getServiceRecords(data, included);
}

export async function findServicePlans(
  params: ServicePlanParams
): Promise<ServicePlan> {
  const { iprId, pnc } = params;

  const queryParams = iprId ? `iprId=${iprId.trim()}` : `pnc=${pnc?.trim()}`;
  const url = `${config.backendApi}/service-plans?${queryParams}`;

  const response = await apiFetch<ServicePlanResponse>(url);

  return response.data;
}

export async function getFactoryPinByIprId(
  iprId: string
): Promise<ProductFactoryPinResponse> {
  const url = `${config.backendApi}/product/${iprId}/factorypin`;

  const response = await apiFetch<any>(url);

  return response;
}
