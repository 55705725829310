import { useMemo } from 'react';
import { SCPUserAccess } from 'models';
import { useAppSelector } from './redux';
import { authSelector } from 'store';
import {
  getDiamSystemGroups,
  hasAzureAccess,
  hasDiamAccess,
  hasDiamStatisticsAccess
} from 'utils/roles';

export const useAccess = (): SCPUserAccess => {
  const { session } = useAppSelector(authSelector);

  return useMemo(() => {
    const { scpUser } = session;
    if (!scpUser) {
      return {
        azureAccess: false,
        diamAccess: false,
        diamStatisticsAccess: false,
        groups: []
      };
    }
    return {
      azureAccess: hasAzureAccess(scpUser),
      diamAccess: hasDiamAccess(scpUser),
      diamStatisticsAccess: hasDiamStatisticsAccess(scpUser),
      groups: getDiamSystemGroups(scpUser)
    };
  }, [session]);
};
