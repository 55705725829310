import { FC, ReactNode, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';

import { MenuItem } from 'models';
import { getShieldVariants } from 'utils/layout';

import {
  MenuContainer,
  Menu,
  MenuLink,
  MenuListItem,
  LinkIcon
} from './styled';

type Props = {
  children: ReactNode;
  menu: MenuItem[];
};

const TabMenuDropdown: FC<Props> = ({ children, menu }) => {
  const { pathname } = useLocation();

  // State
  const [isActive, setIsActive] = useState<boolean>(false);

  // Open / close
  const onOpen = useCallback(() => setIsActive(true), [setIsActive]);
  const onClose = useCallback(() => setIsActive(false), [setIsActive]);

  return (
    <MenuContainer onMouseEnter={onOpen} onMouseLeave={onClose}>
      {children}
      <Menu $isActive={isActive}>
        <ul>
          {menu.map(({ id, intl, text, link = '#' }, i) => (
            <MenuListItem
              key={id}
              $isLast={menu.length === i + 1}
              onClick={onClose}
            >
              <MenuLink to={link}>
                <LinkIcon>
                  <motion.svg
                    width="4"
                    variants={getShieldVariants(i)}
                    animate={pathname === link ? 'open' : 'closed'}
                  >
                    <g fill="#F35321" fillRule="evenodd">
                      <path
                        d="M3.662 4.087c.22.31.338.676.338 1.05V6H0V.473C0 .006.64-.177.916.21l2.746 3.877Z"
                        fillRule="nonzero"
                      />
                      <path d="M4 6v10H0V6z" />
                      <path
                        d="M3.662 17.913c.22-.31.338-.676.338-1.05V16H0v5.527c0 .467.64.65.916.263l2.746-3.877Z"
                        fillRule="nonzero"
                      />
                    </g>
                  </motion.svg>
                </LinkIcon>
                <span>{intl ? <FormattedMessage id={intl} /> : text}</span>
              </MenuLink>
            </MenuListItem>
          ))}
        </ul>
      </Menu>
    </MenuContainer>
  );
};

export default TabMenuDropdown;
