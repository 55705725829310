import { FC, ReactNode } from 'react';
import Icon, { IconType } from 'components/UI/Icon';
import { Container, Title, Description, Column } from './styled';

type Props = {
  title?: string;
  children: ReactNode;
};

export const InfoBox: FC<Props> = ({ title, children }) => (
  <Container>
    <Column>
      <Icon type={IconType.Info} themeType="grey9" />
    </Column>
    <Column>
      {title && <Title>{title}</Title>}
      <Description>{children}</Description>
    </Column>
  </Container>
);
