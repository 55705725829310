import { useMemo } from 'react';

import { authSelector } from 'store';
import { AzureUserAccess } from 'models';
import { useAppSelector } from 'hooks/redux';
import { hasAzureGlobalAdminAccess } from 'utils/roles';

export const useAzureAccess = (): AzureUserAccess => {
  const { session } = useAppSelector(authSelector);

  return useMemo(() => {
    const { scpUser } = session;
    if (!scpUser) {
      return {
        isGlobalAdmin: false
      };
    }
    return {
      isGlobalAdmin: hasAzureGlobalAdminAccess(scpUser)
    };
  }, [session]);
};
