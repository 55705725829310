import styled from 'styled-components';

type Props = {
  $isPressable: boolean;
  $padding?: boolean;
  disabled?: boolean;
};

export const Root = styled.td<Props>`
  position: relative;
  padding: 0;
  cursor: ${({ $isPressable, disabled }) => {
    return $isPressable && !disabled ? 'pointer' : 'default';
  }};
  border-top: 1px solid ${({ theme }) => theme.colors.grey3};
  max-width: 250px;
`;

export const ContentContainer = styled.div<Props>`
  padding: ${({ $padding }) => ($padding ? '16px' : '0 16px')};
  display: flex;
  align-items: center;
  justify-content: ${({ $isPressable }) =>
    $isPressable ? 'flex-end' : 'inherit'};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.grey9};
  font-size: 16px;
`;

export const Image = styled.img`
  max-height: 64px;
`;
