import { APIError } from 'models/errors';

export type CompanyAddress = {
  street: string;
  county?: string;
  state?: string;
  zipCode: string;
  city: string;
  country: string;
  longitude?: string;
  latitude?: string;
};

export enum CompanyFilterAttribute {
  CompanyName = 'companyName',
  CountryCode = 'countryCode',
  DunsNumber = 'dunsNumber',
  VatNumber = 'vatNumber'
}

export type CompanyFilter = {
  attribute: CompanyFilterAttribute;
  value: string;
};

export type ExternalCompanyAttributes = {
  countryCode: string;
  companyName: string;
  primaryAddress: CompanyAddress;
  customerId?: string;
  userId?: string;
};

export type ExternalCompany = {
  id: string;
  type: string;
  attributes: ExternalCompanyAttributes;
};

export type ExternalCompanyResponse = {
  data: ExternalCompany[];
};

export type CompanySearchState = {
  data: ExternalCompany[];
  inputCountryCode: string;
  inputValue: string;
  advancedData: ExternalCompany[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type CompanySelectState = {
  data: ExternalCompany | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type CompanyState = {
  search: CompanySearchState;
  select: CompanySelectState;
};
