import { FC, ReactNode, createElement, useMemo } from 'react';
import { Upper } from './styled';

export enum Type {
  h1,
  h2,
  h3,
  h4,
  h5
}

type Props = {
  children: ReactNode;
  type?: Type;
  uppercase?: boolean;
};

const Heading: FC<Props> = ({
  children,
  type = Type.h1,
  uppercase = false
}) => {
  // Content
  const content = useMemo(() => {
    const element = createElement(`h${type + 1}`, null, children);

    if (!uppercase) {
      return element;
    }

    return <Upper>{element}</Upper>;
  }, [type, uppercase, children]);

  return content;
};

export default Heading;
