import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
`;

export const InputField = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const InputIcon = styled.div`
  position: absolute;
  display: flex;
  top: 13px;
  left: 16px;
`;

export const InputButton = styled.button`
  position: absolute;
  display: flex;
  background: ${({ theme }) => theme.colors.grey2};
  border-radius: 8px;
  border: none;
  top: 13px;
  right: 16px;
  cursor: pointer;
  padding: 4px;
`;

export const Input = styled.input`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 24px;
  border: solid 1px ${({ theme }) => theme.colors.grey3};
  border-radius: 16px;
  padding: 12px 16px 12px 48px;
  outline: none;
`;

export const ButtonGrid = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    justify-content: flex-end;
  }
`;
