import styled from 'styled-components';
import { breakpoints } from 'styles';

export const SearchGrid = styled.div`
  display: flex;
  max-width: 250px;
  width: 100%;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    max-width: none;
  }
`;

export const ResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoadMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 24px 0 8px;
`;

export const NoItems = styled.p`
  color: ${({ theme }) => theme.colors.grey5};
  margin: 0;
`;
