import styled from 'styled-components';

type AddressProps = {
  $border: boolean;
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 16px;
  }
`;

export const AddressGrid = styled.div<AddressProps>`
  display: flex;
  flex: 1;
  padding-bottom: 8px;
  border-bottom: ${({ $border, theme }) => {
    return $border ? '1px solid' + theme.colors.grey3 : 'none';
  }};
`;

export const AddressDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 16px;
    margin: 0 0 8px;
    color: ${({ theme }) => theme.colors.grey6};
  }
`;

export const FieldText = styled.p`
  margin: 0 0 4px;
`;
