import styled from 'styled-components';
import { ThemeColor } from 'styles';
import { setColor } from '../styled';

const rotationMap = {
  up: '270deg',
  right: '0',
  down: '90deg',
  left: '180deg'
};

type StyledSVGProps = {
  $direction: 'up' | 'right' | 'down' | 'left';
  $transitionDuration: number;
  $transition: boolean;
};

export const StyledSVG = styled.svg<StyledSVGProps>`
  transform: rotate(${({ $direction }) => rotationMap[$direction]});
  transition: ${({ $transition, $transitionDuration }) => {
    const duration = 'transform ' + $transitionDuration + 's';
    return $transition ? duration : 'initial';
  }};
`;

export type StyledPathProps = {
  $themeType?: ThemeColor;
  $color: string;
};
export const StyledPath = styled.path<StyledPathProps>`
  fill: ${({ theme, $themeType, $color }) =>
    setColor(theme, $color, $themeType)}};
`;
